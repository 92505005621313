<template>
    <div>
        <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
            <form v-for="localization in form.localizations" :key="localization.language" @submit.prevent="updateTagCategory">
                <a class="category-collapse" v-b-toggle="'tag-category' + category.id" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                    <input v-if="isCategoryEditable" type="text" v-model="localization.title" :placeholder="$t('inputLabels.categoryTitle')" :class="{error: error.newTagTitle}" @click.stop="" maxlength="30">
                    <p v-else>{{ localization.title }}</p>
                    <button v-if="!isCategoryEditable" type="button" class="img-button edit-button ml-auto" @click.stop="editTagCategoryTitle(localization.language)"></button>
                    <button v-else type="button" class="img-button confirm-button ml-auto" @click.stop="updateTagCategory"></button>
                    <button type="button" class="img-button delete-button" @click.stop="showModal"></button>
                </a>
            </form>
        </div>
        <b-collapse :id="'tag-category' + category.id">
            <div class="tag-category">
                <div class="tag-list">
                    <Tag v-for="tag in category.tags" :key="tag.id" :tag="tag" :tagCategoryId="category.id" :isManagingLocalizations="isManagingLocalizations" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage"/>

                    <div class="tag">
                        <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
                            <form @submit.prevent="createTag" class="tag" v-for="localization in newTagForm.localizations" :key="localization.language">
                                <div class="form-content-container" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                                    <div class="equipment-category-icon-container">
                                        <div v-if="!newTagForm.icon">
                                            <label :for="'fileInput-' + category.id" v-tooltip="$t('info.addIcon')">+</label>
                                            <input :id="'fileInput-' + category.id" class="button button--secondary" type="file" accept="image/svg+xml" @change="onInputFile">
                                        </div>
                                        <div v-else class="icon-preview">
                                            <img :src="chosenImageUrl" alt="">
                                            <span @click="newTagForm.icon = null" role="button" class="remove-icon">&#215;</span>
                                        </div>
                                    </div>
                                    <input type="text" v-model="localization.title" :placeholder="$t('admin.addTag')" :class="{error: error.newTagTitle}" maxlength="30">
                                    <TrekioButton width="60px" style="font-size: 26px;" type="submit" primary :isLoading="isLoadingTag">+</TrekioButton>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </b-collapse>
        <b-modal class="modal" :id="'delete-category-modal' + form.id" hide-footer hide-header no-close-on-backdrop>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                <h3 class="moda-title pb-3">{{ $t("modals.tagCategoryDeleteTitle", [form.localizations[0].title])}}</h3>
                <div v-if="canDeleteTag && canDeleteTag.length">
                    <div class="travel-tip-links-container">
                        <p>{{ $t("modals.tagCategoryDeleteText1") }}</p>
                        <div class="pl-4 d-flex flex-column">
                            <router-link v-for="travelTip in canDeleteTag" :key="travelTip.key" :to="'/admin/manageTravelTip/' + travelTip.id" target="_blank">{{travelTip.localizations[0].title}}</router-link>
                        </div>
                    </div>
                    <p class="moda-text">{{ $t("modals.tagCategoryDeleteText2") }}</p>
                    <p class="moda-text pb-5">{{ $t("modals.tagCategoryDeleteText3") }}</p>
                </div>
                <div class="pb-5 d-flex" style="gap: 40px">
                    <TrekioButton secondary :disabled="loadingDelete != ''"  @click="$bvModal.hide('delete-category-modal' + form.id)">{{ $t("buttons.back") }}</TrekioButton>
                    <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="deleteTagCategory">{{ $t("buttons.delete") }}</TrekioButton>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
    import { CAN_DELETE_TAG } from '../../api/graphql/query/TagQuery';
    import TagService from '../../services/TagService';
    import Tag from './Tag.vue';
    import sharedUtils from '../../utils/sharedUtils';
    import { CLEVERTRIP_ICON_URL } from '../../definitions';
    import fileUtils from '../../utils/fileUtils';

    export default {
        props: {
            category: {
                type: Object,
                required: true,
            },
            isManagingLocalizations: {
                type: Boolean,
                required: true
            },
            primaryLanguage: {
                type: String,
                required: true,
            },
            editedLanguage: {
                type: String,
            },
            allTagTitles: {
                type: Set,
            }
        },
        data() {
            return {
                iconUrl: CLEVERTRIP_ICON_URL,
                form: {
                    id: null,
                    originalLanguage: "",
                    localizations: [],
                },
                newTagForm: {
                    originalLanguage: 'cs',
                    localizations: this.$store.state.languages.map(lang => {
                        return {
                            language: lang.code,
                            title: ""
                        }
                    }),
                    icon: null,
                },
                chosenImageUrl: null,
                isCategoryEditable: false,
                isLoadingTag: false,
                isLoadingCategory: false,
                loadingDelete: '',
                error: {
                    title: false,
                    newTagTitle: false
                }
            }
        },

        created() {
            this.form = {
                id: this.category.id,
                originalLanguage: this.category.originalLanguage,
                localizations: this.category.localizations.map(localization => {
                    const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
                    delete localizationCopy['__typename'];
                    return localizationCopy
                })
            }
            this.sortLocalizations()
        },

        components: {
            Tag
        },

        methods: {
            async onInputFile(event){
                const file = event.target.files[0]
                if (!file) return
                const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(file.name)
                this.newTagForm.icon = new File([file], uniqueNameWithoutAccentsAndSpaces, {type: file.type});

                const reader = new FileReader();
                reader.onload = () => {
                    this.chosenImageUrl = reader.result;
                };
                reader.readAsDataURL(this.newTagForm.icon);
                this.isEditable = true
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
                sharedUtils.sortLocalizations(this.newTagForm.localizations, this.primaryLanguage, this.editedLanguage)
            },
            showModal() {
                if (this.category.tags?.length > 0) {
                    this.$apollo.queries.canDeleteTag.setVariables({
                        tagIds: this.category.tags.map(tag => tag.id)
                    })
                    this.$apollo.queries.canDeleteTag.skip = false
                }
                this.$bvModal.show('delete-category-modal' + this.form.id)
            },
            editTagCategoryTitle() {
                this.isCategoryEditable = true
            },
            async createTag() {
                if (this.isLoadingTag) return
                this.error.newTagTitle = false
                if (!this.newTagForm.localizations[0].title) {
                    return this.error.newTagTitle = true
                }
                if (this.allTagTitles.has(this.newTagForm.localizations[0].title.toLowerCase())) {
                    return this.$emit('showAlertDanger', this.$t("alerts.tagCreateErrorExists", [this.newTagForm.localizations[0].title]))
                }
                this.isLoadingTag = true
                this.newTagForm.originalLanguage = this.primaryLanguage
                await TagService.createTag(this.newTagForm, this.category.id)
                    .then(resp => {
                        this.newTagForm.localizations.forEach(loc => loc.title = '')
                        this.newTagTitle = ''
                        this.$emit('showAlert', this.$t("alerts.tagCreated"))
                        this.newTagForm.icon = null
                    })
                    .catch(err => {
                        console.log(err)
                        this.$emit('showAlertDanger', this.$t("alerts.tagCreateError"))
                    })
                    .finally(() => {
                        this.isLoadingTag = false
                    })
            },

            async updateTagCategory() {
                if (this.isLoadingCategory) return
                this.error.title = false
                if (!this.form.localizations[0].title) {
                    return this.error.title = true
                }
                
                this.isLoadingCategory = true
                await TagService.updateTagCategory(this.form)
                    .then(resp => {
                        this.$emit('showAlert', this.$t("alerts.tagCategoryUpdated"))
                        this.isCategoryEditable = false
                    })
                    .catch(err => {
                        console.log(err)
                        this.$emit('showAlertDanger', this.$t("alerts.tagCategoryUpdateError"))
                    })
                    .finally(() => {
                        this.isLoadingCategory = false
                    })
            },
            
            async deleteTagCategory() {
                this.loadingDelete = this.$t("loading.tagCategoryDelete")
                await TagService.deleteTagCategory(this.form.id)
                    .then(resp => {
                        this.$root.$emit('showAlertTag', this.$t("alerts.tagCategoryDeleted"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.$emit('showAlertDanger', this.$t("alerts.tagCategoryDeleteError"))
                })
            },
        },

        watch: {
            primaryLanguage() {
                this.sortLocalizations()
            },
            editedLanguage() {
                this.sortLocalizations()
            }
        },

        apollo: {
            canDeleteTag: {
                query: CAN_DELETE_TAG,
                variables: function() {
                    return {
                        tagIds: this.category.tags.map(tag => tag.id)
                    }
                },
                result({data}) {
                    console.log(data, data.canDeleteTag)
                },
                skip: true
            }
        }
    }


</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .forms-container {
        column-gap: 30px;
        flex-wrap: wrap;

        
        form {
            width: 100%;
            flex: 0 0 calc(50% - 15px);
        }
        &.d-flex form {
            width: calc(50% - 15px);
        }
    }

    .travel-tip-links-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            font-size: 20px;
            margin-bottom: 6px;
        }

        a {
            color: $tr-green;
        }
    }

    p {
        margin: 0;
    }

    .tag-category {
        display: flex;
        flex-direction: column;
        margin-block: 20px 40px;

        .tags-title {
            margin-block: 30px 10px;
            font-size: 20px;
        }

        & > form {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .tag-list {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 40px;

            .forms-container {
                width: 100%;
                column-gap: 30px;
                flex-wrap: wrap;

                form {
                    flex: 0 0 calc(50% - 15px);
                    width: calc(50% - 15px);
                }
                .form-content-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 60px;
                    background-color: $tr-white-alt;

                    .equipment-category-icon-container {
                        text-align: center;

                        input[type="file"] {
                            display: none;
                        }

                        .icon-preview {
                            position: relative;
                            img, .remove-icon {
                                width: 60px;
                                height: 60px;
                            };

                            img {
                                padding: 16px;
                            }

                            .remove-icon {
                                display: none;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: rgba(255, 255, 255, 0.8);
                                border: 1px solid $tr-black;
                                font-size: 25px;
                            }
                            &:hover .remove-icon {
                                display: flex;
                            }
                        }

                        label {
                            cursor: pointer;
                            height: 60px;
                            width: 60px;
                            padding-inline: 18px;
                            border: 1px solid $tr-black;
                            line-height: 60px;
                            font-size: 20px;
                            background-color: $tr-white;

                            &:hover {
                                background-color: $tr-white-alt;
                            }
                        }
                    }
                }
            }

            .tag {
                width: 100%;
                display: flex;

                input {
                    resize: none;
                    height: 60px;
                    padding-block: 0px;
                    padding-inline: 18px;
                    background-color: $tr-white-alt;
                    color: $tr-black;
                    width: 100%;
                    border: 2px solid $tr-white-alt;
                    
                    &:focus {
                        border: 2px solid $tr-green !important;
                    }
                    &.error, &.error:focus {
                        border: 2px solid $tr-pink !important;
                    }
                }
            }
        }
        
    }
    .category-collapse {
        font-size: 25px;
        line-height: 25px;
        color: $tr-black;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: dotted 3px $tr-light-gray;
        border-top: dotted 3px $tr-light-gray;
        height: 80px;
        cursor: pointer;
        margin-top: -3px;
        
        & > button {
            flex-shrink: 0;
        }
        
        p {
            font-size: 25px;
            line-height: 60px;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            padding-top: 1px;
            padding-left: 39.5px;
        }

        input {
            width: 100%;
            margin-right: 20px;
            height: 78px;
            padding-left: 38px;
            border: 2px solid $tr-green;
        }

        &:hover{
            color: $tr-black;
            text-decoration: none;
        }

        &::after{
            content: "";
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 14px;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            background-size: 24px 15px;
        }

        &[aria-expanded="true"]{
            &::after{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            }
        }

    }

    .img-button {
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        aspect-ratio: 1;
        margin-left: 10px;
    }

    .delete-button {
        margin-right: 60px;
        background-image: url('data:image/svg+xml,<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="17.5" r="17.5" fill="%23222"/><path d="M12 23.142 23.142 12m.112 11.142L12.112 12" stroke="%23fff"/></svg>');
    }

    .edit-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23222"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path stroke="%23000" d="m17.646 9.354-2-2"/></svg>');
    }

    .confirm-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
    }

</style>