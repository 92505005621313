<template>
    <div class="tag">
        <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
            <form @submit.prevent="updateTag" v-for="(localization, index) in form.localizations" :key="localization.language">
                <div class="form-content-container d-flex" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                    <img v-if="index == 0 && (tag.iconFileName || chosenImageUrl)" @click.stop="openFilePicker"
                        :src="chosenImageUrl ? chosenImageUrl : iconUrl + tag.iconFileName" v-tooltip="$t('info.changeIcon')"
                    >
                    <div v-show="index == 0 && tag.id !== -1 && !tag.iconFileName && !chosenImageUrl" @click.stop="" v-tooltip.top="$t('info.addIcon')">
                        <input :id="'fileInput-' + tag.id" type="file" accept="image/svg+xml" @change="onInputFile">
                        <label :for="'fileInput-' + tag.id" class="add-icon-button">+</label>
                    </div>
                    <input v-if="isEditable" :ref="'tag-input' + form.id + localization.language" type="text" v-model.trim="localization.title" :class="{error: error.title}" maxlength="30">
                    <p v-else @focus="editTagTitle(localization.language)" @click="editTagTitle(localization.language)">{{ localization.title }}</p>
                    <div class="d-flex">
                        <button v-if="!isEditable" type="button" class="img-button edit-button" @click.prevent="editTagTitle(localization.language)"></button>
                        <button v-else class="img-button confirm-button"></button>
                        <button type="button" class="img-button delete-button" @click="showDeleteTagModal"></button>
                    </div>
                </div>
            </form>
        </div>
        <b-modal class="modal" :id="'delete-tag-modal' + form.id" hide-footer hide-header no-close-on-backdrop>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                <h3 class="moda-title pb-3">{{ $t("modals.tagDeleteTitle", [form.localizations[0].title])}}</h3>
                <div v-if="canDeleteTag && canDeleteTag.length" >
                    <div class="travel-tip-links-container">
                        <p>{{ $t("modals.tagDeleteText1") }}</p>
                        <div class="pl-4 d-flex flex-column">
                            <router-link v-for="travelTip in canDeleteTag" :key="travelTip.key" :to="'/admin/manageTravelTip/' + travelTip.id" target="_blank">{{travelTip.localizations[0].title}}</router-link>
                        </div>
                    </div>
                    <p  class="moda-text pb-5">{{ $t("modals.tagDeleteText2") }}</p>
                </div>
                <div class="pb-5 d-flex" style="gap: 40px">
                    <TrekioButton secondary :disabled="loadingDelete != ''"  @click="$bvModal.hide('delete-tag-modal' + form.id)">{{ $t("buttons.back") }}</TrekioButton>
                    <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete"  @click="deleteTag">{{ $t("buttons.delete") }}</TrekioButton>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
    import TagService from '../../services/TagService';
    import { CAN_DELETE_TAG } from '../../api/graphql/query/TagQuery';
    import sharedUtils from '../../utils/sharedUtils';
    import { CLEVERTRIP_ICON_URL } from '../../definitions';
    import fileUtils from '../../utils/fileUtils';

    export default {
        props: {
            tag: {
                type: Object,
                required: true
            },
            tagCategoryId: {
                required: true
            },
            isManagingLocalizations: {
                type: Boolean,
            },
            primaryLanguage: {
                type: String
            },
            editedLanguage: {
                type: String
            }
        },
        data() {
            return {
                iconUrl: CLEVERTRIP_ICON_URL,
                form: {
                    id: null,
                    title: '',
                    icon: null
                },
                chosenImageUrl: null,
                error: {
                    title: '',
                },
                isEditable: false,
                isLoading: false,
                loadingDelete: '',
            }
        },

        created() {
            this.form.id = this.tag.id
            this.form.originalLanguage = this.tag.originalLanguage
            this.form.localizations = this.tag.localizations.map(localization => {
                const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
                delete localizationCopy['__typename'];
                return localizationCopy
            })
            this.sortLocalizations()
        },

        watch: {
            primaryLanguage() {
                this.sortLocalizations()
            },
            editedLanguage() {
                this.sortLocalizations()
            }
        },

        methods: {
            openFilePicker() {
                document.getElementById('fileInput-' + this.tag.id).click();
            },
            async onInputFile(event){
                const file = event.target.files[0]
                if (!file) return
                const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(file.name)
                this.form.icon = new File([file], uniqueNameWithoutAccentsAndSpaces, {type: file.type});

                const reader = new FileReader();
                reader.onload = () => {
                    this.chosenImageUrl = reader.result;
                };
                reader.readAsDataURL(this.form.icon);
                this.isEditable = true
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
            },
            showDeleteTagModal() {
                this.$apollo.queries.canDeleteTag.skip = false
                this.$bvModal.show('delete-tag-modal' + this.form.id)
            },
            editTagTitle(localizationLanguageCode) {
                this.isEditable = true
                this.$nextTick(() => {
                    const tagInputRefName = 'tag-input' + this.form.id + localizationLanguageCode
                    this.$refs[tagInputRefName][0].focus()
                })
            },
            async updateTag() {
                if (this.isLoading) return
                this.error.title = false
                if (!this.form.localizations[0].title) {
                    return this.error.title = true
                }
                this.isLoading = true
                await TagService.updateTag(this.form, this.tagCategoryId)
                    .then(resp => {
                        this.isEditable = false
                        this.$root.$emit('showAlertTag', this.$t("alerts.tagUpdated"))
                        
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerTag', this.$t("alerts.tagUpdateError"))
                        console.log(err)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            async deleteTag() {
                this.loadingDelete = this.$t("loading.tagDelete")
                await TagService.deleteTag(this.form.id, this.tagCategoryId)
                    .then(resp => {
                        this.$root.$emit('showAlertTag', this.$t("alerts.tagDeleted"))
                    })
                    .catch(err => {
                        this.$root.$emit('showAlertDangerTag', this.$t("alerts.tagDeleteError"))
                        console.log(err)
                    })
                    .finally(() => {
                        this.loadingDelete = ''
                    })
            }
        },


        apollo: {
            canDeleteTag: {
                query: CAN_DELETE_TAG,
                variables: function() {
                    return {
                        tagIds: [this.form.id]
                    }
                },
                skip: true
            }
        }
    }


</script>

<style lang="scss" scoped>
    @import '@/scss/variables';


    .forms-container {
        width: 100%;
        column-gap: 30px;
        flex-wrap: wrap;

        form {
            flex: 0 0 calc(50% - 15px);
            width: calc(50% - 15px);
        }
        .form-content-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 60px;
            background-color: $tr-white-alt;

            img, .add-icon-button {
                cursor: pointer;
                width: 60px;
                height: 60px;
                padding-inline: 16px;
            }

            .add-icon-button {
                cursor: pointer;
                border: 1px solid;
                width: 60px;
                height: 60pX;
                display: flex;
                align-items: center;
                padding: 0;
                display: inline;
                display: flex;
                font-size: 20px;
                align-items: center;
                justify-content: center;
                background-color: $tr-white;

                &:hover {
                    background-color: $tr-white-alt;
                }
            }

            input[type="file"] {
                display: none;
            }
        }
    }
    .travel-tip-links-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        p {
            font-size: 20px;
            margin-bottom: 6px;
        }

        a {
            color: $tr-green;
        }
    }

    p {
        margin: 0;
    }
    .tag {
        width: 100%;

        p {
            cursor: pointer;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            padding-left: 19.5px;
            width: 100%;
            line-height: 60px;
            height: 60px;
            padding-top: 1px;

            &:focus {
                border: 2px solid $tr-green
            }
        }

        input {
            width: 100%;
            resize: none;
            padding: 0;
            background-color: $tr-white-alt;
            color: $tr-black;
            line-height: 20px;
            padding-left: 18px;
            height: 60px;
            border: 2px solid transparent !important;
            
            &:focus {
                border: 2px solid $tr-green !important;
            }

            &.error, &.error:focus {
                border: 2px solid $tr-pink !important;
            }
        }

        .img-button {
            background-size: 20px;
            background-position: center;
            background-repeat: no-repeat;
            width: 60px;
            height: 60px;
            
            &:hover {
                background-color: $tr-light-gray;
            }
        }

        .confirm-button {
            background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
        }

        .delete-button {
            background-size: 14px;
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><path d="M4.858 19.142 19 5" stroke="%23222"/><path d="M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5M4.858 19.142 19 5" stroke="%23000" stroke-opacity=".2"/><path d="M19.142 19.142 5 5" stroke="%23222"/><path d="M19.142 19.142 5 5m14.142 14.142L5 5m14.142 14.142L5 5m14.142 14.142L5 5" stroke="%23000" stroke-opacity=".2"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h24v24H0z"/></clipPath></defs></svg>');
        }

        .edit-button {
            background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23222"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path stroke="%23000" d="m17.646 9.354-2-2"/></svg>');
        }
    }

</style>