<template>
    <div>
        <div class="forms-container" :class="{'d-flex': isManagingLocalizations}">
            <form v-for="(localization, index) in form.localizations" :key="localization.language" @submit.prevent="updateEquipmentCategory">
                <a class="category-collapse" v-b-toggle="'equipment-category' + category.id" v-if="primaryLanguage == localization.language || isManagingLocalizations && editedLanguage == localization.language">
                    <img v-if="index == 0 && (category.iconFileName || chosenImageUrl)" @click.stop="openFilePicker"
                        :src="chosenImageUrl ? chosenImageUrl : iconUrl + category.iconFileName" v-tooltip="$t('info.changeIcon')"
                    >
                    <div v-show="index == 0 && category.id !== -1 && !category.iconFileName && !chosenImageUrl" @click.stop="" v-tooltip.top="$t('info.addIcon')">
                        <input :id="'fileInput-' + category.id" type="file" accept="image/svg+xml" @change="onInputFile">
                        <label :for="'fileInput-' + category.id" class="add-icon-button">+</label>
                    </div>
                    <p v-if="category.id === -1 || !isCategoryEditable">{{ localization.title }}</p>
                    <input v-else type="text" v-model="localization.title" :placeholder="$t('inputLabels.categoryTitle')" :class="{error: error.newTagTitle}" @click.stop="">
                    <button v-if="category.id !== -1 && !isCategoryEditable" type="button" class="img-button edit-button ml-auto" @click.stop="editEquipmentCategoryTitle(localization.language)"></button>
                    <button v-else-if="category.id !== -1" type="button" class="img-button confirm-button ml-auto" @click.stop="updateEquipmentCategory"></button>
                    <button v-if="category.id !== -1 && !isManagingLocalizations" type="button" class="img-button delete-button" @click.stop="showModal"></button>
                </a>
            </form>
        </div>
        <b-collapse :id="'equipment-category' + category.id">
            <div class="equipment-category">
                <div class="equipment-list" :class="{'w-50': !isManagingLocalizations}">
                    <Equipment ref="equipment" v-for="equipment in category.equipments" :key="equipment.id" :equipment="equipment" :equipmentCategoryId="category.id"
                    :isManagingLocalizations="isManagingLocalizations" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage"
                    />
                    <Equipment v-if="category.id !== -1" :equipmentCategoryId="category.id" :isManagingLocalizations="isManagingLocalizations" :primaryLanguage="primaryLanguage"/>
                </div>
            </div>
        </b-collapse>
        <b-modal class="modal" :id="'delete-category-modal' + form.id" hide-footer hide-header no-close-on-backdrop>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                <h3 class="moda-title">{{ $t("modals.equipmentCategoryDeleteTitle", [form.localizations[0].title]) }}</h3>
                <p class="modal-text pb-5">{{ $t("modals.equipmentCategoryDeleteText") }}</p>
                <div class="pb-5 d-flex" style="gap: 40px">
                    <TrekioButton secondary :disabled="loadingDelete != ''"  @click="$bvModal.hide('delete-category-modal' + form.id)">{{ $t("buttons.back") }}</TrekioButton>
                    <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="deleteEquipmentCategory">{{ $t("buttons.delete") }}</TrekioButton>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>
    import EquipmentService from '../../services/EquipmentService';
    import Equipment from './Equipment.vue';
    import sharedUtils from '../../utils/sharedUtils';
    import { CLEVERTRIP_ICON_URL } from '../../definitions';
    import fileUtils from '../../utils/fileUtils';

    export default {
        props: {
            category: {
                type: Object,
            },
            isManagingLocalizations: {
                type: Boolean,
                required: true
            },
            primaryLanguage: {
                type: String
            },
            editedLanguage: {
                type: String
            }
        },
        data() {
            return {
                iconUrl: CLEVERTRIP_ICON_URL,
                isCategoryEditable: false,
                form: {
                    id: null,
                    localizations: [],
                    icon: null,
                },
                chosenImageUrl: null,
                lastSavedForm: null,
                loading: '',
                loadingDelete: '',
                error: {
                    title: false,
                    code: false,
                }
            }
        },

        created() {
            this.form = {
                id: this.category.id,
                picture: this.category.picture,
                originalLanguage: this.category.originalLanguage,
                localizations: this.category.localizations?.map(localization => {
                    const localizationCopy = {...localization, language: localization.language ? localization.language : 'cs' }
                    delete localizationCopy['__typename'];
                    return localizationCopy
                })
            }
            this.lastSavedForm = this.createCopyOfForm(this.form)
            this.sortLocalizations()
        },

        watch: {
            primaryLanguage() {
                this.sortLocalizations()
            },
            editedLanguage() {
                this.sortLocalizations()
            }
        },

        components: {
            Equipment
        },

        methods: {
            openFilePicker() {
                document.getElementById('fileInput-' + this.category.id).click();
            },
            async onInputFile(event){
                const file = event.target.files[0]
                if (!file) return
                const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(file.name)
                this.form.icon = new File([file], uniqueNameWithoutAccentsAndSpaces, {type: file.type});

                const reader = new FileReader();
                reader.onload = () => {
                    this.chosenImageUrl = reader.result;
                };
                reader.readAsDataURL(this.form.icon);
                this.isCategoryEditable = true
            },
            isChangeInForms() {
                if (JSON.stringify(this.lastSavedForm) !== JSON.stringify(this.form)) return true
                return false  
            },
            createCopyOfForm(form) {
                return {
                    ...form,
                    localizations: form.localizations.map(localization => ({...localization}))
                }
            },
            editEquipmentCategoryTitle() {
                this.isCategoryEditable = true
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
                if (this.lastSavedForm) {
                    sharedUtils.sortLocalizations(this.lastSavedForm.localizations, this.primaryLanguage, this.editedLanguage)
                }
            },
            showModal() {
                this.$bvModal.show('delete-category-modal' + this.form.id)
            },

            validateInputs() {
                let numberOfErrors = 0
                this.error.title = false
                if (!this.form.localizations[0].title) {
                    numberOfErrors += 1
                    this.error.title = true
                }
                return numberOfErrors === 0
            },

            async updateEquipmentCategory() {
                if (!this.validateInputs()) return

                this.loading = this.$t("loading.equipmentCategoryUpdate")
                await EquipmentService.updateEquipmentCategory(this.form)
                    .then(resp => {
                        this.$emit('showAlert', this.$t("alerts.equipmentCategoryUpdated"))
                        this.isCategoryEditable = false
                        this.lastSavedForm = this.createCopyOfForm(this.form)
                    })
                    .catch(err => {
                        console.log(err)
                        this.$emit('showAlertDanger', this.$t("alerts.equipmentCategoryUpdateError"))
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            
            async deleteEquipmentCategory() {
                this.loadingDelete = this.$t("loading.equipmentCategoryDelete")
                await EquipmentService.deleteEquipmentCategory(this.form.id, this.category.equipments.map(eq => eq.id))
                    .then(resp => {
                        this.$root.$emit('showAlertEquipment', this.$t("alerts.equipmentCategoryDeleted"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.$emit('showAlertDanger', this.$t("alerts.equipmentCategoryDeleteError"))
                    })
                    .finally(() => this.loadingDelete = '')
            },
        },
    }


</script>

<style lang="scss" scoped>
    @import '@/scss/variables';


    .forms-container {
        column-gap: 30px;
        flex-wrap: wrap;

        form {
            max-width: calc(50% - 15px);
            width: 100%;
            flex: 0 0 calc(50% - 15px);
        }
    }

    p {
        margin: 0;
    }

    .equipment-category {
        display: flex;
        flex-direction: column;
        margin-block: 30px 50px;

        .equipment-title {
            margin-block: 30px 10px;
            font-size: 20px;
        }

        & > form {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            & > * {
                flex-basis: 250px;
                flex-shrink: 1;
                flex-grow: 1;
            }
        }

        .equipment-list {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
        }
        
    }
    .category-collapse {
        font-size: 25px;
        line-height: 25px;
        color: $tr-black;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: dotted 3px $tr-light-gray;
        border-top: dotted 3px $tr-light-gray;
        height: 80px;
        cursor: pointer;
        margin-top: -3px;
        padding-right: 60px;

        img, .add-icon-button {
            width: 40px;
            height: 40px;
            padding-inline: 8px;
            margin-right: 10px;
            margin-left: 10px;
        }

        .add-icon-button {
            cursor: pointer;
            border: 1px solid;
            width: 40px;
            height: 40pX;
            margin-right: 10px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            padding: 0;
            display: inline;
            display: flex;
            font-size: 20px;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            max-width: 40px;

            &:hover {
                background-color: $tr-white-alt;
            }
        }

        input[type="file"] {
            display: none;
        }

        p {
            font-size: 25px;
            width: 100%;
            line-height: 60px;
            overflow: hidden;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            padding-top: 1px;
            padding-left: 29.5px;
        }

        input {
            width: 100%;
            height: 78px;
            padding-left: 28px;
            border: 2px solid $tr-green;
        }

        &:hover{
            color: $tr-black;
            text-decoration: none;
        }

        &::after{
            content: "";
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 14px;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            background-size: 24px 15px;
        }

        &[aria-expanded="true"]{
            &::after{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            }
        }

    }

    .img-button {
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        width: 80px;
        height: 80px;
        aspect-ratio: 1;

        &:hover {
            background-color: $tr-light-gray;
        }
    }

    .delete-button {
        background-image: url('data:image/svg+xml,<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="17.5" cy="17.5" r="17.5" fill="%23222"/><path d="M12 23.142 23.142 12m.112 11.142L12.112 12" stroke="%23fff"/></svg>');
    }

    .edit-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23222"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path d="M21.142 5.828 8.167 18.804l-3.915.783.783-3.915L18.01 2.697l3.132 3.131Z" stroke="%23000" stroke-opacity=".2"/><path stroke="%23000" d="m17.646 9.354-2-2"/></svg>');
    }

    .confirm-button {
        background-image: url('data:image/svg+xml,<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m2 5.5 8.5 8.5 12-12" stroke="%233BEA7E" stroke-width="3"/></svg>');
    }

</style>