export default {
    defaultItemDifficulty: "Choose an adequate difficulty of this point of interest and the route leading to it according to {explanationLink} explanation.",
    startItemDifficulty: "If the starting point is also a point of interest, choose its difficulty according to {explanationLink} explanation.",
    finishItemDifficulty: "Choose an adequate difficulty of the end point and the route leading to it according to {explanationLink} explanation.",
    itemDifficultyLinkText: "this",

    maxAmountOfTravelTipsInProgressReached: "You can't have more than 10 trip drafts.",
    allTravelTips: "All your trips.",
    travelTipsInProgress: "Drafted trips that you have not yet submitted to the administrators for review and publication on the website. You can have a maximum of 10.",
    travelTipsToRework: "Trips that you have submitted for review before publishing and an administrator asks you to make a change.",
    travelTipsToControl: "Trips that you have submitted for review before publishing. There are two stages to the review process: first review and then approval.",
    travelTipsToApprove: "Trips that you have submitted and have already passed the first stage of pre-publication review. They are still awaiting the approval phase.",
    travelTipsPublished: "All your trips that have already been approved and are now publicly available on the website.",
    travelTipsNotPublished: "Your trips that have been reviewed and approved for publication, but are not currently posted on the website. (These may also be trips that have already been published, but don't fit into any category at the moment or don't fit the current season.)",
    travelTipsDeclined: "Trips that have been rejected for publication (you can read why in the comments). They are automatically deleted after 30 days of rejection.",
    travelTipTitle: "What is the name of your itinerary?\nThe name is the first information about the trip. It has to be both interesting and informative. Are you going on a hike or to a spa? And where will it be?",
    travelTipShortInfo: "The subtitle is displayed after clicking on the trip card, extending the title.\nCapture the atmosphere in a few words or add more info to the title.",
    travelTipPerex: "Lead paragraph are the first two bold lines in the trip description.",
    travelTipDescription: "Brief description of the trip as a whole.",
    travelTipPracticalInfo: "Include practical information here to help travelers decide whether to go on this trip, and then plan it. Tell them where to look for accommodation, if they need any special knowledge or equipment, share links which are useful before the trip etc.",
    travelTipHashtags: "How would you tag your trip on social media?\nEnglish, Czech, other languages or a mix.",
    travelTipTheme: "Select the option that best fits.\nTrips can be filtered by category, the category is also displayed directly on the trip card.",
    travelTipPicture: "Add only photo that you have a license for! This means that it is a photo taken by you without identifiable people, or you have obtained the license directly from the author and all models. For example, a picture from a stock photo site may correspond to this, but make sure you read and follow all the rules and conditions.\nThis photo will be the title photo of the trip. It will appear on the trip card, and in the trip detail it will be the main photo that appears as the background of the title.",
    travelTipPreview: "Open a preview in a new window of how all saved changes would look to a public user",

    dayName: "Think of an apt name for this day. If you can't think of one, think about the activities and where they happen.",
    dayDescription: "Briefly describe the day. Don't go into detail, that's what the individual points of interest are for.",
    dayPicture: "Choose the day title photo from the photos you have assigned to each point of interest. The day title photo will be displayed in the day overview in the Trekio mobile app. If you remove the selected photo from a point of interest, it will also be removed as the day title photo.",
    itemLatitude: "It is automatically filled in by selecting a point on the map or you can enter the value as a decimal number. A positive value corresponds to north latitude (N), a negative value to south latitude (S).",
    itemLongitude: "It is automatically filled in by selecting a point on the map or you can enter the value as a decimal number. A positive value corresponds to east longitude (E), a negative value to west longitude (W).",
    itemCountriesDefault: "Select the country where the point of interest is located and countries through which goes the route from the previous point of interest or starting point. You can select multiple countries.",
    itemCountriesStart: "Select the country where the starting point of today's route is located.",
    itemCountriesFinish: "Select the country where the end point of today's route is and countries through which goes the route from the previous point of interest. You can select more than one country.",
    itemTransferType: "How do travelers get to the point of interest from the previous point of interest or starting point? If they have to change on the way, you can add another means of transportation (max 5).",
    itemTransferLength: "Distance for this means of transportation. (If you are transferring, divide the length of the entire journey to the point of interest appropriately between the different means of transportation.)\nYou can enter integers or decimals.",
    itemTitleDefault: "Name of place (e.g. Taj Mahal) or route (e.g. Paris-Dakar)",
    itemTitleStart: "The name of the starting point of today's route.",
    itemTitleFinish: "The name of the end-point of today's route or the last leg of the route.",
    itemDescriptionDefault: "Describe the route to the point of interest and the place itself. What makes it interesting, why should you stop here, what will you see or experience?",
    itemDescriptionStart: "If the starting point is an interesting place, you can describe it here.",
    itemDescriptionFinish: "Describe the route to the day's end point and the place itself, if it is interesting.",
    itemPracticalInfoDefault: "Write here practical information such as a link to a page with admission prices and opening hours. If you need special equipment or knowledge for a particular point of interest, put that here too.\nWhen you put a link in this field, it will be displayed as a clickable URL.",
    itemPracticalInfoStart: "Write here practical information such as where it is best to park and how much it costs, or special equipment if needed for the starting poing.\nWhen you put a link in this field, it will appear as a clickable URL.",
    itemDuration: "How do you estimate people will spend at this point of interest? (The journey does not count towards this time.)",
    itemPictures: "Add only photos that you have a license for! This means that the photo was taken by you and there are no identifiable people, or you have obtained the license directly from the author and all models. This may be a picture from a stock photo site, but make sure you read and follow all the rules and conditions.",
    itemCopyDataInfoStart: "Copy the location, point of interest name and countries from the previous day's end point of the day",
    itemCopyDataInfoFinish: "Copy the location, point of interest name and countries from this day's starting point of the day",
    
    userNameUser: "It will appear when you interact with trips and other users (e.g. trip reviews).",
    userNameAuthor: "The name or nickname that will appear with your trips if you choose to be credited as the author.",
    email: "An email address we can use to contact you. It also serves as a login for your account.",
    password: "Password must have at least 8 characters, one uppercase and one lowercase letter, and one symbol",
    firstNameUser: "If you want, you can give us your first name. But it's not required.",
    firstNameAuthor: "We need your full name for the license agreement regarding your trips.",
    lastNameUser: "If you want, you can give us your last name. But it's not required.",
    lastNameAuthor: "We need your full name for the license agreement regarding your trips.",
    dateOfBirth: "We need your birth date for the license agreement regarding your trips.",
    phoneNumber: "If you want, you can provide a phone number for more efficient communication.",
    address: "We need your address for a license agreement regarding your trips.",
    aboutAuthor: "If you choose to be credited as the author of your trips, your nickname, photo and short bio will be displayed with your trips.",

    travelTipComments: "Here you can communicate with administrators about your trip. They will write to you when the trip goes through the next step of the approval process. Or they will recommend what would be good to change. You can reply to them or let them know if you find out that some factual information has changed and the trip needs to be updated.",

    pinboardEndDate: "This date will expire the post. It will then be deleted along with all related messages.",

    equipmentTemplates: "Adds equipments based on the type of trip. \nIt is possible to use multiple templates.",

    // ToolTips
    totalRouteLengthToItem: "Total distance to the point of interest.",
    itemEstimatedTime: "Estimated time spent at this point of interest.",
    deleteAssignedPicture: "You can't delete a photo that is associated with a trip that is NOT in one of these states - 'In progress', 'To rework'",
    updateAssignedPicture: "You can't edit a photo that is associated with a trip that is NOT in one of these states - 'In progress', 'To rework'",
    changeTravelTipPicture: "Change title photo of the trip",
    changeDayPicture: "Change title photo",
    itemChangeOrder: "Change order",
    chooseAsDayPicture: "Select as day title photo",

    loginToWriteAMessage: "Log in to write a message to the post author",

    routeMayDiffer: "The route calculated by mapy.cz may differ!",

    removeFromFavourites: "Remove from saved",
    addToFavourites: "Save",
    loginToAddToFavourites: "Log in to save trip to favourites",
    addItem: "Add item",
    markAsImportant: "Mark as 'Important'",
    disabledInPreview: "Disabled in preview",
    addIcon: "Add icon",
    changeIcon: "Change icon",
}