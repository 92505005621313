<template>
    <div>
        <p v-if="$apollo.loading">{{ $t('loading.loading') }}</p>
        <div v-else class="px-sm-3">
            <div class="d-flex justify-content-end mb-3">
                <button v-if="shouldShowListSwitch" class="text-button show-original" @click="isOriginalEquipmentListVisible = !isOriginalEquipmentListVisible">
                    {{ isOriginalEquipmentListVisible ? $t("buttons.showMyList") : $t("buttons.showOriginalList") }}
                </button>
                <button v-if="$store.state.currentUserId && !isPreviewEquipmentList" @clic.stop="" class="img-button edit-button ml-1 ml-sm-3" :class="{active: isEditable}" @click="isEditable = !isEditable">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.143 5.828 8.168 18.804l-3.915.783.783-3.915L18.011 2.697z" stroke="#222"/>
                        <path stroke="#222" d="m17.646 9.354-2-2"/>
                    </svg>
                </button>
                <button v-if="equipmentListByCategories && equipmentListByCategories.flatMap(category => category.equipments).length > 0" @click="exportToTextFile" class="img-button download-button">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#a)">
                            <path d="M6 15v4h12v-4" stroke="#222"/>
                            <path d="m16 10-4 4-4-4" stroke="#222"/>
                            <path d="M12 14V3" stroke="#222"/>
                        </g>
                    </svg>
                </button>
            </div>
            <div>
                <p v-if="travelTipEquipments && travelTipEquipments.length === 0 && personalEquipmentsForCurrentUser && personalEquipmentsForCurrentUser.length === 0">
                    {{ $t("public.travelTipDetailPage.packingListInfo") }}
                </p>
                <div v-for="category in equipmentListByCategories" :key="category.id">
                    <div class="category-container">
                        <div class="category-title-container">
                            <img v-if="category.iconFileName" :src="iconUrl + category.iconFileName" alt="icon">
                            <p class="category-title">{{ category.title }}</p>
                            <button v-if="isEditable" class="add-equipment-button" v-tooltip.top-end="$t('info.addItem')" @click="createEquipment(category)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6V18" stroke="#29414D"/>
                                    <path d="M18 12H6" stroke="#29414D"/>
                                </svg>
                            </button>
                        </div>
                        <ul>
                            <PackingItem :ref="'equipment' + equipment.id" v-for="equipment in category.equipments" :key="equipment.id" @copyTravelTipEquipments="copyTravelTipEquipments"
                            :equipment="equipment" :isTripEquipment="isOriginalEquipmentListVisible" :isEditable="isEditable" :isPreviewEquipment="isPreviewEquipmentList"
                            @deletePersonalEquipment="deletePersonalEquipment"
                            />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TRAVEL_TIP_EQUIPMENTS, PERSONAL_EQUIPMENTS, EQUIPMENT_CATEGORIES } from '../api/graphql/query/EquipmentQuery'
import PackingItem from '@/components/PackingItem'
import EquipmentService from '../services/EquipmentService';
import { CLEVERTRIP_ICON_URL } from '../definitions';

    export default {
        props: {
            travelTipId: {
                required: true
            }
        },
        data() {
            return {
                iconUrl: CLEVERTRIP_ICON_URL,
                isOriginalEquipmentListVisible: true,
                travelTipEquipmentListByCategories: [],
                userEquipmentListByCategories: [],
                isEditable: false,
                loading: ''
            }
        },

        components: {
            PackingItem
        },
        
        watch: {
            travelTipId() {
                this.isOriginalEquipmentListVisible = true
            },
            chosenEquipments(nV) {
                if (!this.isOriginalEquipmentListVisible && nV.length === 0) {
                    this.isEditable = true
                }
            },
            isOriginalEquipmentListVisible(nV) {
                if (nV) {
                    this.isEditable = false
                }
                else if (!nV && this.chosenEquipments.length === 0) {
                    this.isEditable = true
                } 
            },
            isEditable(nV) {
                if (!nV) {
                    this.travelTipEquipmentListByCategories.forEach(category => {
                        category.equipments = category.equipments.filter(equipment => {
                            if (equipment.title == '') return false
                            return true
                        })
                    })
                }
            },
        },
        
        computed: {
            isPreviewEquipmentList() {
                return this.isOriginalEquipmentListVisible && this.personalEquipmentsForCurrentUser?.length > 0
            },
            isPackingListAvailable() {
                return this.travelTipEquipments?.length > 0 || this.$store.state.currentUserId != null
            },
            shouldShowListSwitch() {
                if (!this.$store.state.currentUserId) return false
                if (this.travelTipEquipments?.length === 0) return false
                if (this.personalEquipmentsForCurrentUser?.length === 0 && this.isOriginalEquipmentListVisible) return false
                if (this.personalEquipmentsForCurrentUser?.length > 0 && this.travelTipEquipments?.length === 0) return false
                return true
            },
            equipmentListByCategories() {
                return this.isOriginalEquipmentListVisible ? this.travelTipEquipmentListByCategories : this.userEquipmentListByCategories
            },
            chosenEquipments() {
                return this.userEquipmentListByCategories.flatMap(category => category.equipments)
            }
        },

        methods: {
            exportToTextFile() {
                let textToExport = ""
                this.equipmentListByCategories.forEach(category => {
                    textToExport += '- ' + category.title
                    category.equipments.forEach(eq =>{
                        if (eq.title) {
                            textToExport += '\n\t' + '- ' + eq.title
                        }
                    })
                    textToExport += '\n\n'
                    
                })

                const blob = new Blob([textToExport], { type: 'text/plain' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = this.$t("public.travelTipDetailPage.packingList") + '.txt'
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            async copyTravelTipEquipments(equipmentIdToSkip = null) {
                const equipmentsToCreate = this.travelTipEquipmentListByCategories.flatMap(category => category.equipments)
                for (const equipment of equipmentsToCreate) {
                    if (equipmentIdToSkip && equipment.id == equipmentIdToSkip) continue
                    await EquipmentService.createPersonalEquipment(equipment)
                        .then(resp => {
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } 
            },
            async deletePersonalEquipment(equipment) {
                if (this.isOriginalEquipmentListVisible && this.personalEquipmentsForCurrentUser.length === 0) {
                    await this.copyTravelTipEquipments(equipment.id)
                    return
                }
                EquipmentService.deletePersonalEquipment(equipment.id, this.travelTipId)
                        .then(resp => {
                            console.log(resp)
                        })
                        .catch(err => {
                            console.log(err)
                        })
            },
            async createEquipment(category) {
                if (this.isOriginalEquipmentListVisible && this.personalEquipmentsForCurrentUser.length === 0) {
                    await this.copyTravelTipEquipments()
                    await this.createEquipment(category)
                    return
                }
                await EquipmentService.createPersonalEquipment({
                    equipmentCategoryId: category.id !== -1 ? category.id : null ,
                    title: "",
                    productLink: "",
                    travelTipId: this.travelTipId,
                    quantity: 1,
                    settled: false,
                    packed: false
                })
                .then(({data}) => {
                    this.$nextTick(() => {
                        this.$refs['equipment' + data.createPersonalEquipment.id][0].$refs['title-input']?.focus()
                    })
                })
            },
            setEquipmentListByCategories(travelTipEquipments) {
                let categoriesArray = this.equipmentCategories.map(category => {
                    const categoryLocalization = category.localizations.find(loc => loc.language === this.$store.state.preferredLanguage)
                    return {
                        ...category,
                        equipments: [],
                        title: categoryLocalization ? categoryLocalization.title : null 
                    }
                });
                categoriesArray.push({
                    id: -1,
                    title: this.$t("general.other"),
                    equipments: [],
                })
                travelTipEquipments.forEach((travelTipEquipment) => {
                    const equipment = travelTipEquipment.equipment
                    const categoryId = equipment.category ? equipment.category.id : -1;
                    let categoryObject = categoriesArray.find((category) => category.id === categoryId);
                    // If categoryObject not found, the category localization is not published
                    if (!categoryObject) {
                        categoryObject = categoriesArray.find(category => category.id == -1) 
                    }
                    const equipmentLocalization = equipment.localizations.find(loc => loc.language == this.$store.state.preferredLanguage)
                    if (equipmentLocalization?.title !== "" && equipmentLocalization?.title !== undefined && equipmentLocalization?.title !== null) {
                        categoryObject.equipments.push({
                            id: equipment.id,
                            equipmentCategoryId: equipment.category?.id,
                            title: equipmentLocalization.title,
                            productLink: equipmentLocalization.productLink,
                            travelTipId: this.travelTipId,
                            quantity: travelTipEquipment.quantity,
                            settled: false,
                            packed: false,
                            importance: travelTipEquipment.importance
                        })
                    }
                });
                categoriesArray = categoriesArray.filter(category => {
                    if (category.id === -1) return true
                    return category.equipments.length !== 0
                })
                this.travelTipEquipmentListByCategories = categoriesArray
            }
        },

        apollo: {
            equipmentCategories: {
                query: EQUIPMENT_CATEGORIES,
            },
            travelTipEquipments: {
                query: TRAVEL_TIP_EQUIPMENTS,
                variables: function() {
                    return {
                        travelTipId: this.travelTipId
                    }
                },
                skip: function() {
                    return !this.equipmentCategories || !this.travelTipId
                },
                result({data}) {
                    this.setEquipmentListByCategories(data.travelTipEquipments)
                }
            },

            personalEquipmentsForCurrentUser: {
                query: PERSONAL_EQUIPMENTS,
                variables: function() {
                    return {
                        travelTipId: this.travelTipId
                    }
                },
                result({data}) {
                    if (this.travelTipEquipments?.length === 0 || data.personalEquipmentsForCurrentUser.length > 0) {
                        this.isOriginalEquipmentListVisible = false
                    }
                    let categoriesArray = this.equipmentCategories.map(category => {
                        const categoryLocalization = category.localizations.find(loc => loc.language === this.$store.state.preferredLanguage)
                        return {
                            ...category,
                            equipments: [],
                            title: categoryLocalization ? categoryLocalization.title : null 
                        }
                    });
                    categoriesArray.push({
                        id: -1,
                        title: this.$t("general.other"),
                        equipments: [],
                    })
                    data.personalEquipmentsForCurrentUser.forEach((equipment) => {
                        const categoryId = equipment.category ? equipment.category.id : -1;
                        let categoryObject = categoriesArray.find((category) => category.id === categoryId);
                        if (!categoryObject) {
                            categoryObject = categoriesArray.find(category => category.id == -1) 
                        }
                        categoryObject.equipments.push({
                            id: equipment.id,
                            equipmentCategoryId: equipment.category?.id,
                            title: equipment.title,
                            productLink: equipment.productLink,
                            travelTipId: this.travelTipId,
                            quantity: equipment.quantity,
                            settled: equipment.settled,
                            packed: equipment.packed
                        })
                    });
                    categoriesArray = categoriesArray.filter(category => {
                        if (category.id === -1) return true
                        return category.equipments.length !== 0
                    })
                    this.userEquipmentListByCategories = categoriesArray
                },
                skip: function() {
                    return !this.equipmentCategories || !this.travelTipEquipments || !this.travelTipId || !this.$store.state.currentUserId
                }
            },
        }
    }


</script>

<style lang="scss" scoped>
  @import '@/scss/variables';

  .show-original {
        color: $tr-gray;
        font-size: 12px;
    }

    .edit-button, .download-button {
        width: 40px;
        height: 40px;

        &.active path, &:hover path {
            stroke: $tr-green;
        }
    }

    .category-container {
        margin-bottom: 30px;

        .category-title-container {
            img {
                width: 24px;
                height: 24px;
                margin-right: 10px;
            }

            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }
    
        .category-title {
        font-size: 20px;
        margin: 0;
        }

        .add-equipment-button {
            background: none;
            border: none;
            margin-left: 10px;
            padding: 0;

            &:hover svg path {
                stroke: $tr-green;
            }
        }
    
        ul {
            padding: 0;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            gap: 12px 6px;
        }
    }

</style>