
<template>
  <div class="text-left bg-white-alt">
    <NavBarNew :greenLogo='true'/>
    <main class="tr-container">
      <section>
          <h4>{{ $t("termsOfUse.usingPlatformTrekioAgreement")}}</h4>
      </section>
      <section>
          <h2>I. {{ $t("termsOfUse.definitions")}}</h2>
          <ol type="A">
              <li>
                <i18n path="termsOfUse.definitionsParagraph1" :tag="false">
                    <template v-slot:trekioUrl>
                        <a href="https://trekio.com">https://trekio.com</a>
                    </template>
                </i18n>
            </li>
              <li>{{ $t("termsOfUse.definitionsParagraph2")}}</li>
              <li>{{ $t("termsOfUse.definitionsParagraph3")}}</li>
          </ol>
      </section>
      <section>
          <h2>II. {{ $t("termsOfUse.profileRegistrationAndSecurity")}}</h2>
          <ol type="A">
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph1")}}</li>
              <i18n path="termsOfUse.profileRegistrationAndSecurityParagraph2">
                <template v-slot:principlesDataProcessingUrl>
                    <router-link to="/principlesDataProcessing">{{ $t("general.principlesDataProcessing") }}</router-link>
                </template>
              </i18n>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph3")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph4")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph5")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph6")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph7")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph8")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph9")}}</li>
              <li>{{ $t("termsOfUse.profileRegistrationAndSecurityParagraph10")}}</li>
          </ol>
      </section>
      <section>
          <h2>III. {{ $t("termsOfUse.rightsToUploadedContent")}}</h2>
          <ol type="A">
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph1")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph2")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph3")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph4")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph5")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph6")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph7")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph8")}}</li>
              <li>{{ $t("termsOfUse.rightsToUploadedContentParagraph9")}}</li>
          </ol>
      </section>
      <section>
          <h2>IV. {{ $t("termsOfUse.termsOfUse")}}</h2>
          <ol type="A">
              <li>{{ $t("termsOfUse.termsOfUseParagraph1")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph2")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph3")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph4")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph5")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph6")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph7")}}</li>
              <li>{{ $t("termsOfUse.termsOfUseParagraph8")}}</li>
          </ol>
      </section>
      <section>
          <h2>V. {{ $t("termsOfUse.violationOfUserTermsAndConditions")}}</h2>
          <ol type="A">
                <i18n path="termsOfUse.violationOfUserTermsAndConditionsParagraph1" :tag="false">
                    <template v-slot:trekioInfoEmail>
                        <a href="mailto:info@trekio.com">info@trekio.com</a>
                    </template>
                </i18n>
              <li>{{ $t("termsOfUse.violationOfUserTermsAndConditionsParagraph2")}}</li>
          </ol>
      </section>
      <section>
          <h2>VI. {{ $t("termsOfUse.ourLiabilityForTheContent")}}</h2>
          <ol type="A">
              <li>{{ $t("termsOfUse.ourLiabilityForTheContentParagraph1")}}</li>
              <li>{{ $t("termsOfUse.ourLiabilityForTheContentParagraph2")}}</li>
              <li>{{ $t("termsOfUse.ourLiabilityForTheContentParagraph3")}}</li>
          </ol>
      </section>
      <p>{{ $t("termsOfUse.theseTermsTakeEffectOn") }}</p>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {

  name: "TermsOfUsePage",
  metaInfo() {
    return {
      title: this.$t('meta.termsOfUseTitle'),
      meta: [
        {
          vmid: 'description',
          name: "description",
          content: this.$t('meta.termsOfUseDescription')
        }
      ]
    }
  },
  components: {
    Footer
  }
}
</script>

<style scoped lang="scss">

  main {
      padding-top: 20px;
      margin-bottom: 60px;
  }

  section {
      padding-bottom: 40px;
  }

</style>