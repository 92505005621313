<template>
  <div class="text-left bg-white-alt">
    <NavBarNew :greenLogo='true'/>
    <main>
      <section class="tr-container tr-glasses tr-glasses-jak-to-funguje--intro">
        <header class="tr__header">
          <h1 class="tr__h1">{{ $t("public.howToPage.howItAllWorks") }}</h1>
        </header>
        <article style="padding-bottom: 120px;t">
          <div class="row">
            <div class="col-12 col-lg-7 offset-lg-5" style="">
              <h3 class="tr__h3">{{ $t("public.howToPage.iWantToGoOnATripTitle") }}</h3>
              <p>
                {{ $t("public.howToPage.iWantToGoOnATripText") }}
              </p>
            </div>
          </div>
        </article>
      </section>

      <section class="tr-container how-to-page-no-overflow">
        <div class="row">
          <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg">
            <div class="tr-photo-card tr-photo-card--nobg minimaxicard" id="card-with-points">
              <div class="tr-spec-border tr-spec-border--bottom-only tr-bottom-line tr-bottom-line--lg">
                <div class="tr-bg-white">
                  <img alt="some alt text" src="../../assets/svg/ManageTraveltipDumyImage.svg" class="tr-points-img">
                  <div class="row">
                    <div class="col-6 category" :class="{'tr-active-point': activatedItem === 'CATEGORY'}">
                      <span class="tr-pl-15 text-center">{{ $t("public.howToPage.culture") }}</span>
                    </div>
                    <div class="col-6">
                      <p :class="{'tr-active-point': activatedItem === 'LENGTH'}" class="text-right">{{ $t("public.howToPage.threeDays") }}</p>
                    </div>
                  </div>
                  <h3 class="tr__h3" :class="{'tr-active-point': activatedItem === 'NAME'}">Opera la Scala</h3>
                  <p class="tr__p--popisek" :class="{'tr-active-point': activatedItem === 'COUNTRY'}">{{ $t("public.howToPage.italy") }}</p>
                </div>
              </div>
              <div class="tr-spec-border tr-spec-border--top-only bottom-part">
                <div class="tr-bg-white h-100">
                  <svg class="icon" :class="{'tr-active-point': activatedItem === 'HEART'}" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g mask="url(#mask0_737_1277)">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.31802 5.37258C6.07538 3.54247 8.92462 3.54247 10.682 5.37258L12 6.74517L13.318 5.37258C15.0754 3.54247 17.9246 3.54247 19.682 5.37258C21.4393 7.20269 21.4393 10.1699 19.682 12L12 20L4.31802 12C2.56066 10.1699 2.56066 7.20269 4.31802 5.37258Z" stroke-linecap="round"/>
                    </g>
                  </svg>
                  <svg class="icon" :class="{'tr-active-point': activatedItem === 'SHARE'}" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#a)">
                      <circle cx="17" cy="7" r="2.5"/>
                      <circle cx="6" cy="13" r="2.5"/>
                      <path d="M8.5 12 15 8.5" />
                      <path d="m8.5 14 6.5 3.5" />
                      <circle cx="17" cy="18" r="2.5"/>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-7 offset-lg-1 tr-order-1 tr-order-2--lg">
            <h3 class="tr__h3">{{ $t("public.howToPage.howToChooseTitle") }} </h3>
            <p>
              <i18n path="public.howToPage.howToChooseText" :tag="false">
              <template v-slot:country>
                <strong @mouseover="activateitem('COUNTRY')" @mouseout="deactivateitem()">{{ $t('public.howToPage.country')}}</strong>
              </template>
              <template v-slot:numberOfDays>
                <strong @mouseover="activateitem('LENGTH')" @mouseout="deactivateitem()">{{ $t('public.howToPage.numberOfDays')}}</strong>
              </template>
              <template v-slot:whatInterestsYouWhenTraveling>
                <strong @mouseover="activateitem('CATEGORY')" @mouseout="deactivateitem()">{{ $t('public.howToPage.whatInterestsYouWhenTraveling')}}</strong>
              </template>
            </i18n>
            </p>
            <p>
              <i18n path="public.howToPage.howToChooseText2" :tag="false">
                <template v-slot:share>
                  <strong @mouseover="activateitem('SHARE')" @mouseout="deactivateitem()">{{ $t('public.howToPage.share')}}</strong>
                </template>
              </i18n>
            </p>
            <p>
              <i18n path="public.howToPage.howToChooseText3" :tag="false">
                <template v-slot:heartIcon>
                  <strong @mouseover="activateitem('HEART')" @mouseout="deactivateitem()">{{ $t('public.howToPage.heartIcon')}}</strong>
                </template>
              </i18n>
            </p>
          </div>

        </div>
      </section>

      <section class="tr-container">
        <div class="row">
          <div class="col-12 col-lg-4">
          </div>
          <div class="col-12 col-lg-7 offset-lg-1">
            <h3 class="tr__h3">{{ $t("public.howToPage.foundATripTitle")}}</h3>
            <p>
              {{ $t("public.howToPage.foundATripText")}}
            </p>
            <p>
              {{ $t("public.howToPage.foundATripText2")}}
            </p>
          </div>
        </div>
      </section>

      <section class="tr-container" style="margin-top: 60px">
        <div class="row">
          <div class="col-12 col-lg-4"></div>
          <div class="col-12 col-lg-7 offset-lg-1">
            <h3 class="tr__h3">{{ $t("public.howToPage.rateTheTripYouLikedTitle")}}</h3>
            <p>
              {{ $t("public.howToPage.rateTheTripYouLikedText")}}
            </p>
            <p>
              <i18n path="public.howToPage.rateTheTripYouLikedText2" :tag="false">
                <template v-slot:contactPageLink>
                  <router-link to="/contact">{{ $t('general.contact')}}</router-link>
                </template>
              </i18n>
            </p>
            <p>
              {{ $t("public.howToPage.rateTheTripYouLikedText3")}}
            </p>
          </div>
        </div>
      </section>
      <section class="tr-container" style="margin-top: 60px">
        <div class="row">
          <div class="col-12 col-lg-4"></div>
          <div class="col-12 col-lg-7 offset-lg-1">
            <h3 class="tr__h3">{{ $t("public.howToPage.iWantToShareTripsTitle")}}</h3>
            <p>
              {{ $t("public.howToPage.iWantToShareTripsText")}}
            </p>
            <p class="pt-3">
              <router-link to="/workWithUs" class="button button--primary">{{ $t("buttons.becomeAuthor")}}</router-link>
            </p>
          </div>
        </div>
      </section>
    </main>
    <Footer style="margin-top: 173px"/>
    <div class="to-top-arrow" @click="scrollToTop"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {

  name: "HowToPage",
  metaInfo() {
    return {
      title: this.$t('meta.howToTitle'),
      meta: [
        {
          vmid: 'description',
          name: "description",
          content: this.$t('meta.howToDescription')
        }
      ]
    }
  },
  components: {
    Footer
  },
  data() {
    return {
      activatedItem: null,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showButtonToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.showButtonToTop)
  },
  methods: {
    showButtonToTop() {
      if (this.throttleTimer) return;
        this.throttleTimer = true;
        setTimeout(() => {
            const scrollButton = document.querySelector('.to-top-arrow')
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
              scrollButton.classList.add("show");
            } else {
              scrollButton.classList.remove('show');
            }
            this.throttleTimer = false;
          }, 100);
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    activateitem(itm) {
      this.activatedItem = itm
    },
    deactivateitem() {
      this.activatedItem = null
    }
  },
}


</script>

<style scoped lang="scss">

@import '@/scss/variables';

.bottom-part {
  height: 100px;

  & > div {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 15px;

    .icon {
      width: 24px;
      height: 24px;
      fill: none;
      stroke: $tr-black;

      &.tr-active-point {
        fill: $tr-green;
        stroke: $tr-green
      }
    }
  }
}

.category span {
  position: relative;
  display: inline-block;
  padding-top: 15px;
  width: 100px;
}

.how-to-page-no-overflow {
  overflow: hidden;
}


</style>