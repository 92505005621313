<template>
    <div class='to-top'>
        <b-modal class="modal" size="fit-content" id="login-modal" hide-footer hide-header no-close-on-backdrop>
            <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto"  style="max-width: none">
                <div class="modal-login-text-container">
                    <p class="modal-login-title">{{ $t("admin.loginExpired") }}</p>
                    <p>{{ $t("admin.loginToContinue") }}</p>
                </div>
                <form class="d-flex flex-column align-items-center" @submit.prevent="login" style="gap: 30px" novalidate>
                    <TrekioInput id="user-login" :label="$t('inputLabels.login')" trim v-model="form.userLogin" :error="error.userLogin" />
                    <TrekioInput id="password" type="password" :label="$t('inputLabels.password')" v-model="form.password" :error="error.password" />
                    <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" primary>{{ $t('buttons.login') }}</TrekioButton>
                    <div class="link-to-hp">
                        <router-link to="/">{{ $t("admin.redirectToHomepage") }}</router-link>
                        <p>{{ $t("admin.redirectToHomepageAlert") }}</p>
                    </div>
                </form>
            </div>
        </b-modal>
        <div class="mobile-warning" v-if="!desktopViewOnMobile">
            <p>{{ $t("admin.mobileViewAlert") }}</p>
            <TrekioButton @click="switchToDesktopView" secondary>{{ $t("admin.switchToDesktopVersion") }}</TrekioButton>
        </div>
        <div>
            <b-alert id="alert" fade
                :show="alert.dismissCountDown"
                @dismissed="alert.dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
                >
                <p>{{alert.notification}}</p>
            </b-alert>
            <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                    @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
                    {{ alertDanger.notification }}
                <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
            </b-alert>

            <div :class="{hide: !isSidebarVisible}" class="sidebar d-flex flex-column">
                <button class="hide-sidebar-button" @click="isSidebarVisible = !isSidebarVisible"><div class="arrow" :class="isSidebarVisible ? 'arrow--left' : 'arrow--right'"></div></button>
                <a href="/" class="px-3 mx-auto">
                    <img class="logo" alt="logo trekio" src="../../assets/svg/trekio-logo.svg">
                </a>
                <nav class="text-left">
                    <ul class="nav flex-column flex-nowrap overflow-hidden">
                        <li class="nav-item"><router-link v-if="!isUserAuthor" to='/admin' exact>{{ $t("general.home") }}</router-link></li>
                        <li class="nav-item"><router-link v-if="!isUserAuthor" to='/admin/campaigns'>{{ $t("general.campaigns") }}</router-link></li>
                        <li class="nav-item"><router-link to='/admin/travelTips'>{{ $t("general.travelTips") }}</router-link></li>
                        <li class="nav-item"><router-link to='/admin/pictures'>{{ $t("general.pictures") }}</router-link></li>
                        <li class="nav-item" v-if="!isUserAuthor"><router-link to='/admin/manageTags'>{{ $t("general.tags") }}</router-link></li>
                        <li class="nav-item" v-if="!isUserAuthor"><router-link to='/admin/manageEquipments'>{{ $t("general.equipments") }}</router-link></li>
                        <li class="nav-item"><router-link v-if="!isUserAuthor" to='/admin/users' :class="{ 'router-link-active': this.$route.path.includes('manageUser')}">
                            {{ $t("general.users") }}
                        </router-link></li>
                    </ul>
                </nav>
                <div class="d-flex flex-column bottom-part align-items-start">
                    <div class="profile d-flex align-items-center mt-auto" >
                        <div v-if="user && user.avatarFileName" class="image" v-bind:style="{ backgroundImage: 'url(' + imgURL + user.avatarFileName +')'}"></div>
                        <div v-else class="initials ml-auto">{{ initials }}</div>
                        <router-link :to="{ name: 'adminManageUser', params: { userId: this.id }}" class="mb-0">{{user ? user.userName : ""}}</router-link>
                    </div>
                    <a class="logout mb-0" @click="logout">{{ $t("general.logout") }}</a>
                    <div class="mt-3">{{ $t("admin.untilLogout") }} <b>{{hours + "h " + minutes + "m "}}</b></div>
                    <label class="version mb-0">{{ $t("admin.trekioVersion") }}</label>
                </div>
            </div>
            <div class="view" :class="{fullscreen: !isSidebarVisible}">
                <router-view :isSidebarVisible="isSidebarVisible" @showAlert="showAlert"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {AUTH_REQUEST, AUTH_LOGOUT} from "@/store/actions/auth"
    import {CLEVERTRIP_AVATAR_URL}  from '@/definitions';
    import {USER_BY_ID} from "@/api/graphql/query/UserQuery";
    import {getCookie} from "@/services/CookieService.js";
    import sharedUtils from "../../utils/sharedUtils";

    export default {
        name: "RootPage",
        
        async created() {
            if (localStorage.getItem('username')) {
                await this.$store.commit('setCurrentUserId', localStorage.getItem('id'));
                await this.$store.commit('setCurrentUser', localStorage.getItem('username'));
                await this.$store.commit('setCurrentUserRole', localStorage.getItem('role'));
            }
            this.setup()
        },

        data: function() {
            return {
                imgURL: CLEVERTRIP_AVATAR_URL,
                username: null,
                role: null,
                id: null,
                user: null,
                desktopViewOnMobile: false,
                alert: {
                    dismissSecs: 4,
                    dismissCountDown: 0,
                    notification: '',
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                isSidebarVisible: true,
                endDate: null,
                hours: null,
                minutes: null,
                seconds: null,
                form: {
                    userLogin: null,
                    password: null,
                },
                showPassword: false,
                loading: '',
                error: {
                    userLogin: '',
                    password: ''
                },
                countdownTimeout: null,
            }
        },

        watch: {
            isSidebarVisible(nV) {
                this.$store.commit('setIsSidebarVisible', nV)
            },
        },

        beforeRouteLeave (to, from , next) {
            if (this.desktopViewOnMobile) {
                let viewport = document.querySelector("meta[name=viewport]");
                viewport.setAttribute('content', 'width=device-width,initial-scale=1.0');
            }
            this.clearCountdownTimeout()
            next()
        },

        apollo: {
            user: {
                query: USER_BY_ID,
                    variables: function () {
                        return {
                            userId: this.$store.state.currentUserId,
                        }
                    },
                    skip: function () {
                        return !this.$store.state.currentUserId
                    },
            }
        },

        computed: {
            initials() {
                if (!this.user) return
                return sharedUtils.extractInitials(this.user.userName)
            },
            isUserAuthor() {
                return this.$store.state.currentUserRole == 'AUTHOR'
            },
        },

        methods: {
            clearCountdownTimeout() {
                clearTimeout(this.countdownTimeout);
            },
            async recaptcha() {
                const token = await this.$recaptchaToken('LOGIN')
                return token
            },
            validateInputs() {
                let errorCount = 0
                this.error.userLogin = this.error.password = ''
                if (!this.form.userLogin) {
                    this.error.userLogin = this.$t("inputErrors.required")
                    errorCount += 1
                }
                if (!this.form.password) {
                    this.error.password = this.$t("inputErrors.required")
                    errorCount += 1
                }
                return errorCount === 0
            },
            login: async function () {
                if (!this.validateInputs()) return
                this.loading = this.$t("loading.login")
                const recaptchaToken = await this.recaptcha()

                const { userLogin, password } = this.form;
                console.log("Starting authentication with username "+ userLogin);
                await this.$store
                    .dispatch(AUTH_REQUEST, {username: userLogin, password, recaptchaToken})
                    .then(() => {
                        this.showAlert(this.$t('alerts.loginSuccessful'))
                        this.$bvModal.hide('login-modal')
                        this.setLoginExpirationDateCookie()
                    })
                    .catch(err => {
                        console.log(err)
                        this.error.userName = this.error.password = ' '
                        this.showAlertDanger(this.$t('alerts.loginError'));
                    })
                this.loading = ''
            },
            changePasswordVisibility() {
                this.showPassword = !this.showPassword 
            },
            setCountdown() {
                var currentDate = new Date().getTime();
                var timeDifference = new Date(this.endDate) - currentDate;

                // Check if the countdown has reached under one second
                if (timeDifference <= 1000) {
                    this.clearCountdownTimeout()
                    this.hours = 0
                    this.minutes = 0
                    this.$bvModal.show('login-modal')
                    return;
                }

                // Calculate the remaining time in hours, minutes, and seconds
                this.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                this.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                // Sync the countdown to a whole minute and then check the time every minute
                if (this.seconds == 0) {
                    this.countdownTimeout = setTimeout(this.setCountdown, 60000);
                } else {
                    this.countdownTimeout = setTimeout(this.setCountdown, this.seconds * 1000)
                }
            },
            setLoginExpirationDateCookie(name) {
                const loginExpirationDateCookie = getCookie('loginExpirationDate')
                this.endDate = loginExpirationDateCookie
                this.setCountdown()
            },
            switchToDesktopView() {
                let viewport = document.querySelector("meta[name=viewport]");
                viewport.setAttribute('content', 'width=1460');
                this.desktopViewOnMobile = true
            },
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            showAlert(message) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.alert.notification = message
                this.alert.dismissCountDown = this.alert.dismissSecs
            },
            countDownChangedDanger: function(dismissCountDown) {
                this.alertDanger.dismissCountDown = dismissCountDown;
            },
            showAlertDanger: function(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
                this.alertDanger.notification = notification;
            },
            setup(){
                this.setLoginExpirationDateCookie()
                this.username = localStorage.getItem("username")
                this.role = localStorage.getItem("role")
                this.id = localStorage.getItem("id")
            },

            logout() {
                this.$store.dispatch(AUTH_LOGOUT)
                this.$router.push('/adminLogin');
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .hide-sidebar-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        background-color: $tr-white;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $tr-black;
        z-index: 1;

        &:hover {
            border: 2px solid $tr-black;
        }

        .arrow {
            margin: 0;
            margin-left: 5px;
            transition-property: transform, margin-left;

            &.arrow--right {
                margin-left: -5px;
            }
        }
    }

    .link-to-hp, .link-to-hp:hover {
        text-align: center;

        a, a:hover {
            color: $tr-black
        }
        p {
            margin-bottom: 0;
            color: $tr-gray;
        };
    }

    .modal-login-text-container {
        margin-bottom: 30px;
        text-align: center;

        p {
            margin-bottom: 0;
        }

        .modal-login-title {
            font-size: 25px;
        }
    }

    .mobile-warning {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .to-top {
            & .mobile-warning {
               display: block; 
            
               & + div {
                display: none;
               }
            }
        }
    }
    .to-top {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        height: 100vh;
        padding: 30px 0 40px 0;
        transition: left 0.3s ease-in-out;
        background-color: $tr-white-alt;

        &.hide {
            left: -280px;

            nav {
                pointer-events: none;
            }
        }

        & > a {
            margin-bottom: 25px;
        }
        & > *:not(a) {
            flex: 1;
        }
        
        @media screen and (max-height: 700px) {
            & {
                padding: 15px 0 15px 0;
            }
            .bottom-part label {
                margin-top: 25px;
            }
            ul {
                .nav-item a{
                    padding-block: 18px;
                }
            }
        }
    }

    nav {
        ul {
            .nav-item {
                height: 100%;
                width: 100%;

                a {
                    display: inline-block;
                    text-decoration: none;
                    color: $tr-black;
                    width: 100%;
                    height: 100%;
                    padding: 20px 60px;

                    &:hover {
                        background-color: $tr-green;
                    }
                    &.router-link-active, &.active-link {
                        position: relative;
                        &::before{
                            content: "";
                            color: $tr-black;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 25px;
                            width: 27px;
                            height: 18px;
                            background-repeat: no-repeat;
                            background-size: 26.5px 15px;
                            background-image: url("data:image/svg+xml,%3Csvg width='27' height='15' viewBox='0 0 27 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.125 3.1063C14.4421 1.2243 16.5832 -8.45098e-07 19.0007 -6.33752e-07C23.0044 -2.8374e-07 26.25 3.35787 26.25 7.5C26.25 11.6421 23.0044 15 19.0007 15C16.5832 15 14.4421 13.7757 13.125 11.8937C11.8079 13.7757 9.66679 15 7.24927 15C3.24561 15 -5.42849e-06 11.6421 -5.06638e-06 7.5C-4.70426e-06 3.35786 3.24561 -2.01111e-06 7.24927 -1.6611e-06C9.66679 -1.44975e-06 11.8079 1.2243 13.125 3.1063Z' fill='%23222222'/%3E%3C/svg%3E");
                        }
                    }
                }
            }
        }
    }
    .bottom-part {
        padding: 0 30px 0 60px;

        p, a {
            cursor: pointer;
            padding: 7px 0;
            color: $tr-black;
            
            &:hover {
                text-decoration: underline;
            }
        }
        .logout {
            margin-left: 55px;
        }
        .profile {
            gap: 15px;

            .image, .initials {
            width: 40px;
            height: 40px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 50%;
            }
            .initials {
            background-color: $tr-green;
            display: flex;
            justify-content: center;
            align-items: center;
            }
        }
        .version {
            color: $tr-gray;
            margin-top: 20px;

            &:hover {
                text-decoration: none;               
            }
        }
    }

    .view {
        margin-left: 340px; 
        padding: 50px 40px 50px 0; 
        transition: margin-left 0.3s ease-in-out;

        &.fullscreen {
            margin-left: 60px;
        }
    }
</style>

<style>
    .modal .modal-fit-content {
        max-width: fit-content
    }
</style>

