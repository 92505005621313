<template>
    <div class="text-left">
        <b-alert :show="alert.dismissCountDown" fade id="alert"
                @dismiss-count-down="countDownChanged"
                class="alert-success">{{alert.notification}}
        </b-alert>
        <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                @dismiss-count-down="countDownChangedDanger"
                class="alert-danger">{{alertDanger.notification}}
                <div class="x dismiss-x" @click="() => {alertDanger.dismissCountDown = 0}"></div>
        </b-alert>
        <div class="d-flex align-items-center justify-content-between mb-4">
            <h1>{{ $t("general.equipments") }}</h1>
            <TrekioSwitch v-if="!isManagingTemplate" v-model="isManagingLocalizations" :constantText="$t('admin.translation')"/>
        </div>
        <div class="state-filters-container d-flex">
            <button class="only-text" @click="showOrHideTemplates(false)" :class="{'active-state': !isManagingTemplates && !isManagingTemplate}">{{ $t("buttons.categories") }}</button>
            <button class="only-text" @click="showOrHideTemplates(true)" :class="{'active-state': isManagingTemplates || isManagingTemplate}">{{ $t("buttons.templates") }}</button>
        </div>
        <div class="d-flex justify-content-between align-items-center row mx-0 w-100 mb-4">
            <div class="col-6 px-0">
                <TrekioSelect :label="$t('inputLabels.choosePrimaryLanguage')" :placeholder="$t('inputLabels.choosePrimaryLanguage')"
                    :text="primaryLanguage" v-model="primaryLanguage"
                >   
                    <TrekioSelectOption v-for="language in languages" :key="language.code" :value="language.code">
                        {{ language.code }}
                    </TrekioSelectOption>
                </TrekioSelect>
            </div>
            <div class="col-6">
                <TrekioSelect v-if="isManagingLocalizations" :label="$t('inputLabels.showTranslation')" :placeholder="$t('inputLabels.showTranslation')"
                    :text="editedLanguage ? editedLanguage : $t('admin.notSelected')" v-model="editedLanguage"
                >   
                    <TrekioSelectOption :value="null">{{ $t("admin.notSelected") }}</TrekioSelectOption>
                    <TrekioSelectOption v-for="language in translatedLanguages" :key="language" :value="language">
                        {{ language }}
                    </TrekioSelectOption>
                </TrekioSelect>
            </div>
        </div>
        <div v-show="!isManagingTemplate && !isManagingTemplates">
            <div v-if="$apollo.loading" class="mt-4 loader-1 center paging-loading"><span></span></div>
            <div v-else>
                <div v-if="!isManagingLocalizations">
                    <a class="add-new-category-collapse text-center" v-b-toggle.addCategory>+ {{ $t("admin.addCategory") }}</a>
                    <b-collapse id="addCategory">
                        <form action="submit" @submit.prevent="createEquipmentCategory" novalidate>
                            <div class="equipment-category-icon-container">
                                <div v-if="!form.icon">
                                    <label for="equipment-category-icon">+ {{ $t("info.addIcon") }}</label>
                                    <input id="equipment-category-icon" class="button button--secondary" type="file" accept="image/svg+xml" @change="onInputFile">
                                </div>
                                <div v-else class="icon-preview">
                                    <img :src="chosenImageUrl" alt="">
                                    <span @click="form.icon = null" role="button" class="remove-icon">&#215;</span>
                                </div>
                            </div>
                            <TrekioInput id="title" trim v-model="newCategoryTitle" :label="$t('inputLabels.categoryTitle')" maxLength="50"
                                :error="error.title" required
                            />
                            <TrekioButton primary :isLoading="loading != ''" :loadingText="loading">{{ $t("buttons.createCategory") }}</TrekioButton>
                        </form>
                        
                    </b-collapse>
                </div>
                <EquipmentCategory ref="equipmentCategory" v-for="category in categoriesWithEquipment" :key="category.id" :category="category" @showAlert="showAlert" @showAlertDanger="showAlertDanger"
                    :isManagingLocalizations="isManagingLocalizations" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage"
                />
            </div>
        </div>
        <div v-if="categoriesWithEquipment" class="templates" v-show="isManagingTemplates && !isManagingTemplate">
            <EquipmentTemplate :categoriesWithEquipment="categoriesWithEquipment" :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage"/>
            <EquipmentTemplate ref="equipmentTemplate" @manageTemplate="manageTemplate" v-for="template in equipmentTemplatesAdmin" :key="template.id" :primaryLanguage="primaryLanguage"
                :isManagingLocalizations="isManagingLocalizations" :template="template" :categoriesWithEquipment="categoriesWithEquipment" :editedLanguage="editedLanguage"
                />
        </div>
        <div class="templates" v-show="isManagingTemplate">
            <ManageEquipmentTemplate ref="equipmentTemplateDetail" :template="currentTemplate" :equipmentCategories="categoriesWithEquipment"
                :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage" :isManagingLocalizations="isManagingLocalizations"
                @hideManageTemplate="isManagingTemplate = false"
            />
        </div>
    </div>
</template>


<script>
    import { EQUIPMENTS_ADMIN, EQUIPMENT_CATEGORIES_ADMIN, EQUIPMENT_TEMPLATES_ADMIN } from '../../api/graphql/query/EquipmentQuery'
    import EquipmentCategory from '../../components/admin/EquipmentCategory.vue';
    import EquipmentService from '@/services/EquipmentService'
    import EquipmentTemplate from '@/components/admin/EquipmentTemplate'
    import TrekioSwitch from '../../components/TrekioSwitch.vue';
    import ManageEquipmentTemplate from '../../components/admin/ManageEquipmentTemplate.vue';
    import sharedUtils from '../../utils/sharedUtils';
    import fileUtils from '../../utils/fileUtils'

    export default {
        data() {
            return {
                isManagingLocalizations: false,
                isManagingTemplates: false,
                isManagingTemplate: false,
                currentTemplate: null,
                primaryLanguage: "cs",
                editedLanguage: "en",
                newCategoryTitle: "",
                form: {
                    localizations: this.$store.state.languages.map(lang => {
                        return {
                            language: lang.code,
                            title: "",
                            published: true
                        }
                    }),
                    icon: null,
                    chosenIcon: null,
                },
                chosenImageUrl: null,
                lastSavedForm: null,
                error: {
                    title: '',
                },
                alert: {
                    dismissSecs: 4,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                loading: '',
            }
        },

        components: {
            EquipmentCategory,
            EquipmentTemplate,
            TrekioSwitch,
            ManageEquipmentTemplate
        },

        created() {
            this.$root.$on('showAlertEquipment', this.showAlert)
            this.$root.$on('showAlertDangerEquipment', this.showAlertDanger)
        },

        beforeRouteLeave (to, from , next) {
            if (!this.isChangeInForms()) {
                next()
                return
            }
            const answer = window.confirm(this.$t("admin.unsavedChangesWarning"))
            if (answer) next()
            else next(false)
        },

        methods: {
            async onInputFile(event){
                const file = event.target.files[0]
                if (!file) return
                const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(file.name)
                this.form.icon = new File([file], uniqueNameWithoutAccentsAndSpaces, {type: file.type});

                const reader = new FileReader();
                reader.onload = () => {
                    this.chosenImageUrl = reader.result;
                };
                reader.readAsDataURL(this.form.icon);
            },
            isChangeInForms() {
                if (this.newCategoryTitle != "") return true
                const equipmentCategoryRefs = this.$refs.equipmentCategory
                if (equipmentCategoryRefs) {
                    for (let category of equipmentCategoryRefs) {
                        if (category.isChangeInForms()) {
                            return true
                        }
    
                        const equipmentRefs = category.$refs.equipment
                        if (equipmentRefs) {
                            for (let equipment of equipmentRefs) {
                                if (equipment.isChangeInForms()) {
                                    return true
                                }
                            }
                        }
                    }

                }
                const equipmentTemplateRefs = this.$refs.equipmentTemplate
                if (equipmentTemplateRefs) {
                    for (let equipmentTemplate of equipmentTemplateRefs) {
                        if (equipmentTemplate.isChangeInForms()) {
                            return true
                        }
                    }
                }
                return false 
            },
            manageTemplate(template) {
                this.isManagingTemplate = true
                this.currentTemplate = template
            },
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
            },
            showOrHideTemplates(isVisible) {
                if (this.isManagingTemplate) this.isManagingTemplate = false
                this.isManagingTemplates = isVisible
            },
            showAlert(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alert.dismissCountDown = this.alert.dismissSecs
                this.alert.notification = notification;
            },

            showAlertDanger(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs
                this.alertDanger.notification = notification
            },
            countDownChanged(dismissCountDown) {
                this.alert.dismissCountDown = dismissCountDown
            },

            countDownChangedDanger(dismissCountDown) {
              this.alertDanger.dismissCountDown = dismissCountDown
            },

            validateInputs() {
                let numberOfErrors = 0
                this.error.title = ''
                
                if (!this.newCategoryTitle) {
                    numberOfErrors += 1
                    this.error.title = this.$t("inputErrors.required")
                }

                return numberOfErrors === 0

            },

            async createEquipmentCategory() {
                if (!this.validateInputs()) return

                this.loading = this.$t("loading.equipmentCategoryCreate")
                
                this.form.localizations = this.form.localizations.map(localization => {
                    return {
                        language: localization.language,
                        title: localization.language == this.primaryLanguage ? this.newCategoryTitle : "",
                        published: true
                    }
                })
                this.form.originalLanguage = this.primaryLanguage
                await EquipmentService.createEquipmentCategory(this.form)
                    .then(resp => {
                        console.log(resp)
                        this.$root.$emit('bv::toggle::collapse', 'addCategory')
                        this.$nextTick(() => {
                            this.$root.$emit('bv::toggle::collapse', 'equipment-category' + resp.data.createEquipmentCategory.id)
                        })
                        this.newCategoryTitle = ""
                        this.form.icon = null
                        this.showAlert(this.$t("alerts.equipmentCategoryCreated"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.showAlertDanger(this.$t("alerts.equipmentCategoryCreateError"))
                    })
            },
        },

        computed: {
            languages() {
                return this.$store.state.languages
            },
            categoriesWithEquipment() {
                if (!this.equipmentsAdmin || !this.equipmentCategoriesAdmin) return
                let equipments = this.equipmentsAdmin.map(eq => {
                    return {
                        ...eq,
                        localizations: eq.localizations.map(loc => ({...loc}))
                    }
                })

                let equipmentsWithoutCategory = []
                let equipmentsInCategories = this.equipmentCategoriesAdmin.map(category => {
                    let equipmentsInCategory = []
                    equipments.forEach((equipment, index) => {
                        sharedUtils.sortLocalizations(equipment.localizations, this.primaryLanguage, this.editedLanguage)

                        if (!equipment.category && !equipmentsWithoutCategory.find(eq => eq.id === equipment.id)) {
                            equipmentsWithoutCategory.push(equipment)
                        }

                        if (equipment.category && equipment.category.id == category.id) {
                            equipmentsInCategory.push(equipment)
                        }
                    });

                    const equipmentCategoryLocalizations = category.localizations.map(loc => ({...loc}))
                    sharedUtils.sortLocalizations(equipmentCategoryLocalizations, this.primaryLanguage, this.editedLanguage)
                    return {
                        ...category,
                        equipments: equipmentsInCategory,
                        localizations: equipmentCategoryLocalizations
                    }
                })
                if (equipmentsWithoutCategory.length) {
                    equipmentsInCategories.unshift({
                        id: -1,
                        localizations: this.$store.state.languages.map(lang => ({language: lang.code, title: "DEFAULT"})),
                        equipments: equipmentsWithoutCategory
                    })
                }
                this.$store.commit('setEquipmentCategories', equipmentsInCategories?.filter(category => category.id > 0))
                return equipmentsInCategories
            },
            translatedLanguages() {
                return this.form.localizations.map(localization => localization.language).filter(lang => {
                    return lang != this.primaryLanguage
                }).sort((a, b) => a.localeCompare(b))
            },
        },

        watch: {
            primaryLanguage(nV) {
                if (nV == this.editedLanguage) this.editedLanguage = null
                this.sortLocalizations()
            },
            editedLanguage(nV) {
                this.sortLocalizations()
            }
        },

        apollo: {
            equipmentCategoriesAdmin: {
                query: EQUIPMENT_CATEGORIES_ADMIN,
                skip: function() {
                    return !this.equipmentsAdmin
                }
            },
            equipmentsAdmin: {
                query: EQUIPMENTS_ADMIN,
            },

            equipmentTemplatesAdmin: {
                query: EQUIPMENT_TEMPLATES_ADMIN,
            }
        }
    }


</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

.only-text {
  background: none !important; 
  border: none !important;
}

.state-filters-container {
  border-bottom: 1px solid $tr-light-gray;
  margin-bottom: 20px;
  flex-wrap: wrap;

  button {
    position: relative;
    padding: 10px 20px 15px;
    color: $tr-gray;

    &:hover {
        color: $tr-black;
    }

    &.active-state {
        color: $tr-black;
  
        &::after {
            position: absolute;
            left: 0;
            bottom: -2px;
            content: "";
            width: 100%;
            height: 3px;
            background-color: $tr-green;
        }
      }
  }
  .active-state {
    color: $tr-green;
  }
}

    .loading {
        font-size: 25px;
    }

    h1 {
        font-size: 50px !important;
        font-weight: 700 !important;
    }

    form {
        margin-block: 30px 50px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .add-new-category-collapse {
        font-size: 25px;
        line-height: 25px;
        color: $tr-black;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: dotted 3px $tr-light-gray;
        border-top: dotted 3px $tr-light-gray;
        height: 80px;
        cursor: pointer;

        &:hover{
            color: $tr-black;
            text-decoration: none;
        }

        &::after{
            content: "";
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 14px;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            background-size: 24px 15px;
        }

        &[aria-expanded="true"]{
            &::after{
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .equipment-category-icon-container {
        input[type="file"] {
            display: none;
        }

        .icon-preview {
            position: relative;
            img, .remove-icon {
                width: 60px;
                height: 60px;
            };

            img {
                padding: 14px;
            }

            .remove-icon {
                display: none;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, 0.8);
                border: 1px solid $tr-black;
                font-size: 25px;
            }
            &:hover .remove-icon {
                display: flex;
            }
        }

        label {
            cursor: pointer;
            height: 60px;
            padding-inline: 18px;
            border: 1px solid $tr-black;
            line-height: 60px;

            &:hover {
                background-color: $tr-white-alt;
            }
        }
    }

</style>