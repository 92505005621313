<template>
    <div class="users-list-container text-left">
      <div class="user-wrapper" v-for="user in users" :key="user.id" @click="onRowClick(user.id)">
        <div class="profile col-4 px-0">
          <div v-if="user.avatarFileName" class="image" v-bind:style="{ backgroundImage: 'url(' + imgURL + user.avatarFileName +')'}"></div>
          <div v-else class="initials" >{{initials(user.userName)}}</div>
          <p class="name">{{user.userName}}</p>
        </div>
        <div class="role col-3 px-0">{{user.role.code}}</div>
        <div class="login col-3 px-0">{{user.userLogin}}</div>
        <div class="tipscount col-2 pr-0">{{ $tc("general.travelTipAmmount", user.articleCount) }}</div>
      </div>
    </div>
</template>

<script>
    import {CLEVERTRIP_AVATAR_URL}  from '@/definitions';
    import sharedUtils from '../../utils/sharedUtils';

    export default {
        name: "UserList",

        props: {
            users: Array,
        },

        data() {
            return {
                imgURL: CLEVERTRIP_AVATAR_URL,
            }
        },

        methods: {
            initials(userName) {
              return sharedUtils.extractInitials(userName)
            },
            onRowClick: function (userId) {
                this.$router.push({name: 'adminManageUser', params: {userId: userId}})
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .users-list-container {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    max-width: 1200px;
    position: relative;

    .user-wrapper {
      cursor: pointer;
      height: 110px;
      width: 100%;
      display: flex;
      align-items: center;
      background: $tr-white-alt;
      padding-inline: 30px;

      &:hover {
        outline: lightgray 2px solid;
      }
      
      .profile {
        display: flex;
        gap: 20px;
        align-items: center;
        .image, .initials {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        }
        .initials {
          background-color: $tr-green;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .name {
          margin-bottom: 0;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .name, .role {
        font-weight: 500;
        color: $tr-black;
      }

      .login {
        word-break: break-all;
      }
      .login, .tipscount {
        color: $tr-gray;
      }

      .tipscount {
        padding-left: 20px;
        white-space: nowrap;
      }
    }
  }

</style>