<template>
  <div class="text-left bg-white-alt">
    <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
             @dismiss-count-down="countDownChangedDanger" class="alert-danger multi-line pre-formatted">
            {{ alertDanger.notification }}
        <div class="x dismiss-x" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
    </b-alert>
    <NavBarNew :greenLogo='true'/>
    <main>
        <section class="tr-container tr-glasses-login">
            <form action="" @submit.prevent="onSubmit" novalidate>
                <h1 class="tr__h1">{{ $t("public.loginPage.loginHeader") }}</h1>
                <div class="form-card d-flex">
                    <div class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                        <h3 style="line-height: 25px; margin: 0 auto 30px">{{ $t("public.loginPage.welcomeBack") }}</h3>
                        <div class="d-flex flex-column" style="gap: 40px">
                            <div class="d-flex h-100 flex-column mx-auto" style="gap: 50px">
                                <div class="d-flex flex-wrap" style="gap: 40px">
                                    <TrekioInput darkBg width="260px" id="userName" v-model="form.userName" :label="$t('inputLabels.login')" maxLength="100"
                                        dynamicLabel :error="error.userName" placeholder=" " required
                                    />
                                    <TrekioInput darkBg width="260px" type="password" id="password" v-model="form.password" :label="$t('inputLabels.password')" maxLength="100"
                                        dynamicLabel :error="error.password" placeholder=" " :required="true"
                                    />
                                  </div>
                                  <router-link to="resetPassword" style="color: #30E781; margin-inline: auto">{{ $t("public.loginPage.forgottenPassword")}}</router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="tr-right-part tr-border-left d-flex flex-column jusify-content-center">
                        <TrekioButton type="submit" primary width="260px" :isLoading="loading != ''" :loadingText="loading" style="margin-bottom: 50px">{{ $t("general.login") }}</TrekioButton>
                        <div class="text-center">
                            <div>{{ $t("public.loginPage.noAccountYetUser") }}</div>
                            <router-link :to="{ path: '/register', query: $route.query.returnUrl ? { returnUrl: $route.query.returnUrl } : null }" style="color: #30E781">{{ $t("general.register") }}</router-link>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </main>
    <Footer style="margin-top: 60px"/>
  </div>
</template>

<script>
    import { AUTH_REQUEST } from "@/store/actions/auth";
    import Footer from "@/components/Footer";

    export default {
        name: "LoginPage",
        metaInfo() {
            return {
            title: this.$t('meta.loginTitle'),
                meta: [
                    {
                        vmid: 'description',
                        name: "description",
                        content: this.$t('meta.loginDescription')
                    }
                ]
            }
        },
        components: {
            Footer,
        },
        
        data: function() {
            return {
                form: {
                    userName: '',
                    password: '',
                },
                loading: '',
                error: {
                    userName: '',
                    password: ''
                },
                  alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                showPassword: false
              }
            },
        created() {
            this.resetForm();
        },

        methods: {
            async recaptcha() {
                const token = await this.$recaptchaToken('LOGIN')
                return token
            },
            changePasswordVisibility() {
                this.showPassword = !this.showPassword 
            },
            validateInputs() {
                let errorCount = 0
                const inputs = document.querySelectorAll("input")
                this.error.userName = this.error.password = ''

                inputs.forEach(input => {
                    if (input.required && !input.value) {
                        this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                })
                errorCount > 0 && document.querySelector("h1").scrollIntoView({behavior: 'smooth', block: 'nearest'})
                return errorCount > 0 ? false : true
            },
            async onSubmit() {
                console.log("OnSubmit")
                if (!this.validateInputs()) return
                this.loading = this.$t("loading.login")
                const username = this.form.userName;
                const password = this.form.password;
                const recaptchaToken = await this.recaptcha().catch(err => this.showAlertDanger(this.$t('alerts.loginRecaptchaError')))

                console.log("Starting authentication with username " + username);
                const currentPreferredLanguage = this.$store.state.preferredLanguage
                await this.$store.dispatch(AUTH_REQUEST, {username, password, recaptchaToken})
                .then(async (resp) => {
                        if (this.$route.query.returnUrl) await this.$router.push(this.$route.query.returnUrl)
                        else if (this.$store.state.currentUserRole == "USER") await this.$router.push('/')
                        else if (this.$store.state.currentUserRole == "AUTHOR") await this.$router.push('admin/travelTips')
                        else await this.$router.push('admin')
                        if (resp.data.preferredLanguage != currentPreferredLanguage) {
                            location.reload()
                        }
                    })
                    .catch(err => {
                        this.error.userName = this.error.password = ' '
                        this.showAlertDanger(this.$t('alerts.loginError'));
                        })
            },

            showAlertDanger: function(notification) {
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
                this.alertDanger.notification = notification;
            },
            countDownChangedDanger: function(dismissCountDown) {
                this.alertDanger.dismissCountDown = dismissCountDown;
            },

            resetForm: function() {
                this.form = {
                    userName:'',
                    password: '',
                }
            },
        }

    }
</script>

<style lang="scss" scoped>

#password {
    padding-right: 55px;
 }
.show-password {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 18px;
}

</style>
