<template>
    <div id="app">
        <div v-if="window.location.hostname === 'trekiodev.cleverlance.com'" class="dev-label">DEV</div>
        <router-view></router-view>
    </div>
</template>

<script>
import { UNREAD_CONVERSATIONS_COUNT } from '@/api/graphql/query/MessageQuery'

    export default {
        name: 'app',
        metaInfo() {
            return {
                title: this.$t('meta.homeTitle'),
                titleTemplate: '%s | Trekio.com',
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.homeDescription')
                    }
                ]
            }
        },

        data() {
            return {
                window: window
            }
        },

        methods: {
            enableGoogleAnalytics() {
                // eslint-disable-next-line
                if (Cookiebot && Cookiebot.consent.statistics) {
                    this.$gtag.config({
                        'enabled': true
                    })
                }
            }
        },

        created() {
            const chosenPreferredLanguage = localStorage.getItem('preferredLanguage');

            let preferredLanguage = 'cs';

            // Pokud je jazyk již nastaven uživatelem
            if (chosenPreferredLanguage) {
                preferredLanguage = chosenPreferredLanguage;
            } else {
                // Pokud není nastavený jazyk v localStorage, použít jazyk prohlížeče
                let browserLanguage = navigator.language;
                if (browserLanguage === 'sk') {
                    preferredLanguage = 'cs';
                // Pokud to není prerenderPlugin nebo webCrawler a browser language není podporovaná -> en
                } else if (!navigator.webdriver && browserLanguage && !this.$store.state.publicLanguageCodes.includes(browserLanguage)) {
                    preferredLanguage = 'en';
                }
            }

            this.$store.commit('setPreferredLanguage', preferredLanguage);
            this.$root.$i18n.locale = preferredLanguage;
            document.documentElement.lang = preferredLanguage;
        },

        mounted() {
            window.addEventListener('CookiebotOnAccept', this.enableGoogleAnalytics())
        },

        destroyed() {
            window.removeEventListener('CookiebotOnAccept', this.enableGoogleAnalytics())
        },

        apollo: {
            unreadConversationsCount: {
                query: UNREAD_CONVERSATIONS_COUNT,
                pollInterval: 60000,
                skip: function() {
                    return !this.$store.state.currentUserId || this.$route.path.includes('admin')
                },
                result({data}) {
                    this.$store.commit('setUnreadMessagesCount', data.unreadConversationsCount)
                },
                fetchPolicy: 'network-only'
            },
        }
    }
</script>

<style scoped lang="scss">
    @import 'common-def';
    @import '@/scss/variables';

    .dev-label {
        pointer-events: none;
        position: fixed;
        color: $tr-white;
        background-color: red;
        font-weight: 600;
        border: 2px solid $tr-black;
        padding: 1px 10px;
        top: 5px;
        left: 5px;
        z-index: 10000;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        background-color: $tr-white;
        // overflow-x: hidden;
    }
</style>

<style lang="scss">
    @use 'global';
</style>
