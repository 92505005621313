<template>
    <div class="campaign-list d-flex flex-column">
        <Campaign v-for="(campaign, index) in campaigns" :key='campaign.id' :index="index" :campaign="campaign" :visibleFormCampaignId="visibleFormCampaignId"
            @onRowClick="onRowClick" @setCampaignPriority="setCampaignPriority" @toggleOptions="toggleOptions" @editPriorityToChange="editPriorityToChange"
        />
        <router-link to='/admin/manageCampaign' style="position: absolute; bottom: 0; right: 80px">
            <div class="plus position-fixed" style="bottom: 60px"></div>
        </router-link>
    </div>
</template>

<script>
    import {CLEVERTRIP_AVATAR_URL}  from '@/definitions';
    import CampaignService from "@/services/CampaignService";
    import Campaign from './Campaign.vue';

    export default {
        name: "CampaignList",

        props: {
            campaigns: Array,
            topPriority: Number,
        },

        components: {
            Campaign
        },

        created() {
            this.topPriorityNumber = this.topPriority
        },

        data() {
            return {
                imgURL: CLEVERTRIP_AVATAR_URL,
                priorityToChange: null,
                visibleFormCampaignId: null,
            }
        },

        methods: {
            editPriorityToChange(event) {
                this.priorityToChange = event.target.value
            },
            toggleOptions(campaignId) {
                this.priorityToChange = null
                if (this.visibleFormCampaignId == campaignId) {
                    this.visibleFormCampaignId = null
                } else {
                    this.visibleFormCampaignId = campaignId
                }
            },
            async setCampaignPriority(campaign) {
                const { id, priorityOrder, isCustom} = campaign
                const priorityNumber = isCustom
                ? this.priorityToChange
                : priorityOrder > 1
                ? 1
                : this.topPriority + 10
                
                if (!priorityNumber) return
                await CampaignService.setPriority(id, priorityNumber)
                    .then(() => {
                        this.priorityToChange = null
                        this.visibleFormCampaignId = null
                        this.$emit('refetchCampaigns')
                    })
            },
            onRowClick: function(campaignId) {
                this.$router.push({
                    name: 'adminManageCampaign',
                    params: { campaignId: campaignId }
                })
            }
        },
    }
</script>

<style scoped lang="scss">

.campaign-list {
    gap: 30px;
    max-width: 1100px;
    position: relative;
}
</style>