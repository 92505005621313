export default {
    defaultItemDifficulty: "Vyber odpovídající náročnost zastávky a cesty na ní podle vysvětlivek {explanationLink}",
    startItemDifficulty: "Pokud je výchozí bod zároveň zajímavým místem, vyber, jak náročné je absolvování takové zastávky. Vysvětlivky {explanationLink}",
    finishItemDifficulty: "Vyber odpovídající náročnost cílového bodu a cesty na něj podle vysvětlivek {explanationLink}",
    itemDifficultyLinkText: "zde",

    maxAmountOfTravelTipsInProgressReached: "Nemůžeš mít více jak 10 rozpracovaných výletů!",
    allTravelTips: "Všechny tvoje výlety.",
    travelTipsInProgress: "Rozepsané výlety, které jsi ještě neposlal/a na kontrolu administrátorům a publikaci na webu. Můžeš jich mít maximálně 10.",
    travelTipsToRework: "Výlety, které jsi odeslal/a ke kontrole před publikováním, a některý z administrátorů tě žádá, abys v nich ještě provedl/a nějakou změnu.",
    travelTipsToControl: "Výlety, které jsi odeslal/a ke kontrole před publikováním. Kontrola má dvě fáze: nejdříve kontrolu a potom schválení.",
    travelTipsToApprove: "Výlety, které jsi odeslal/a a už prošly první fází kontroly před publikováním. Ještě je čeká fáze schválení.",
    travelTipsPublished: "Všechny tvoje výlety, které už byly schválené a teď jsou veřejně přístupné na webu.",
    travelTipsNotPublished: "Tvoje výlety, které prošly kontrolou a schválením k publikování, ale momentálně nejsou zveřejněné na webu. (Může jít i o výlety, které už zveřejněné byly, ale v tuhle chvíli se nehodí do žádné kategorie nebo třeba neodpovídají sezóně.)",
    travelTipsDeclined: "Výlety, které byly v rámci kontroly pro publikaci zamítnuté (v komentáři si můžeš přečíst, proč se tak stalo). Po 30 dnech od zamítnutí se automaticky smažou.",
    travelTipTitle: "Jak se tvůj itinerář bude jmenovat?\nNázev je první informace o výletu. Musí zaujmout i informovat. Půjde se na vandr nebo do lázní? A kam to vlastně bude?",
    travelTipShortInfo: "Podnadpis se zobrazuje po rozkliknutí kartičky výletu, jako doplněk nadpisu.\nZachyť pár slovy atmosféru nebo doplň info v nadpisu.",
    travelTipPerex: "Perex jsou ty první dva tučné řádky v popisu výletu.",
    travelTipDescription: "Stručný popis výletu jako celku.",
    travelTipPracticalInfo: "Sem uveď praktické informace, které cestovatelům pomůžou v rozhodnutí, zda na výlet vyrazit, a potom v jeho plánování. Řekni jim, kde si mají hledat ubytování, jestli na výlet potřebují nějaké speciální znalosti nebo vybavení, sdílej odkazy užitečné před cestou atd.",
    travelTipHashtags: "Jak bys svůj výlet otagoval/a na sociálních sítích?\nČesky, v cizích jazycích nebo mix.",
    travelTipTheme: "Vyber možnost, která nejlépe odpovídá.\nVýlety lze podle tématu filtrovat, téma se také zobrazuje přímo na kartičce výletu.",
    travelTipPicture: "Přidávej pouze fotku, na kterou máš licenci! To znamená, že jde o tebou vyfocenou fotku bez identifikovatelných lidí nebo jsi licenci získal/a přímo od autora a všech modelů. Může tomu odpovídat například obrázek z fotobanky, tam si ale pořádně prostuduj a dodržuj všechna pravidla a podmínky.\nTato fotka bude úvodní fotkou výletu, zobrazí se na kartičce výletu a v jeho detailu to bude ta hlavní fotka, co se zobrazuje jako pozadí nadpisu.",
    travelTipPreview: "Otevřít náhled v novém okně, jak by vypadaly všechny uložené změny pro běžného uživatele",

    dayName: "Vymysli výstižný název pro tento den. Pokud si nevíš rady, zamysli se, co se bude dělat a kde.",
    dayDescription: "Stručně popiš, co se během dne bude dělat. Nezacházej do detailů, na to jsou jednotlivé zastávky.",
    dayPicture: "Vyber hlavní fotku dne z fotek, které máš přiřazené k jednotlivým zastávkám. Fotka dne se bude zobrazovat v přehledu dnů výletu v mobilní aplikaci Trekio. Pokud vybranou fotku od zastávky odstraníš, odstraní se i jako hlavní fotka dne.",
    itemLatitude: "Automaticky se vyplní vybráním z mapy nebo můžeš zadat hodnotu formou desetinného čísla. Kladná hodnota odpovídá severní šířce (N), záporná jižní šířce (S).",
    itemLongitude: "Automaticky se vyplní vybráním z mapy nebo můžeš zadat hodnotu formou desetinného čísla. Kladná hodnota odpovídá východní délce (E), záporná západní délce (W).",
    itemCountriesDefault: "Vyber zemi, kde se zastávka nachází a kudy vede cesta z předchozí zastávky nebo výchozího bodu. Můžeš vybrat více zemí.",
    itemCountriesStart: "Vyber zemi, kde se výchozí bod dnešní trasy nachází.",
    itemCountriesFinish: "Vyber zemi, kde se cílový bod dnešní trasy nachází a kudy vede cesta z předchozí zastávky. Můžeš vybrat více zemí.",
    itemTransferType: "Jakým způsobem se cestovatelé na zastávku dostanou z předchozí zastávky nebo výchozího bodu? Pokud musí cestou přestoupit, můžeš přidat další dopravu (max. 5).",
    itemTransferLength: "Vzdálenost pro tento typ dopravy. (Pokud se přestupuje, rozděl délku celé cesty na zastávku odpovídajícím způsobem mezi jednotlivé dopravní prostředky.)\nMůžeš zadávat celá nebo desetinná čísla.",
    itemTitleDefault: "Název místa (př. Špilberk) nebo trasy (př. Jelenka-Sněžka)",
    itemTitleStart: "Název výchozího bodu dnešní trasy.",
    itemTitleFinish: "Název cíle dnešní trasy nebo jejího posledního úseku.",
    itemDescriptionDefault: "Popiš cestu k zastávce i místo samotné. Čím je zajímavé, proč by se tu měl člověk zastavit, co tu uvidí nebo zažije?",
    itemDescriptionStart: "Pokud je výchozí bod zajímavým místem, tady ho můžeš popsat.",
    itemDescriptionFinish: "Popiš cestu do cílového bodu i místo samotné, pokud je samo o sobě zajímavé.",
    itemPracticalInfoDefault: "Sem patří praktické informace jako odkaz na stránku s cenami vstupného a otevíracími hodinami. Pokud pro danou zastávku člověk potřebuje speciální vybavení nebo znalosti, taky to tu uveď.\nKdyž do tohoto pole dáš odkaz, zobrazí se jako klikatelná URL.",
    itemPracticalInfoStart: "Sem patří praktické informace, např. kde nejlépe zaparkovat a kolik to stojí, nebo speciální vybavení pro absolvování úvodní zastávky, pokud je potřeba.\nKdyž do tohoto pole dáš odkaz, zobrazí se jako klikatelná URL.",
    itemDuration: "Kolik času zabere absolvovat tuto zastávku? (Cesta se do tohoto času nepočítá.)",
    itemPictures: "Přidávej pouze fotku, na kterou máš licenci! To znamená, že jde o tebou vyfocenou fotku bez identifikovatelných lidí nebo jsi licenci získal/a přímo od autora a všech modelů. Může tomu odpovídat například obrázek z fotobanky, tam si ale pořádně prostuduj a dodržuj všechna pravidla a podmínky.",
    itemCopyDataInfoStart: "Zkopírovat lokaci, název zastávky a země z cíle předchozího dne",
    itemCopyDataInfoFinish: "Zkopírovat lokaci, název zastávky a země ze začátku tohoto dne",

    userNameUser: "Zobrazí se u tvých interakcí s výlety a ostatními uživateli (např. hodnocení výletů).",
    userNameAuthor: "Jméno nebo přezdívka, která se zobrazí u tvých výletů, pokud si zvolíš, že chceš být uveden/a jako autor.",
    email: "E-mail, pomocí kterého tě můžeme kontaktovat. Slouží i jako login pro přihlášení k tvému účtu.",
    password: "Heslo musí mít nejméně 8 znaků, velké písmeno, malé písmeno, symbol a číslo.",
    firstNameUser: "Pokud chceš, můžeš nám dát své křestní jméno. Není to ale povinné.",
    firstNameAuthor: "Tvé jméno a příjmení potřebujeme kvůli licenční smlouvě týkající se tvých výletů.",
    lastNameUser: "Pokud chceš, můžeš nám dát své příjmení. Není to ale povinné.",
    lastNameAuthor: "Tvé jméno a příjmení potřebujeme kvůli licenční smlouvě týkající se tvých výletů.",
    dateOfBirth: "Tvé datum narození potřebujeme kvůli licenční smlouvě týkající se tvých výletů.",
    phoneNumber: "Pokud chceš, můžeš uvést telefonní číslo pro efektivnější komunikaci.",
    address: "Tvou adresu potřebujeme kvůli licenční smlouvě týkající se tvých výletů.",
    aboutAuthor: "Pokud zvolíš, že chceš být uveden/a jako autor výletů, zobrazí se u tvých výletů tvoje přezdívka, fotka a medailonek.",

    travelTipComments: "Tady můžeš ohledně svého výletu komunikovat s administrátory. Napíšou ti sem, až výlet projde dalším krokem schvalovacího procesu. Nebo doporučí, co by bylo dobré změnit. Ty jim tu můžeš odpovídat nebo třeba dát vědět, pokud zjistíš, že se změnila některá faktická informace a bylo by potřeba výlet aktualizovat.",

    pinboardEndDate: "Toto datum ukončí platnost příspěvku. Pak se smaže i se všemi souvisejícími zprávami.",

    equipmentTemplates: "Přidá vybavení podle typu výletu. \nJe možné použít více šablon",

    // ToolTips
    totalRouteLengthToItem: "Celková vzdálenost cesty na zastávku.",
    itemEstimatedTime: "Odhadovaný čas na zastávce",
    deleteAssignedPicture: 'Nemůžeš odstranit fotku, která je přiřazená k výletu, který není ve stavu "Rozpracovaný" nebo "K přepracování"',
    updateAssignedPicture: 'Nemůžeš editovat fotku, která je přiřazená k výletu, který není ve stavu "Rozpracovaný" nebo "K přepracování"',
    changeTravelTipPicture: "Změnit titulní fotku výletu",
    changeDayPicture: "Změnit fotku dne",
    itemChangeOrder: "Změnit pořadí",
    chooseAsDayPicture: "Vybrat jako fotku dne",

    loginToWriteAMessage: "Pro možnost odepsání na příspěvek se nejprve přihlaš",

    routeMayDiffer: "Trasa vykreslená na mapy.cz se může lišit!",

    removeFromFavourites: "Odebrat z uložených",
    addToFavourites: "Uložit",
    loginToAddToFavourites: "Pro uložení výletu do oblíbených se přihlaš",
    addItem: "Přidat položku",
    markAsImportant: 'Označit jako "důležité"',
    disabledInPreview: "Nepřístupné v náhledu",
    addIcon: "Přidat ikonku",
    changeIcon: "Změnit ikonku",
}