import gql from "graphql-tag";

export const CREATE_TAG_CATEGORY = gql`
    mutation createTagCategory ($originalLanguage: String!, $localizations: [TagCategoryLocalizationInput]) {
        createTagCategory (originalLanguage: $originalLanguage, localizations: $localizations) {
            id
            originalLanguage
            localizations {
                language
                title
                description
            }
            tags {
                id
                localizations {
                    title
                }
            }
        }
    }`

export const UPDATE_TAG_CATEGORY = gql`
mutation updateTagCategory($id: ID!, $originalLanguage: String!, $localizations: [TagCategoryLocalizationInput]) {
    updateTagCategory(id: $id, originalLanguage: $originalLanguage, localizations: $localizations) {
        id
    }
}
`;

export const DELETE_TAG_CATEGORY = gql`
    mutation deleteTagCategory($id: Int!) {
        deleteTagCategory(id: $id)
    }
`;

export const CREATE_TAG = gql`
    mutation createTag($tagCategoryId:ID!, $originalLanguage: String!, $icon: Upload, $localizations: [TagLocalizationInput]) {
        createTag(tagCategoryId: $tagCategoryId, originalLanguage: $originalLanguage, icon: $icon, localizations: $localizations) {
            id
            originalLanguage
            iconFileName
            localizations {
                language
                title
            }
        }
    }
`;

export const UPDATE_TAG = gql`
    mutation updateTag($id: ID!, $tagCategoryId:ID!, $originalLanguage: String!, $icon: Upload, $localizations: [TagLocalizationInput]) {
        updateTag(id: $id, tagCategoryId: $tagCategoryId, originalLanguage: $originalLanguage, icon: $icon, localizations: $localizations) {
            id
        }
    }
`;

export const DELETE_TAG = gql`
    mutation deleteTag($id: Int!) {
        deleteTag(id: $id)
    }
`;
