<template>
    <div class="campaign-container text-left" @click="$emit('onRowClick', campaign.id)">
        <div class="row m-0 w-100">
            <div class="order-column col-1 p-0 d-flex flex-column align-items-center">
                <span class="number">{{index+1}}</span>
                <div v-if="$store.state.currentUserRole === 'ADMIN_SUPERVISOR'" class="d-flex flex-column align-items-center" style="gap: 20px">
                    <div class="top-icon-container d-inline" @click.stop="$emit('setCampaignPriority', campaign)">
                        <span class="top-icon" :class="{'topped': campaign.priorityOrder > 1}"></span>
                    </div>
                    <div class="more-options-container position-relative">
                        <div class="more-options-icon d-flex flex-column align-items-center" 
                            :class="{show: campaign.id == visibleFormCampaignId}" @click.stop="$emit('toggleOptions', campaign.id)">
                            <span class="dot"></span><span class="dot"></span><span class="dot"></span>
                        </div>
                        <div class="options-container" @click.stop>
                            <div class="d-flex align-items-center justify-content-center" style="height: 100%;">
                                <form @submit.prevent="$emit('setCampaignPriority', {id: campaign.id, priorityOrder: campaign.priorityOrder, isCustom: true})">
                                    <input type="number" :placeholder="$t('admin.priority')" @change="$emit('editPriorityToChange', $event)">
                                    <TrekioButton type="submit" class="p-0" maxWidth="80px" style="margin-left: 20px; min-width: 80px; height: 40px;">{{ $t("buttons.save")}}</TrekioButton>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="priority-number text-center">{{campaign.priorityOrder}}</div>
                </div>
            </div>
            <div class="campaign-details col-7 p-0">
                <div class="d-flex mb-4">
                    <h3 class="campaign-title d-inline-block">{{chosenLocalization.title}}</h3>
                    <span class="tip-count">{{campaign.travelTips && $tc('general.travelTipAmmount', campaign.travelTips.length) }}</span>
                </div>
                <p class="description">{{chosenLocalization.description}}</p>
                <div class="d-flex countries">
                    <div v-for="country in allCountriesInCampaign" :key="country.nameEnglish">{{country[countryLocalization]}}</div>
                </div>
            </div>
            <div class="col-4 p-0 d-flex flex-column">
                <div class="mb-auto d-flex justify-content-between align-items-start">
                <span class="date">{{campaign.validFrom | moment("D. M. YYYY")}} - {{campaign.validTo | moment("D. M. YYYY")}}</span>
                <div class="state d-inline-block">{{campaign.state == 'PUBLISHED' ? $t("general.publishedFem") : $t("general.hiddenFem")}}</div>
                </div>
                <div class="creator d-flex ml-auto mt-auto align-items-center">
                    <div v-if="campaign.author && campaign.author.avatarFileName" class="creator-avatar" v-bind:style="{ backgroundImage: 'url(' + imgURL + campaign.author.avatarFileName +')'}"></div>
                    <div v-else class="creator-initials ml-auto d-flex justify-content-center align-items-center">{{ getInitials(campaign.author && campaign.author.userName) }}</div>
                    <div class="creator-name">{{campaign.author ? campaign.author.userName : $t("inputLabels.firstName") + ' ' + $t("inputLabels.lastName")}}</div>
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-1"></div>
            <div class="col-11 p-0 localizations">
                <div class="localization" v-for="localization in sortedLocalizations" :key="localization.language"
                    :class="{'active': chosenLocalizationCode == localization.language, 'original-language': localization.language == campaign.originalLanguage}"
                    @click.stop="setChosenLocalization(localization.language)">{{ localization.language }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import sharedUtils from '../../utils/sharedUtils'
import {CLEVERTRIP_AVATAR_URL} from '@/definitions';

    export default {
        props: {
            campaign: Object,
            index: Number,
            visibleFormCampaignId: [Number, String]
        },

        data() {
            return {
                imgURL: CLEVERTRIP_AVATAR_URL,
                chosenLocalizationCode: null,
                sortedLocalizations: null,
            }
        },

        created() {
            const campaignLocalizationLanguages = this.campaign.localizations.map(loc => loc.language);
            if (campaignLocalizationLanguages.includes(this.preferredLanguage)) {
                this.chosenLocalizationCode = this.preferredLanguage
            } else if (this.preferredLanguage == 'cs' && campaignLocalizationLanguages.includes('sk')) {
                this.chosenLocalizationCode = 'sk'
            } else {
                this.chosenLocalizationCode = this.campaign.originalLanguage
            }

            this.sortedLocalizations = [...this.campaign.localizations].sort((a, b) => {
                if (a.language == this.chosenLocalizationCode) return -1
                else if (b.language === this.chosenLocalizationCode) return 1
                else return b.language.localeCompare(a.language)
            })
        },

        methods: {
            transformedTripsText(travelTipCount) {
                if (travelTipCount === 1) {
                    return travelTipCount + ' výlet';
                } else if (travelTipCount > 1 && travelTipCount < 5) {
                    return travelTipCount + ' výlety';
                } else {
                    return travelTipCount + ' výletů';
                }
            },
            setChosenLocalization(languageCode) {
                this.chosenLocalizationCode = languageCode
            },
            getInitials(userName) {
                if (!userName) return
                return sharedUtils.extractInitials(userName)
            }
        },

        computed: {
            countryLocalization() {
                return this.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
            },
            preferredLanguage() {
                return this.$store.state.preferredLanguage
            },

            chosenLocalization() {
                return this.campaign.localizations.find(localization => localization.language === this.chosenLocalizationCode)
            },
            allCountriesInCampaign() {
                if (!this.campaign.travelTips) return
                let countriesInCampaign = []
                this.campaign.travelTips.forEach(tip => {
                    tip.itineraryDays.forEach(day => {
                        day.itineraryItems.forEach(item => {
                            item.countries.forEach(country => {
                                if(!countriesInCampaign.some(c => c.nameEnglish == country.nameEnglish)) {
                                    countriesInCampaign.push(country) 
                                }
                            })
                        })
                    })
                })
                return countriesInCampaign
            },
        }
    }

</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .top-icon-container {
        padding: 5px;

        &:hover {
            .top-icon {
                transform: scale(1.1);
            }
        }
        .top-icon {
            display: inline-block;
            margin-bottom: 2px;
            border-style: solid;
            border-width: 0 6px 12px 6px;
            border-color: transparent transparent $tr-black transparent;
            position: relative;
        
            &::after {
                content: "";
                height: 2px;
                width: 8px;
                position: absolute;
                left: -4px;
                background-color: $tr-black;
                top: 14px;
            }
            
            &.topped {
                transform: scale(1.25);
                border-color: transparent transparent $tr-green transparent;
                &::after {background-color: $tr-green;}
            }
        }
    }

    .more-options-icon {
        gap: 3px;
        padding: 7px 15px;
        position: relative;
        border-radius: 50%;

        &.show  + .options-container {
            display: block;
        }

        + .options-container {
            display: none;
            top: 0;
            left: 50px;
            position: absolute;
            z-index: 1;
            width: 250px;
            height: 80px;
            background-color: $tr-white-alt;
            box-shadow: rgb(0 0 0 / 50%) 0px 2px 2px !important;

            input {
                border: $tr-black solid 1px !important;
                width: 100px;
                height: 40px;
                padding-left: 10px;
            }
            button {
                width: 80px;
                height: 40px;
            }
        }
        
        &:hover {
            background-color: $tr-green;
        }
        .dot {
            pointer-events: none;
            display: inline-block;
            background-color: $tr-black;
            height: 5px;
            width: 5px;
            border-radius: 50%;
        }
    }

    .priority-number {
        background-color: $tr-green;
        padding: 2px 8px;
    }
        
    .number, .date, .tip-count, .description {
        color: $tr-gray;
    }
    .campaign-container {
        cursor: pointer;
        background-color: $tr-white-alt;
        // height: 250px;
        padding: 30px 0 40px 0;
        padding-right: calc(100% / 12);

        &:hover {
            outline: 2px solid rgb(158, 158, 158) !important;
        }

        .campaign-details {
            display: flex;
            flex-direction: column;

            .campaign-title {
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 25px;
                font-size: 25px;
                margin: 0
            }
            .tip-count {
                text-wrap: nowrap;
                margin-inline: 10px;
            }

            
            .description {
                padding-right: 40px;
                margin-bottom: 20px;
                overflow: hidden;
            }
    
            .countries {
                gap: 10px;
                flex-wrap: wrap;
                overflow-x: auto;
                
                & > *{
                    padding: 5px 10px;
                    white-space: nowrap;
                    background-color: $tr-black;
                    color: $tr-white;
    
                }
            }
        }
        .number {
            line-height: 25px;
            font-size: 25px;
            margin-bottom: 25px;
        }
        
        .state {
            padding: 5px 10px;
            color: $tr-white;
            background-color: $tr-green;
        }

        .creator-avatar, .creator-initials {
            font-size: 12px;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 50%;
            background-color: $tr-green;
            margin-right: 10px;
        }

        .localizations {
            margin-top: 20px;
            display: flex;
            gap: 10px;
            align-items: center;

            .localization {
            padding: 2px 6px;
            border: 2px solid transparent;
            
            &.active {
                border: 2px solid $tr-black;
                background-color: $tr-black;
                color: $tr-white;
            }
            
            &.original-language {
                border: 2px solid $tr-green;
            }

            &:hover {
                border: 2px solid $tr-black;
            }
            }
        }
    }
</style>