<template>
    <div style="background-color: rgb(248, 250, 251);">
        <NavBarNew :background='true'/>
        <ProfileNavBar />
        <main>
            <div class="tr-container" style="padding-bottom: 140px">
                <form class="form" action="" @submit.prevent="onSubmit" novalidate>
                    <div class="form-card static-labels d-flex h-100">
                        <div class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                            <div v-if="!isEditingUser" class="user-overview">
                                <div class="user-details">
                                    <img v-if="avatarFileName" class="user-picture" :src="imgURL + avatarFileName.slice(0,avatarFileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + avatarFileName.slice(avatarFileName.lastIndexOf('.'))" alt="" />
                                    <div v-else class="user-picture mx-auto d-flex align-items-center justify-content-center">{{ initials }}</div>
                                    <h3>{{ user ? user.userName : "..."}}</h3>
                                    <p>{{ user ? user.userLogin : "..."}}</p>
                                </div>
                                <section>
                                    <h3>{{ $t("public.profilePage.support")}}</h3>
                                    <p>{{ $t("public.profilePage.havingTroubleWithTrekio")}}</p>
                                    <TrekioButton type="button" primary @click="$bvModal.show('contact-support-modal')">{{ $t("public.contactPage.contactUs") }}</TrekioButton>
                                </section>
                                <section class="anonymize-section">
                                    <h3 class="font-emilio">{{ $t("public.profilePage.anonymizeAccount")}}</h3>
                                    <TrekioButton type="button" primary @click="$bvModal.show('user-delete-modal')">{{ $t("buttons.anonymize") }}</TrekioButton>
                                </section>
                            </div>
                            <div v-else class="user-edit">
                                <div class="user-picture-container">
                                    <img v-if="chosenImgUrl || avatarFileName" class="icon-remove-picture" src="@/assets/svg/icon-close.svg" @click="removeAvatar" alt="x">
                                    <img v-if="chosenImgUrl" class="user-picture" :src="chosenImgUrl" alt="">
                                    <img v-else-if="avatarFileName" class="user-picture" :src="imgURL + avatarFileName.slice(0,avatarFileName.lastIndexOf('.')) + '-dimensions-640pxX480px' + avatarFileName.slice(avatarFileName.lastIndexOf('.'))" alt="" />
                                    <div v-else class="user-picture mx-auto d-flex align-items-center justify-content-center">{{ initials }}</div>
                                    <label for="avatar" class="change-picture">
                                        {{ $t("public.profilePage.changePicture") }}
                                        <input type="file" id="avatar" class="d-none" name="avatar" accept="image/png, image/jpeg" @change="setAvatar">
                                    </label>
                                </div>
                                <div class="form d-flex flex-column text-left" style="gap: 40px">
                                    <TrekioSelect dark-bg v-model="preferredLanguage" :label="$t('inputLabels.language')" :text="preferredLanguage ? preferredLanguage : $t('admin.notSelected')">
                                        <TrekioSelectOption v-for="language in $store.state.publicLanguageCodes" :key="language" :value="language">{{ language }}</TrekioSelectOption>
                                    </TrekioSelect>
                                    <!-- Email, Username -->
                                    <div class="form-group">
                                        <TrekioInput dark-bg style="max-width: 100%;" type="email" id="userLogin" trim v-model="form.userLogin" :label="$t('inputLabels.login')" :error="error.userLogin" maxlength="50" required
                                            :info="$t('info.email')" :disabled="!isEditingAllowed"
                                        />
                                        <TrekioInput dark-bg style="max-width: 100%;" id="userName" trim v-model="form.userName" :label="$t('inputLabels.username')" :error="error.userName" maxlength="100" required
                                            :info="$t('info.userNameUser')" :disabled="!isEditingAllowed"
                                        />
    
                                    <!-- Password, RepeatPassword -->
                                        <TrekioInput dark-bg style="max-width: 100%;" type="password" id="password" v-model="form.password" :label="$t('inputLabels.password')" maxLength="256"
                                            :error="error.password" :info="$t('info.password')" :disabled="!isEditingAllowed"
                                        />
                                        <TrekioInput dark-bg style="max-width: 100%;" type="password" id="repeatPassword" v-model="form.repeatPassword" :label="$t('inputLabels.repeatPassword')" maxLength="256"
                                            :error="error.repeatPassword" :disabled="!isEditingAllowed"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tr-right-part tr-border-left d-flex flex-column">
                            <div v-if="!isEditingUser" class="buttons-container">
                                <TrekioButton type="button" primary style="width: 260px" @click.prevent="isEditingUser = true">{{ $t("buttons.editProfile")}}</TrekioButton>
                                <TrekioButton type="button" secondary style="width: 260px" @click="logout">{{ $t("buttons.logout") }}</TrekioBUtton>
                            </div>
                            <div v-else class="buttons-container">
                                <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" primary style="width: 260px">{{ $t("buttons.save")}}</TrekioButton>
                                <TrekioButton type="button" secondary style="width: 260px" @click="exitEditing">{{$t("general.back")}}</TrekioButton>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <b-alert :show="alert.dismissCountDown" fade id="alert"
                    @dismiss-count-down="countDownChanged"
                    class="alert-success">{{alert.notification}}
            </b-alert>
            <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                    @dismiss-count-down="countDownChangedDanger"
                    class="alert-danger">{{alertDanger.notification}}
                    <div class="x dismiss-x" @click="() => {alertDanger.dismissCountDown = 0}"></div>
            </b-alert>
            <b-modal class="modal" id="user-delete-modal" hide-footer hide-header>
                <div class="modal-container py-5 d-flex flex-column justify-content-center mx-auto">
                    <h3 class="moda-title pb-3">{{ $t("modals.userAnonymizeAlert")}}</h3>
                    <p class="moda-text pb-5">{{ $t("modals.userAnonymizeText") }}</p>
                    <div class="pb-5 d-flex" style="gap: 40px">
                        <TrekioButton secondary @click="$bvModal.hide('user-delete-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed">{{ $t("buttons.anonymize") }}</TrekioButton>
                    </div>
                </div>
            </b-modal>
            <b-modal class="modal" id="contact-support-modal" hide-footer hide-header white-bg>
                <form @submit.prevent="onContactSubmit" class="contact-form">
                    <h3 class="moda-title pb-3">{{ $t("public.profilePage.hereForYou") }}</h3>
                    <TrekioTextarea dark-bg class="mb-5" id="message" :label="$t('inputLabels.message')" trim v-model="contactForm.msgBody" maxlength="1024" :error="error.msgBody"></TrekioTextarea>
                    <div class="buttons-container">
                        <TrekioButton type="button" secondary @click="$bvModal.hide('contact-support-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton type="button" :isLoading="loadingContact != ''" :loadingText="loadingContact" primary @click="onContactSubmit">{{ $t("buttons.send")}}</TrekioButton>
                    </div>
                </form>
            </b-modal>
        </main>
        <Footer/>
    </div>
</template>

<script>
    import UserService from "@/services/UserService";
    import {USER_BY_ID, USER_PREFERRED_LANGUAGE} from "@/api/graphql/query/UserQuery";
    import {CLEVERTRIP_AVATAR_URL}  from '@/definitions';
    import Footer from "@/components/Footer";
    import fileUtils from "../utils/fileUtils";
    import sharedUtils from "../utils/sharedUtils";
    import { userInfo } from '@/components/InfoIconTexts.js'
    import ProfileNavBar from "../components/ProfileNavBar.vue";
    import ContactEmailService from "@/services/ContactEmailService";
    import {AUTH_LOGOUT} from "@/store/actions/auth";

    export default {
        name: "ManageUserDlg",

        components: {
            Footer,
            ProfileNavBar,
        },

        data: function() {
            return {
                userInfo: userInfo,
                initialLoading: true,
                isEditingAllowed: true,
                isEditingUser: false,
                loadingContact: '',
                loading: '',
                loadingDelete: '',
                imgURL: CLEVERTRIP_AVATAR_URL,
                role: null,
                contactForm: {
                    name: 'Uživatel Trekia',
                    email: '',
                    msgBody: ''
                },
                form: {
                    userName: '',
                    userLogin: '',
                    phoneNumber: null,
                    password: '',
                    role: '',
                    repeatPassword: '',
                    firstName: '',
                    lastName: '',
                },
                preferredLanguage: '',
                error: {
                    userName: '',
                    msgBody: ''
                },
                showPassword: {
                    password: false,
                    repeatPassword: false
                },
                chosenAvatar: null,
                chosenImgUrl: null,
                avatarFileName: '',
                roles: null,
                changePassword: false,
                
                dismissSecs: 10,
                dismissCountDown: 0,
                alert: {
                    dismissSecs: 6,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
              }
            }
        },

        computed: {
            initials() {
                if (!this.user) return
                return sharedUtils.extractInitials(this.user.userName)
            },
            userId() {
                return this.$store.getters.currentUserId
            }
        },

        methods: {
            logout() {
                this.$store.dispatch(AUTH_LOGOUT)
                if (this.$route.path != '/') {
                    this.$router.push('/');
                }
            },
            async recaptcha() {
                const token = await this.$recaptchaToken('CONTACT_MAIL')
                return token
            },
            exitEditing() {
                this.chosenAvatar = null
                this.chosenImgUrl = null
                this.isEditingUser = false
            },
            changePasswordVisibility(inputId) {
                this.showPassword[inputId] = !this.showPassword[inputId]
            },
            validateInputs() {
                let errorCount = 0
                const inputs = [...document.getElementsByTagName("input")]
                inputs.forEach(input => {
                    if (input.required && !input.value) {
                        this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                    else {
                        this.error[input.id] = ''
                    }
                })
                if (this.form.userLogin) {
                    const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@+[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/
                    if (!this.form.userLogin.match(emailRegex)) {
                        errorCount += 1
                        this.error.userLogin = this.$t("inputErrors.incorrectEmailFormat")
                    }
                }

                if (this.form.firstName && !sharedUtils.stringHasOnlyLetters(this.form.firstName)) {
                    errorCount += 1
                    this.error.firstName = this.$t("inputErrors.incorrectFirstNameFormat")
                }
                
                if (this.form.lastName && !sharedUtils.stringHasOnlyLetters(this.form.lastName)) {
                    errorCount += 1
                    this.error.lastName = this.$t("inputErrors.incorrectLastNameFormat")
                }
                
                if (this.form.userName && !sharedUtils.isUserNameValid(this.form.userName)) {
                    errorCount += 1
                    this.error.userName = this.$t("inputErrors.incorrectUserNameFormat")
                }

                if (this.form.password || this.form.repeatPassword) {
                    if (!sharedUtils.passwordHasRequiredSymbols(this.form.password)) {
                        errorCount += 1
                        this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                    }  else {
                        this.error.password = ''
                    }

                    if (this.form.repeatPassword !== this.form.password) {
                        errorCount += 1
                        this.error.repeatPassword = this.$t("inputErrors.passwordsDoNotMatch")
                    } else {
                        this.error.repeatPassword = ''
                    }
                }

                if (errorCount > 0) {
                    this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
                    return false
                }

                return true
            },
            removeAvatar() {
                this.chosenAvatar = null,
                this.avatarFileName = ''
                this.chosenImgUrl = null
            },

            setAvatar(e) {
                const uniqueNameWithoutAccentsAndSpaces = fileUtils.getNormalizedUniqueFileName(e.target.files[0].name)
                this.chosenAvatar = new File([e.target.files[0]], uniqueNameWithoutAccentsAndSpaces, {type: e.target.files[0].type});
            },

            async onContactSubmit() {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.error.msgBody = ''
                if (!this.contactForm.msgBody) return this.error.msgBody = this.$t("inputErrors.required")
                
                this.loadingContact = this.$t("loading.send")
                const retoken = await this.recaptcha()
                
                await ContactEmailService.sendContactEmail(this.contactForm, retoken)
                    .then(() => {
                        this.contactForm.msgBody = ''
                        this.showAlert(this.$t('alerts.messageSent'))
                        this.$bvModal.hide('contact-support-modal')
                    })
                    .catch((err) => {
                        this.showAlertDanger(this.$t('alerts.errorSubmittingForm'));
                        console.log(err)
                    })
                    
                this.loadingContact = ''
            },

            onSubmit: async function() {
                if(!this.validateInputs()) return

                this.loading = this.$t("loading.save")
                await UserService.updateUser(this.changePassword, this.$store.state.currentUserId, this.form)
                    .then( async () => {
                        if(this.chosenAvatar) {
                            await UserService.updateUserAvatar(this.$store.state.currentUserId, this.chosenAvatar)
                            .then(() => {
                                this.updateOldUser()
                            })
                        }
                        else if(!this.avatarFileName && this.user.avatarFileName) {
                            await UserService.deleteUserAvatar(this.userId)
                            this.updateOldUser()
                        }
                        else {this.updateOldUser()}
                    }).catch(err =>  {
                        this.handleError(err)
                    });
                
                if (this.preferredLanguage != this.preferredLanguageForCurrentUser) {
                    await UserService.setPreferredLanguageForCurrentUser(this.preferredLanguage)
                    .then(() => {
                        localStorage.setItem('preferredLanguage', this.preferredLanguage)
                    })
                    .catch(err => {
                        console.log(err)
                        this.showAlertDanger(this.$t('alerts.preferredLanguageChangeError'))
                    })
                    location.reload()
                }
            },

            handleError(error) {
                if (error.message.includes('Password should contain')) this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                else if (error.message.includes('Username already used')) this.error.userName = this.$t("inputErrors.userNameAlreadyUsed", this.form.userName)
                else if (error.message.includes('Login already used')) this.error.userLogin = this.$t("inputErrors.loginAlreadyUsed", this.form.userLogin)
                else this.showAlertDanger(this.$t("alerts.userUpdateError"))
            },

            onDeleteConfirmed: async function() {
                this.loadingDelete = this.$t("loading.userDelete")
                    await UserService.lockUser(this.userId)
                        .then(() => {        
                            localStorage.removeItem("user-token");
                            localStorage.removeItem("id");
                            localStorage.removeItem("username");
                            localStorage.removeItem("role");
                            this.$store.commit('setCurrentUserId', null)
                            this.$store.commit('setCurrentUser', null);
                            this.$store.commit('setCurrentUserRole', null);
                            this.$router.push('/');
                        })
                        .catch(err => {
                            console.log(err)
                            this.showAlertDanger(this.$t('alerts.userAnonymizeError'))
                        })

                    this.loadingDelete = ''
            },

            resetToDefault: function() {
                if (this.user) {
                    this.form = {
                        userName: this.user.userName,
                        userLogin: this.user.userLogin,
                        phoneNumber: this.user.phoneNumber,
                        role: this.user.role.code,
                        password: '',
                        repeatPassword: '',
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                    }
                    this.avatarFileName = this.user.avatarFileName
                }
            },

            async updateOldUser() {
                this.showAlert(this.$t('alerts.changesSaved'));
                await this.$apollo.queries.user.refetch({
                    userId: this.userId
                });
                this.form.password = ""
                this.form.repeatPassword = ""
                this.exitEditing()
            },
            showAlert(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alert.dismissCountDown = this.alert.dismissSecs
                this.alert.notification = notification;
            },

            showAlertDanger(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs
                this.alertDanger.notification = notification
            },
            countDownChanged(dismissCountDown) {
                this.alert.dismissCountDown = dismissCountDown
            },

            countDownChangedDanger(dismissCountDown) {
              this.alertDanger.dismissCountDown = dismissCountDown
            },
        },

        apollo: {
            user: {
                query: function() {
                    return USER_BY_ID
                },

                variables: function() {
                    return {
                        userId: this.userId,
                    }
                },

                skip: function () {
                    return this.userId == null;
                },

                result: function({data}) {
                    this.contactForm.email = data.user.userLogin
                }
            },
            preferredLanguageForCurrentUser: {
                query: USER_PREFERRED_LANGUAGE,
                result({data}) {
                    this.preferredLanguage = data.preferredLanguageForCurrentUser
                }
            } 
        },

        watch: {
            user: function (nV) {
                if (this.initialLoading) this.initialLoading = false
                this.resetToDefault();
            },
            chosenAvatar(newValue) {
                if (newValue) {
                    this.chosenImgUrl = URL.createObjectURL(newValue)
                }
            },
            'form.password'(nV) {
                this.changePassword = nV ? true : false
            },
        },
    }
</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    img {
        object-fit: cover;
    }
    .buttons-container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }
    .tr-left-part , .tr-right-part{
        padding-inline: 60px !important;

        @media (max-width: 768px) {
            padding-inline: 40px !important;
        }

        @media (max-width: 576px) {
            padding-inline: 20px !important;
        }
    }

    .user-overview {
        .user-details {
            margin-bottom: 50px;
    
            img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin-bottom: 30px;
            }
            
            p {
                margin: 0;
            }
            
        }
        h3 {
            font-size: 25px;
            margin-bottom: 20px;
        }
    
        section {
            text-align: left;
            padding-top: 30px;
            border-top: 1px solid $tr-light-gray;

            button {
                margin-top: 15px;
            }
        }
    }
    .input-container {
        max-width: 100%;
    }

    main {
        margin-top: 56px;
    }

    .contact-form {
        padding-top: 30px;
        padding-inline: 30px;
        padding-block: 20px;

        .buttons-container {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            flex-direction: row;
        }

        @media (max-width: 576px) {
            padding-inline: 0;
        }
    }

    .user-picture {
        width: 80px;
        height: 80px;
        background-color: $tr-green;
        border-radius: 50%;
        margin-bottom: 20px;
    }
    .user-picture-container {
        margin-bottom: 70px;
        position: relative;
        width: fit-content;
        margin-inline: auto;

        .icon-remove-picture {
            position: absolute;
            right: 0;
            top: 0;
            background-color: $tr-white-alt;
            cursor: pointer;
            border-radius: 50%;
            padding: 2px;
        }

        .change-picture {
            display: block;
            margin-bottom: 0;
            cursor: pointer;
            color: #30E781;
        }
    }

    .form-group {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        margin-bottom: 30px;
    }

    .form {
        .show-password {
            cursor: pointer;
            position: absolute;
            right: 45px;
            top: 18px;
        }

        #password, #repeatPassword {
            padding-right: 80px !important;
        }
    }

</style>