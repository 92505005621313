<template>
    <div class="text-left bg-white-alt">
        <b-alert :show="alert.dismissCountDown" fade id="alert"
                 class="alert-success">{{alert.notification}}
        </b-alert>
        <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                 class="alert-danger multi-line pre-formatted">
                {{ alertDanger.notification }}
            <div class="x" style="width: 25px; height: 25px; position: absolute; background-color: transparent; right: 10px; top: 11px" @click="() => {this.alertDanger.dismissCountDown = 0}"></div>
        </b-alert>
        <NavBarNew :greenLogo='true'/>
        <main>
            <section class="tr-container" style="padding-bottom: 140px">
                <form action="" @submit.prevent="onSubmit" novalidate>
                    <h1 class="tr__h1">{{ $t("public.registrationPage.registration") }}</h1>
                    <div class="form-card d-flex">
                        <div class="tr-left-part tr-border-right tr-right-dashed-line w-100">
                            <h3 style="line-height: 25px; margin: 0 auto 30px">{{ $t("public.registrationPage.joinUs") }}</h3>
                            <div class="d-flex flex-column" style="gap: 40px">
                                <div class="input-groups-container d-flex h-100 flex-column mx-auto">
                                    <div class="d-flex flex-wrap" style="gap: 40px">
                                        <TrekioInput darkBg width="260px" id="userName" v-model="form.userName" :label="$t('inputLabels.username')" maxLength="100"
                                            dynamicLabel :error="error.userName" placeholder=" " required :info="$t('info.userNameUser')"
                                        />
                                        <TrekioInput darkBg type="email" width="260px" id="userLogin" v-model="form.userLogin" :label="$t('inputLabels.email')" maxLength="50"
                                            dynamicLabel :error="error.userLogin" placeholder=" " required :info="$t('info.email')"
                                        />
                                    </div>
                                    <div class="d-flex flex-wrap" style="gap: 40px">
                                        <TrekioInput darkBg type="password" width="260px" id="password" autocomplete="new-password" v-model="form.password" :label="$t('inputLabels.password')" maxLength="256"
                                            dynamicLabel :error="error.password" placeholder=" " required :info="$t('info.password')"
                                        />
                                        <TrekioInput darkBg type="password" width="260px" id="repeatPassword" v-model="form.repeatPassword" :label="$t('inputLabels.repeatPassword')" maxLength="256"
                                            dynamicLabel :error="error.repeatPassword" placeholder=" " required
                                        />
                                    </div>
                                    <div>
                                        <Checkbox v-model="form.termsOfUse" :required="true" :showError="error.termsOfUse">
                                            {{ $t("public.registrationPage.agreeWith") }} <router-link to="/termsOfUse" target="_blank">{{ $t("public.registrationPage.agreeWithTermsOfUse") }}</router-link>.
                                        </Checkbox>
                                        <Checkbox class="mt-2" v-model="form.principlesDataProcessing" :required="true" :showError="error.principlesDataProcessing">
                                            {{ $t("public.registrationPage.iHaveRead") }} <router-link to="/principlesDataProcessing" target="_blank">{{ $t("public.registrationPage.iHaveReadPrinciplesDataProcessing") }}</router-link>.
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="tr-right-part tr-border-left d-flex flex-column">
                            <TrekioButton primary width="260px" type="submit" :isLoading="loading != ''" :loadingText="loading" style="margin-bottom: 50px">{{ $t("buttons.register") }}</TrekioButton>
                            <div class="text-center">
                                <div>{{ $t("public.registrationPage.alreadyHaveAccount") }}</div>
                                <router-link to="/login" style="color: #30E781">{{ $t("general.login") }}</router-link>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </main>
      <Footer style="margin-top: 60px"/>
    </div>
</template>
  
  <script>
    import { AUTH_REQUEST } from "@/store/actions/auth";
    import UserService from "@/services/UserService";
    import Footer from "@/components/Footer";
    import sharedUtils from "../utils/sharedUtils";
    import { userInfo } from "../components/InfoIconTexts";
    import Checkbox from "../components/Checkbox.vue";
  
  
    export default {
        name: "RegistrationPage",
        metaInfo() {
            return {
                title: this.$t('meta.registerTitle'),
                meta: [
                    {
                        vmid: 'description',
                        name: "description",
                        content: this.$t('meta.registerDescription')
                    }
                ]
            }
        },
        components: {
            Footer,
            Checkbox
        },

        props: {
            previous: {
                type: String,
                default: 'HomePage'
            },
            id: String,
        },
  
        data: function() {
            return {
                userInfo: userInfo,
                form: {
                    userName: '',
                    userLogin: '',
                    password: '',
                    repeatPassword: '',
                    firstName: '',
                    lastName: '',
                    termsOfUse: false,
                    principlesDataProcessing: false,
                    language: this.$store.state.preferredLanguage
                },
                loading: '',
                alert: {
                    dismissSecs: 6,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                success: false,
                error: {
                    userName: '',
                    userLogin: '',
                    password: '',
                    repeatPassword: '',
                    termsOfUse: false,
                    principlesDataProcessing: false,
                },
                showPassword: {
                    password: false,
                    repeatPassword: false
                },
            }
        },
        methods: {
            async recaptcha(action) {
                const token = await this.$recaptchaToken(action || 'LOGIN')
                return token
            },
            changePasswordVisibility(inputId) {
                this.showPassword[inputId] = !this.showPassword[inputId] 
            },
            validateInputs() {
                let errorCount = 0
                const {userLogin, password, repeatPassword, firstName, lastName, userName} = this.form
                const inputs = document.querySelectorAll("input")
                for (let key in this.error) {
                    if (this.error.hasOwnProperty(key)) {
                        if (key === 'termsOfUse') this.error[key] = false
                        else if (key === 'principlesDataProcessing') this.error[key] = false
                        else this.error[key] = ''
                    }
                }
                inputs.forEach(input => {
                    if (input.type != 'checkbox' && input.required && !this.form[input.id]) {
                        this.error[input.id] = this.$t("inputErrors.required")
                        errorCount += 1
                    }
                })
                if (userLogin) {
                    if (!sharedUtils.isEmailValid(userLogin)) {
                        errorCount += 1
                        this.error.userLogin = this.$t("inputErrors.incorrectEmailFormat")
                    } 
                }

                if (firstName && !sharedUtils.stringHasOnlyLetters(firstName)) {
                    errorCount += 1
                    this.error.firstName = this.$t("inputErrors.incorrectFirstNameFormat")
                }
                
                if (lastName && !sharedUtils.stringHasOnlyLetters(lastName)) {
                    errorCount += 1
                    this.error.lastName = this.$t("inputErrors.incorrectLastNameFormat")
                }
                
                if (userName && !sharedUtils.isUserNameValid(userName)) {
                    errorCount += 1
                    this.error.userName = this.$t("inputErrors.incorrectUserNameFormat")
                }

                if (password) {
                    if (!sharedUtils.passwordHasRequiredSymbols(password)) {
                        errorCount += 1
                        this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                    }
                }
                if (repeatPassword) {
                    if (password !== repeatPassword) {
                        errorCount += 1
                        this.error.repeatPassword = this.$t("inputErrors.passwordsDoNotMatch")
                    }
                }
                if (!this.form.termsOfUse) {
                    errorCount += 1
                    this.error.termsOfUse = true
                }
                
                if (!this.form.principlesDataProcessing) {
                    errorCount += 1
                    this.error.principlesDataProcessing = true

                }

                if (errorCount > 0) {
                    this.showAlertDanger(this.$tc("alerts.foundErrorsInForm", errorCount))
                    document.querySelector("h1").scrollIntoView({behavior: 'smooth', block: 'nearest'})
                    return false
                }

                this.alertDanger.dismissCountDown = 0
                return true
            },
            async onSubmit() {
                if (!this.validateInputs()) return
                this.loading = this.$t("loading.register")
                const username = this.form.userLogin;
                const password = this.form.password;
                const recaptchaToken = await this.recaptcha('REGISTER_USER')
                await UserService.registerUser(this.form, recaptchaToken).then(result => {
                    if (!result.data.registerUser) {
                        this.showAlertDanger("")
                    }
                    this.loading = this.$t("loading.login")
                    this.showAlert(this.$t('alerts.registrationSuccessful'));
                    this.success = true;
                }).catch(error => {
                    this.handleError(error)
                    this.loading = ""
                });
                if (this.success){
                    console.log("Starting authentication with username " + username);
                    const recaptchaToken = await this.recaptcha()
                    await this.$store
                        .dispatch(AUTH_REQUEST, {username, password, recaptchaToken})
                        .catch(err => this.alertDanger.notification = err);

                    if (this.$route.query.returnUrl) await this.$router.push(this.$route.query.returnUrl)
                    else await this.$router.push({name: 'HomePage',}).catch(err => console.log(err))
                }
            },

            handleError(error) {
                if (error.message.includes('Password should contain')) this.error.password = this.$t("inputErrors.incorrectPasswordFormat")
                else if (error.message.includes('Username already used')) this.error.userName = this.$t("inputErrors.userNameAlreadyUsed", [this.form.userName])
                else if (error.message.includes('Login already used')) this.error.userLogin = this.$t("inputErrors.loginAlreadyUsed", [this.form.userLogin])
                else this.showAlertDanger(this.$t("alerts.registrationError"))
            },

            showAlert(notification) {
                this.alert.dismissCountDown = this.alert.dismissSecs;
                this.alert.notification = notification;
            },

            showAlertDanger: function(notification) {
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs;
                this.alertDanger.notification = notification;
            },
        }
  
    }
</script>
  
<style lang="scss" scoped>

 #password {
    padding-right: 55px;

    & ~ .show-password {
        right: 45px;
    }
 }
.show-password {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 18px;
}

.input-groups-container {
    gap: 50px;
    @media (max-width: 768px) {
        gap: 40px
    }
}

.input-alert {
    height: 18px;
}
.x {
    &::before {width: 16px}
    &::after {height: 16px}
}
@media screen and (min-width: 1101px) {
    .form-card {
        width: 100%;
        max-width: 1160px;
        height: 100%;
    }

    
}



</style>

  