export default {
    homeTitle: "Cestujte s námi a prozkoumejte celou zeměkouli",
    homeDescription: "Kam na výlet? Vyrazte za dobrodružstvím v Česku i po světě. Plánujeme pro vás originální cestovní itineráře nabité praktickými informacemi a tipy.",

    searchTitle: "Výlety - Vyhledejte výlet na míru",
    searchDescription: "Vyhledávejte cestovní itineráře plné praktických informací a prozkoumejte neobvyklá místa v Česku i zahraničí.",

    travelTipDetailTitle: "Detail cestovního tipu",
    travelTipDetailDescription: "Podívejte se na detailní informace o tomto cestovním tipu. Inspirujte se a naplánujte si svůj další nezapomenutelný výlet.",

    aboutTitle: "O nás - Objevujte s námi svět udržitelně a jinak",
    aboutDescription: "Poznejte náš příběh! Milujeme objevování skrytých míst a věříme v udržitelné cestování. Naše itineráře vás zavedou tam, kam běžní turisté nezavítají.",

    workWithUsTitle: "Spolupracujte s námi",
    workWithUsDescription: "Připojte se k nám a pomozte inspirovat svět k objevování skrytých míst. Společně tvoříme cestovní komunitu plnou vášní a zkušeností.",

    principlesDataProcessingTitle: "Zásady zpracování osobních údajů",
    principlesDataProcessingDescription: "Zjistěte, jak chráníme vaše osobní údaje. Naše zásady jsou navrženy tak, aby byla zajištěna transparentnost a bezpečnost.",

    termsOfUseTitle: "Podmínky užívání",
    termsOfUseDescription: "Přečtěte si naše podmínky užívání a zjistěte, jak funguje naše platforma. Chceme, aby vaše zkušenosti byly co nejlepší.",

    contactTitle: "Kontakt - Napište nám.",
    contactDescription: "Potřebujete poradit nebo máte otázku? Kontaktujte nás přes formulář nebo na sociální sítě. Jsme tu pro vás, ať už plánujete výlet nebo máte zpětnou vazbu.",

    howToTitle: "Jak to funguje - Zjistěte, jak snadno používat naši platformu.",
    howToDescription: "Naučte se snadno používat naši platformu. Od hledání výletů až po sdílení zkušeností. Vše, co potřebujete vědět, na jednom místě.",

    registerTitle: "Registrace - Vytvořte si účet a objevujte skrytá místa.",
    registerDescription: "Staňte se součástí naší komunity. Registrujte se a získejte přístup k unikátním itinerářům, plánování a možnostem spolucestování.",

    authorRegistrationTitle: "Registrace autora - Vytvořte si účet a sdílejte své výlety jako autor itinerářů.",
    authorRegistrationDescription: "Staňte se autorem výletů! Sdílejte své cesty s komunitou a inspirujte ostatní k objevování světa mimo mainstream.",

    loginTitle: "Přihlášení",
    loginDescription: "Pokračuj v plánování nezapomenutelných dobrodružství.",

    resetPasswordTitle: "Obnova hesla",
    resetPasswordDescription: "Zapomněli jste své heslo? Obnovte jej snadno a rychle prostřednictvím našeho formuláře.",

    pinboardTitle: "Spolucestování - Najděte parťáka na cesty",
    pinboardDescription: "Najděte spolucestovatele a objevujte svět společně. Přidejte se k diskuzi nebo si naplánujte vlastní dobrodružství."
}