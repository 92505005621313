<template>
    <div class="conversation position-relative" :class="{active: conversationId}">
        <button class="close-conversation img-button" @click="$emit('closeConversation')">
            <img src="@/assets/svg/icon-arrow-left.svg" alt="">
        </button>
        <div class="header">
            <div class="receiver-info" @click="$bvModal.show('user-detail')">
                <img v-if="receiverAvatarFileName && !$apollo.queries.conversation.loading" class="user-picture" :src="userPictureFilePath(receiverAvatarFileName)" alt="">
                <div v-else-if="receiverUserName && !$apollo.queries.conversation.loading" class="user-picture" >{{receiverUserName.split(' ').map(function(item){return item[0]}).join('').substring(0,3)}}</div>
                <p class="username">{{ userName ? userName : receiverUserName ? receiverUserName : '...' }}</p>
            </div>
            <p class="post-title">{{ pinboardPostTitle ? pinboardPostTitle : conversation ? conversation.pinboardPost.title : '...' }}</p>
        </div>
        <div class="chat">
            <div class="messages-list" v-if="$apollo.queries.conversation.loading">
                <p class="messages-loading">
                    {{ $t("loading.loading") }}
                </p>
            </div>
            <div v-else class="messages-list-container">
                <div class="messages-list" ref="chat">
                    <div v-show="$apollo.queries.oldMessages.loading" class="position-relative">
                        <div  style="height: 40px;" class="loader-1 center paging-loading --gray"><span></span></div>
                    </div>
                    <TrekioButton class="load-more-button" secondary v-show="hasMoreMessagesToLoad && !$apollo.queries.oldMessages.loading && conversationId != -1" @click="fetchOlderMessages">{{ $t("buttons.loadOlder") }}</TrekioButton>
                    <p v-if="!$apollo.loading && !hasMoreMessagesToLoad || postMessages.length < 1" class="post-description">{{ temporaryConversation ? temporaryConversation.pinboardPost.description : conversation.pinboardPost.description }}</p>
                    <p v-if="postMessages.length < 1" class="type-to-start">{{ $t("public.messagesPage.writeMessageToStartConversation") }}</p>
                    <div v-for="groupedMessages in groupedMessagesByDay" :key="groupedMessages.date.toString()" class="grouped-messages-list">
                        <p class="day-title-and-date">{{ groupedMessages.dateFormatted}}</p>
                        <div v-for="message in groupedMessages.messages" :key="message.id"
                        
                        class="message-container" :class="message.senderId == currentUserId ? 'sender-message' : 'receiver-message'"
                        >
                            <p class="message">{{ message.message }}</p>
                            <p class="date">{{ formatDate(message.createdAt) }}</p>
                        </div>
                    </div>
                    <div v-for="message in messageQueue" :key="message" class="message-container temporary-message">
                        <p class="message">{{ message }}</p>
                        <p class="state reciever-message">{{ $t("loading.send") }}</p>
                    </div>
                </div>
            </div>
            <form @submit.prevent="onSubmit">
                <textarea @input="adjustTextareaHeight" style="height: 40px;" rows="1" column="10" maxLength="4096" v-model="message" @keydown.enter.prevent.exact="onSubmit" @keydown.enter.alt.prevent="insertNewLine" :placeholder="$t('public.messagesPage.writeMessage')" ref="message-input"></textarea>
                <button class="img-button">
                    <img src="@/assets/svg/icon-send.svg" alt="">
                </button>
            </form>
            <b-modal class="modal" :id="`user-detail`" hide-footer hide-header>
                <div class="user-detail-container">
                    <img v-if="receiverAvatarFileName && !$apollo.queries.conversation.loading" class="user-picture" :src="userPictureFilePath(receiverAvatarFileName)" alt="">
                    <div v-else-if="receiverUserName && !$apollo.queries.conversation.loading" class="user-picture" >{{receiverUserName.split(' ').map(function(item){return item[0]}).join('').substring(0,3)}}</div>
                    <p class="username">{{ receiverUserName }}</p>
                <div class="buttons-container">
                </div>
            </div>
        </b-modal>
        </div>
    </div>

</template>

<script>
    import MessageService from '@/services/MessageService'
    import { CONVERSATION_BY_ID, UNREAD_MESSAGES, MESSAGES } from '@/api/graphql/query/MessageQuery'
    import {CLEVERTRIP_AVATAR_URL}  from '@/definitions';
    import sharedUtils from '@/utils/sharedUtils'

    const MESSAGES_PAGE_SIZE = 20
    
    export default {

        data() {
            return {
                message: '',
                temporaryMessage: '',
                shouldScrollToBottom: true,
                messageQueue: [],
                receiverUserName: null,
                receiverAvatarFileName: null,
                messages: [],
                lastMessageDate: null,
                isPollingUnreadMessage: false,
                imgURL: CLEVERTRIP_AVATAR_URL,
                scrollPosition: null,
                hasMoreMessagesToLoad: true,
                
            }
        },

        props: {
            conversationId: {
                required: true,
            },
            userName: {
                type: String
            },
            pinboardPostTitle: {
                type: String
            },
            temporaryConversation: {
                type: Object
            }
        },

        created() {
            if (this.temporaryConversation) {
                this.setReceiverInfo(this.temporaryConversation.postOwner, this.temporaryConversation.conversationInitiator)
            }
        },

        watch: {
            conversationId(nV) {
                this.$apollo.queries.oldMessages.skip = true
                this.resetToDefault()
                this.$refs['message-input'].focus()
            },
            
        },

        computed: {
            preferredLanguage() {
                return this.$store.state.preferredLanguage
            },
            currentUserId() {
                return this.$store.state.currentUserId
            },
            postMessages() {
                if (this.messages.length < 1) return []
                return this.messages
            },
            groupedMessagesByDay() {
                const moment = require('moment')
                const groups = [];
                this.postMessages.forEach(message => {
                    const date = moment(message.createdAt);
                    const dayName = date.format('dddd');
                    const monthName = date.format('MMMM')
                    
                    const existingGroup = groups.find(group => group.date.isSame(date, 'day'));
                    
                    if (existingGroup) {
                        existingGroup.messages.push(message);
                    } else {
                        const dateFormatted = this.preferredLanguage === "cs" ?
                            this.$t('general.' + dayName.toLowerCase()) + ' ' + date.format('D. M. YYYY') :
                            dayName + ', ' + date.format('DD') + ' ' + monthName + ' ' +  date.format('YYYY')

                        groups.push({
                            date: date,
                            dateFormatted: dateFormatted,
                            messages: [message]
                        });
                    }
                });
                return groups;
            }
        },

        methods: {
            async createConversation(newConversationPostId) {
                await MessageService.createConversation(newConversationPostId).
                    then((resp) => {
                        this.$emit('conversationCreated', resp.data.createOrGetConversation.id)
                    })
                    .catch(err => {
                        console.log(err)
                    })

            },
            getFormattedDate(timestamp) {
                const moment = require('moment')
                return moment(timestamp).format('dddd');
            },
            insertNewLine() {
                this.message += "\n";
                const input = this.$refs['message-input']
                this.$nextTick(() => {
                    input.scrollTop = input.scrollHeight 
                    this.adjustTextareaHeight()
                })
            },
            adjustTextareaHeight() {
                const textarea = this.$refs['message-input'];
                textarea.style.height = 'auto';
                textarea.style.height = textarea.scrollHeight + 4 + 'px';
            },
            fetchOlderMessages() {
                const messagesListEl = this.$refs.chat
                this.lastMessageDate = this.messages[0].createdAt

                this.scrollPosition = messagesListEl.scrollHeight - messagesListEl.scrollTop
                this.$apollo.queries.oldMessages.stop()
                this.$apollo.queries.oldMessages.start()
            },
            userPictureFilePath(fileName) {
                return sharedUtils.getPictureUrlLowResolution(fileName, this.imgURL)
            },
            scrollToBottom() {
                this.$nextTick(() => {
                    this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight;
                })
            },
            formatDate(date) {
                let moment = require('moment')
                return moment(date).format("HH:mm")
            },
            async onSubmit() {
                this.message = this.message.trim()
                if (!this.message) {
                    return this.$nextTick(() => {
                        this.adjustTextareaHeight()
                    })
                }
                this.temporaryMessage = this.message
                this.message = ''
                this.messageQueue.push(this.temporaryMessage)
                this.$nextTick(() => {
                    this.adjustTextareaHeight()
                    this.scrollToBottom()
                })
                if (this.conversationId == -1) {
                    await this.createConversation(this.temporaryConversation.pinboardPost.id)
                }
                await MessageService.createMessage(this.temporaryMessage, this.conversationId)
                    .then(async resp => {
                        this.shouldScrollToBottom = true
                        this.messages.push(resp.data.createMessage)
                        this.removeFirstFromMessageQueue()
                    })
                    .catch(err => {
                        console.log((err))
                        this.removeFirstFromMessageQueue()
                    })
            },
            removeFirstFromMessageQueue() {
                this.messageQueue.shift()
            },

            async markMessagesAsSeen(lastMessage) {
                await MessageService.markMessagesAsSeen(lastMessage, this.conversationId)
                .then(resp => {
                    console.log(resp)
                    })
            },
            setReceiverInfo(postOwner, conversationInitiator) {
                if (postOwner.id == this.currentUserId ) {
                    this.receiverUserName = conversationInitiator.userName
                    this.receiverAvatarFileName = conversationInitiator.avatarFileName
                } else {
                    this.receiverUserName = postOwner.userName
                    this.receiverAvatarFileName = postOwner.avatarFileName
                }
            },
            resetToDefault() {
                this.messages = []
                this.isPollingUnreadMessage = false
                this.hasMoreMessagesToLoad = true

            }
        },

        apollo: {
            conversation: {
                query: CONVERSATION_BY_ID,
                variables: function() {
                    return {
                        messageCount: MESSAGES_PAGE_SIZE,
                        conversationId: this.conversationId
                    }
                },
                async result({data}) {
                    if (!data) return
                    
                    this.setReceiverInfo(data.conversation.postOwner, data.conversation.conversationInitiator)
                    if (this.shouldScrollToBottom) {
                        this.scrollToBottom()
                    }

                    const postMessages = data.conversation.postMessages
                    if (postMessages.length < MESSAGES_PAGE_SIZE) {
                        this.hasMoreMessagesToLoad = false
                    }

                    this.messages = [...postMessages.reverse()]
                    if (postMessages.length > 0) {
                        const lastMessage = postMessages[postMessages.length - 1]

                        if (!lastMessage.seenAt && lastMessage.senderId != this.currentUserId) {
                            await this.markMessagesAsSeen(postMessages[postMessages.length - 1])
                            this.isPollingUnreadMessage = true
                        } else {
                            this.isPollingUnreadMessage = true
                        }
                    } else {
                        this.isPollingUnreadMessage = true
                    }


                },
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
                skip() {
                    return !this.conversationId || this.conversationId == -1
                }
            },
            unreadMessages: {
                query: UNREAD_MESSAGES,
                variables: function() {
                    return {
                        conversationId: this.conversationId
                    }
                },
                result({data}) {
                    
                    const postMessages = data.unreadMessages
                    
                    if (postMessages.length > 0 && !postMessages[0].seenAt) {
                        this.markMessagesAsSeen(postMessages[0])
                        this.messages = [...this.messages, ...postMessages]
                    }
                    if (postMessages.length > 0) {
                        this.scrollToBottom()
                    }
                },
                update: data => {
                    return data.unreadMessages.reverse()
                },
                fetchPolicy: 'no-cache',
                pollInterval: 5000,
                skip: function() {
                    return !this.conversationId || this.conversationId == -1 || !this.isPollingUnreadMessage
                }
            },
            oldMessages: {
                query: MESSAGES,
                variables: function() {
                    return {
                        conversationId: this.conversationId,
                        messageCount: MESSAGES_PAGE_SIZE,
                        lastMessageDate: this.lastMessageDate,
                    }
                },
                skip: true,
                result({data}) {
                    if (!data.oldMessages.length) return this.hasMoreMessagesToLoad = false

                    const oldMessages = [...data.oldMessages].reverse()
                    if (oldMessages.length < MESSAGES_PAGE_SIZE) {
                        this.hasMoreMessagesToLoad = false
                    }

                    this.$apollo.queries.oldMessages.skip = true
                    this.messages = [...oldMessages, ...this.messages];

                    this.$nextTick(() => {
                        this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight - this.scrollPosition;
                    })
                }
            }
        },
    }

</script>

<style lang="scss" scoped>
    @import '@/scss/variables';
            
        .user-detail-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .user-picture {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 40px;
                max-width: 400px;
                max-height: 400px;
                height: 100%;
                aspect-ratio: 1;
                object-fit: cover;
                background-color: $tr-green;
                margin-bottom: 20px;
            }

            .username {
                font-size: 25px;
                
            }
        }
    .conversation {
        max-width: 660px;
        width: 100%;
        margin-left: 40px;

        .close-conversation {
            display: none;
        }

        @media (max-width: 992px) {
            margin-left: 20px;

            .message {
                max-width: 65% !important;
            }
        }

        @media (max-width: 768px), (max-height: 600px) {
            display: none;

            .header {
                padding-top: 16px !important;
            }

            .close-conversation {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                top: 25px;
                width: 50px;
                height: 50px;

                img {
                    height: 40px;
                }
            }

            &.active {
                position: fixed !important;
                height: calc(100% + 40px);
                top: 0;
                left: 0;
                margin: 0;
                z-index: 1039;
                display: block !important;
                max-width: 100%;
                background-color: rgb(248, 250, 251);

                .header .user-picture {
                    display: none;
                }

                .chat {
                    height: calc(100% - 155px);

                    .messages-list {
                        height: 100% !important;
                    }
                }
            }
        }
        
        p {
            margin-bottom: 0;
        }

        .header {
            background-color: $tr-white;
            height: 100px;
            text-align: center;
            position: relative;
            padding-top: 26px;
            box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2);
            padding-inline: 40px;

            .receiver-info {
                cursor: pointer;
            }
            
            .user-picture {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
                top: -20px;
                object-fit: cover;
                width: 40px;
                height: 40px;
                aspect-ratio: 1;
                background-color: $tr-green;
            }

            p {
                line-height: 20px;
            }

            .post-title {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                color: $tr-gray;
            }
        }

        .chat {
            height: 450px;
            display: flex;
            flex-direction: column;

            @media (min-height: 600px) and (max-height: 900px) {
                height: calc(100vh - 360px)

            }
            @media (min-height: 600px) and (max-height: 900px) and (max-width: 900px) {
                height: calc(100vh - 280px)
            }
            
            .messages-list-container {
                overflow-y: auto;
                height: 100%;
            }
                
            .messages-list{
                display: flex;
                flex-direction: column;
                gap: 20px;
                height: 100%;
                padding-top: 20px;
                overflow: auto;

                .day-title-and-date {
                    color: $tr-gray;
                    text-align: center;
                }

                .post-description {
                    text-align: left;
                    margin-inline: 10px;
                    border: 1px solid $tr-gray;
                    padding: 10px 15px;
                    white-space: pre-wrap;
                    word-break: break-word;
                }

                .type-to-start {
                    text-align: center;
                    font-weight: 600;
                }


                .messages-loading {
                    text-align: center;
                }
                
                .load-more-button {
                    height: 40px !important;
                    max-width: 200px;
                    flex-shrink: 0;
                    height: 40px;
                    align-self: center;
                }

                .grouped-messages-list {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }

                .message-container {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 8px;
    
                    .message {
                        max-width: 45%;
                        padding: 16px;
                        line-height: 20px;
                        white-space: pre-wrap;
                        word-break: break-word;
                    }

                    
                    .date {
                        color: $tr-gray;
                        font-size: 12px;
                    }
    
    
                    &.receiver-message .message {
                        border: 1px solid $tr-light-gray;
                        background-color: $tr-white;
                    }
                    
                    &.sender-message, &.temporary-message {
                        align-self: flex-end;
                        flex-direction: row-reverse;
                        .message {
                            flex-direction: row-reverse;
                            background-color: $tr-green;
                        }
                    } 
                    &.temporary-message {
                        position: relative;
                        margin-bottom: 15px;

                        .state {
                            position: absolute;
                            top: 100%;
                            font-size: 12px;
                            color: $tr-gray;
    
                        }
                    }
                }
            }

            form {
                padding-top: 10px;
                display: flex;
                align-items: flex-end;

                textarea {
                    resize: none;
                    height: 40px;
                    max-height: 112px;
                    width: 100%;
                    overflow-y: auto;
                    padding-block: 6px;
                    padding-inline: 10px 5px;
                    background-color: $tr-white;
                    color: $tr-black;
                    outline: 2px solid $tr-white;
                    border: none;
                    
                    &:focus {
                        outline: 2px solid $tr-green !important;
                    }

                    &::placeholder {
                        color: $tr-gray;
                        opacity: 1; /* Firefox */
                    }

                    &::-ms-input-placeholder { /* Edge 12 -18 */
                        color: $tr-gray;
                    }
                }

                button {
                    height: 44px;
                    width: 64px;
                }
            }
        }
    }


</style>