<template>
  <div class="text-left bg-white-alt">
    <NavBarNew :greenLogo='true'/>
    <main>
      <section class="tr-container tr-glasses tr-glasses-o-nas--intro">
        <header class="tr__header">
          <h1 class="tr__h1">{{ $t("public.aboutPage.title") }}</h1>
          <router-link class="button button--primary" to="/#favorite-travel-tips-wrapper">{{ $t("buttons.hopOn") }}</router-link>
        </header>
        <article>
          <div class="row">
            <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
              <h3 class="tr__h3">{{ $t("public.aboutPage.whoAreWeTitle") }}</h3>
            </div>
            <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
              <div class="tr-photo-card">
                <img alt="Kdo jsme" src="../../assets/o_nas.jpg">
              </div>
            </div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
              <p>
                {{ $t("public.aboutPage.whoAreWeText") }}
              </p>
            </div>
          </div>
        </article>
      </section>

      <section class="tr-container">
        <article>
          <div class="row">
            <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
              <h3 class="tr__h3">{{ $t("public.aboutPage.ourApproachTitle") }}</h3>
            </div>
            <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
              <div class="tr-photo-card">
                <img alt="Náš přístup" src="../../assets/nas_pristup.jpg">
              </div>
            </div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
              <p>
                {{ $t("public.aboutPage.ourApproachText") }}
              </p>
            </div>
          </div>
        </article>
      </section>

      <section
          class="tr-container tr-glasses tr-glasses-o-nas--pink tr-vertical-center tr-section-50vh tr-section-100vh--lg">
        <h2 class="tr__h2">{{ $t("public.aboutPage.preparingTripsIn15Countries") }}</h2>
      </section>

      <section class="tr-container">
        <article>
          <div class="row">
            <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
              <h3 class="tr__h3">{{ $t("public.aboutPage.howItAllStartedTitle") }}</h3>
            </div>
            <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
              <div class="tr-photo-card">
                <img alt="Jak to začalo" src="../../assets/jak-to-zacalo.png">
              </div>
            </div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
              <p>
                {{ $t("public.aboutPage.howItAllStartedText") }}
              </p>
              <p>
                {{ $t("public.aboutPage.howItAllStartedText2") }}
              </p>
            </div>
          </div>
        </article>
      </section>

      <section class="tr-container">
        <article>
          <div class="row">
            <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
              <h3 class="tr__h3">{{ $t("public.aboutPage.fromAnIdeaToWorkingSearchEngineTitle") }}</h3>
            </div>
            <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
              <div class="tr-photo-card">
                <img alt="První prototyp" src="../../assets/prvni-prototyp.png">
              </div>
            </div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
              <p>
                {{ $t("public.aboutPage.fromAnIdeaToWorkingSearchEngineText") }}
              </p>
              <p>
                {{ $t("public.aboutPage.fromAnIdeaToWorkingSearchEngineText2") }}
              </p>
            </div>
          </div>
        </article>
      </section>

      <section class="tr-container tr-glasses tr-glasses-o-nas--orange tr-vertical-center tr-section-50vh tr-section-100vh--lg">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-7 offset-lg-1">
              <h2 class="tr__h2">{{ $t("public.aboutPage.wePlanSoYouDontHaveTo") }}</h2>
            </div>
          </div>
        </div>
      </section>

      <section class="tr-container">
        <article>
          <div class="row">
            <div class="col-12 col-lg-4 tr-order-4 tr-order-1--lg"></div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-2 tr-order-2--lg">
              <h3 class="tr__h3">{{ $t("public.aboutPage.whatNextTitle") }}</h3>
            </div>
            <div class="col-12 col-lg-4 tr-order-4 tr-order-3--lg">
              <div class="tr-photo-card">
                <img alt="A co dál?" src="../../assets/dalsi-meta.png">
              </div>
            </div>
            <div class="col-12 col-lg-7 offset-lg-1 tr-order-3 tr-order-4--lg">
              <p>
                {{ $t("public.aboutPage.whatNextText") }}
              </p>
              <p>
                {{ $t("public.aboutPage.whatNextText2") }}
              </p>
              <p>
                {{ $t("public.aboutPage.whatNextText3") }}
              </p>
            </div>
          </div>
        </article>
      </section>

      <section class="tr-container tr-glasses tr-glasses-o-nas--yellow tr-vertical-center tr-section-50vh tr-section-100vh--lg">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-7 offset-lg-2 ">
              <h2 class="tr__h2">{{ $t("public.aboutPage.coolPlacesYouMightNotFind") }}</h2>
            </div>
          </div>
        </div>
      </section>

      <section class="tr-container">
            <div class="row">
                <div class="col-12 col-lg-7 offset-lg-5">
                    <h3 class="tr__h3">{{ $t("public.aboutPage.wantToBecomeAuthor") }}</h3>
                </div>
                <div class="col-12 col-lg-7 offset-lg-5">
                    <router-link to="/workWithUs"><button class="button button--primary">{{ $t("buttons.becomeAuthor") }}</button></router-link>
                </div>
            </div>
        </section>
    </main>
    <Footer style="margin-top: 173px"/>
    <div class="to-top-arrow" @click="scrollToTop"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";

export default {

  name: "AboutPage",
  metaInfo() {
    return {
      title: this.$t('meta.aboutTitle'),
      meta: [
        {
          vmid: 'description',
          name: "description",
          content: this.$t('meta.aboutDescription')
        }
      ]
    }
  },
  components: {
    Footer
  },
  mounted() {
    window.addEventListener('scroll', this.showButtonToTop)
  },
  destroyed() {
    window.removeEventListener('scroll', this.showButtonToTop)
  },
  methods: {
    showButtonToTop() {
      if (this.throttleTimer) return;
        this.throttleTimer = true;
        setTimeout(() => {
            const scrollButton = document.querySelector('.to-top-arrow')
            if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
              scrollButton.classList.add("show");
            } else {
              scrollButton.classList.remove('show');
            }
            this.throttleTimer = false;
          }, 100);
    },
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }
}
</script>

<style scoped lang="scss">

  section {
    margin-bottom: 60px
  }

@import "../../global";

  .tr-glasses {
    background-size: 60% auto;

    @media screen and (max-width: 600px) {
      background-size: 100% auto;
    }
  }

  @media (orientation: landscape) {
    .tr-glasses {
      background-size: auto 60% !important;
    }
  }


</style>