import gql from 'graphql-tag'


export const TRAVEL_TIPS_BY_USER_ID = gql`query travelTipsAdminByUserId($userId:Int!) {
    travelTips :travelTipsAdminByUserId(userId:$userId) {
        state
        itineraryDays {
            itineraryItems {
                countries {
                    id,
                    code,
                    nameCzech,
                    nameEnglish
                }
            }
        },
    }
}`;

export const TRAVEL_TIPS_BY_USER_ID_FOR_COUNTRIES = gql`query travelTipsAdminByUserId($userId:Int!) {
    travelTips :travelTipsAdminByUserId(userId:$userId) {
        state
        itineraryDays {
            itineraryItems {
                countries {
                    id,
                    code,
                    nameCzech,
                    nameEnglish
                }
            }
        },
    }
}`;

export const TRAVEL_TIPS_FOR_COUNTRIES_AUTHORS = gql`query travelTipList {
    travelTips:travelTipsShortList {
        author {
            id
            userName
        }
        itineraryDays {
            itineraryItems {
                countries {
                    id
                    code
                    nameCzech
                    nameEnglish
                }
            }
        }
    }
}`;

export const TRAVEL_TIPS = gql`query travelTipList {
    travelTips:travelTipsShortList {
        id,
        titlePicture {
            id,
            fileName
        },
        state,
        topDate,
        campaign {
            id,
        },
        author {
            id
            userName
            avatarFileName
        },
        originalLanguage,
        localizations {
            language,
            title,
            shortInfo,
        }
        itineraryDays {
            id,
            itineraryItems {
                id,
                difficulty,
                transferTypeValues {
                    transferType
                    length
                },
                countries {
                    id,
                    code,
                    nameCzech,
                    nameEnglish
                }
            }
        },
        purchasable,
        dayCount
    }
}`;
export const TRAVEL_TIPS_TO_GET_COUNTRIES_AND_TAGS = gql`query travelTipList($filter: TravelTipsFilter, $pageSize: PageSize) {
    travelTips:travelTipsActual(filter: $filter, pageSize: $pageSize) {
        tagCategories {
            tags {
                id
                localizations {
                    title
                }
            }
        }
        itineraryDays {
            itineraryItems {
                countries {
                    id,
                    code,
                    nameCzech,
                    nameEnglish
                }
            }
        },
    }
}`;

export const TRAVEL_TIPS_WITH_DAYCOUNT_ONLY = gql`query travelTipsWithDaycountOnly($filter: TravelTipsFilter, $pageSize: PageSize) {
    travelTipsWithDaycountOnly:travelTipsActual(filter: $filter, pageSize: $pageSize) {
        dayCount
    }
}`;

export const FILTERED_TRAVEL_TIPS = gql`query travelTipList($filter: TravelTipsAdminFilter, $pageSize: PageSize) {
    filteredTravelTips:travelTipsShortList(filter: $filter, pageSize: $pageSize) {
        id,
        titlePicture {
            fileName
        },
        state,
        campaign {
            localizations {
                language
                title
            }
        },
        author {
            userName
            avatarFileName
        },
        originalLanguage
        localizations {
            published
            language
            title
            shortInfo
        }
        itineraryDays {
            itineraryItems {
                difficulty,
                transferTypeValues {
                    transferType
                },
                countries {
                    nameCzech,
                    nameEnglish
                }
            }
        },
        purchasable,
    }
}`;

export const GET_TRAVEL_TIPS = gql`query GetTravelTipsQuery($campaignId: Int!) {
    travelTips(campaignId: $campaignId) {
        id, 
        campaignId, 
        titlePicture {
            id,
            fileName
        }, 
        hashtags
        localizations {
            language
            title,
            shortInfo
        }
    }
}`;

export const TRAVEL_TIP_BY_ID= gql`query GetTravelTipByIdQuery($travelTipId: Int!) {
    travelTip(travelTipId: $travelTipId) {
        campaign {
            id,
            localizations {
                title
            }
        }
        author {
            userName
            about
            avatarFileName
            isAuthorPublished
        }
        id,
        titlePicture {
            id,
            localizations {
                language
                title
            }
            fileName
        },
        tagCategories {
            localizations {
                language
                title
                description
            }
            tags {
                id
                iconFileName
                localizations {
                    language
                    title
                }
            }
        }
        purchasable,
        rating,
        theme,
        dayCount,
        state,
        localizations {
            language
            title
            shortInfo
            perex
            description
            practicalInfo
        }
        itineraryDays {
            id,
            dayNumber,
            localizations {
                language
                title,
                description
            }
            itineraryItems{
                id,
                itineraryItemType,
                itemNumber,
                countries {
                    id,
                    nameCzech,
                    nameEnglish
                },
                duration,
                difficulty,
                transferTypeValues {
                    transferType
                    length
                },
                location {
                    latitude
                    longitude
                }
                localizations {
                    language
                    title,
                    description,
                    warning
                }
                itineraryItemPictures {
                    id,
                    fileName
                    localizations {
                        language
                        title
                    }
                }
            }
        }
    }
}`;

export const TRAVEL_TIP_BY_ID_ADMIN= gql`query GetTravelTipByIdQuery($travelTipId: Int!) {
    travelTip:travelTipAdmin(travelTipId: $travelTipId) {
        campaign {
            id,
            localizations {
                title
            }
        }
        id,
        hashtags,
        titlePicture {
            id,
            fileName
            localizations {
                language
                title
            }
        },
        tagCategories {
            localizations {
                language
                title
            }
            tags {
                id
                localizations {
                    language
                    title
                }
            }
        }
        purchasable,
        rating,
        theme,
        dayCount,
        state,
        author {
            id
            userName
            avatarFileName
        },
        originalLanguage
        localizations {
            language
            title,
            shortInfo,
            perex,
            description,
            practicalInfo,
            translated
            published
        }
        itineraryDays {
            id,
            dayNumber,
            picture {
                id,
                fileName,
                localizations {
                    title
                }
            },
            localizations {
                language,
                title,
                description,
                translated
            }
            itineraryItems{
                id,
                itineraryItemType,
                itemNumber,
                countries {
                    id,
                    nameCzech,
                    nameEnglish
                }
                difficulty,
                transferTypeValues {
                    transferType
                    length
                },
                location {
                    latitude,
                    longitude
                }
                duration,
                localizations {
                    language
                    title,
                    description,
                    warning,
                    translated
                }
                itineraryItemPictures {
                    id,
                    fileName
                    localizations {
                        language
                        title
                    }
                }
            }
        }
    }
}`;

export const TRAVEL_TIPS_FAVORITE= gql`query { travelTipsFavorite {
    id,
    state
    titlePicture {
        id,
        fileName
    },
    theme,
    dayCount,
    localizations {
        title,
    }
    itineraryDays {
        id,
        itineraryItems {
            id
            countries {
                id
                code
                nameCzech
                nameEnglish
            }
            transferTypeValues {
                transferType
                length
            }
        }
    }
}
}`;

export const TRAVEL_TIPS_SIMILAR= gql`query ($travelTipId:Int!) { travelTipsSimilar(travelTipId:$travelTipId) {
    id,
    state
    titlePicture {
        id,
        fileName
    },
    theme,
    dayCount,
    localizations {
        title,
    }
    itineraryDays {
        id,
        itineraryItems {
            id
            countries {
                id
                code
                nameCzech
                nameEnglish
            }
            transferTypeValues {
                transferType
                length
            }
        }
    }
}
}`;

export const ACTIVE_TRAVEL_TIPS = gql`query travelTipList($filter: TravelTipsAdminFilter) {
    filteredTravelTips:travelTipsShortList(filter: $filter) {
        id
    }
}`;


export const MY_TRAVEL_TIPS = gql`query myTripsTravelTips($filter:TravelTipsMyTripsFilter, $pageSize:PageSize) {
    myTripsTravelTips(filter: $filter, pageSize: $pageSize) {
        id,
        titlePicture {
            fileName
        },
        theme,
        localizations {
            title
        }
        itineraryDays {
            itineraryItems {
                countries {
                    nameCzech,
                    nameEnglish
                }
                transferTypeValues {
                    transferType
                }
            },
        }
    }
}`;

export const MY_TRAVEL_TIPS_FOR_COUNTRIES = gql`query myTravelTips {
    myTravelTipsForCountries:myTripsTravelTips {
        itineraryDays {
            itineraryItems {
                countries {
                    id,
                    code,
                    nameCzech,
                    nameEnglish
                }
            }
        },
    }
}`;

export const LIKED_TRAVEL_TIPS = gql`query likedTravelTips($filter:TravelTipsMyTripsFilter) {
    likedTravelTips:myTripsTravelTips(filter: $filter) {
        id,
    }
}`;
