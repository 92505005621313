<template>
    <div class="d-flex" :class="['justify-content-' + this.filterButtonAlignment]">   
        <TrekioButton secondary class="d-flex align-items-center justify-content-center" maxWidth="160px" @click="isSidebarVisible = true">
             <div class="filter-icon-container" ref="filterIcon">
                 <div v-if="activeFiltersCount > 0" class="filter-count">{{ activeFiltersCount }}</div>
                 <img src="@/assets/svg/icon-filter.svg" class="filter-icon" alt="filter">
             </div>
            {{ $t('buttons.filter') }}
        </TrekioButton>             
        <button class="floating-button filter-icon-container" :class="{show: isFixedFilterButtonVisible}" @click="isSidebarVisible = true">
            <div v-if="activeFiltersCount > 0" class="filter-count">{{ activeFiltersCount }}</div>
            <img src="@/assets/svg/icon-filter.svg" class="filter-icon" alt="filter">
        </button>
        <Sidebar  :isVisible="isSidebarVisible" @closeSidebar="isSidebarVisible=false" :title="$t('public.myTravelTipsPage.filterTrips')">
            <div class="filter-sidebar">
                <div class="filters-container text-left d-flex flex-column px-40" style="gap: 30px">
                    <TrekioInput id="serach" searchInput v-model="filter.searchString" :placeholder="$t('general.search')"/>
                    <TrekioSelect v-if="filter.transferTypes" v-model="filter.transferTypes" selectAllOption :isAllSelected="filter.transferTypes.length == transferTypes.length" multiple
                        :text="selectTransferTypeText"
                    >

                        <TrekioSelectOption v-for="transfer in transferTypes" :key="transfer.value" :value="transfer.value">{{ transfer.text }}</TrekioSelectOption>
                    </TrekioSelect>
                    <TrekioSelect v-if="countries && filter.countries" v-model="filter.countries" selectAllOption :isAllSelected="filter.countries.length == filteredCountries.length" multiple
                        :text="selectCountriesText"
                        searchInput @searchInput="value => countriesSearchString = value" :searchString="countriesSearchString"
                    >

                        <TrekioSelectOption v-for="country in filteredCountries" :key="country.code" :value="country.code">{{ country[countryLocalization] }}</TrekioSelectOption>
                    </TrekioSelect>
                    <TrekioSelect v-if="filter.themes" v-model="filter.themes" selectAllOption :isAllSelected="filter.themes.length == themes.length" multiple
                        :text="selectThemeText"
                    >

                        <TrekioSelectOption v-for="theme in themes" :key="theme.value" :value="theme.value">{{ theme.text }}</TrekioSelectOption>
                    </TrekioSelect>
                    <TrekioSelect v-if="filter.difficulties" v-model="filter.difficulties" selectAllOption :isAllSelected="filter.difficulties.length == difficulties.length" multiple
                        :text="selectDifficultyText"
                    >

                        <TrekioSelectOption v-for="difficulty in difficulties" :key="difficulty.value" :value="difficulty.value">{{ difficulty.text }}</TrekioSelectOption>
                    </TrekioSelect>
                    <TrekioSelect v-if="filter.tagIds" v-model="filter.tagIds" selectAllOption :isAllSelected="filter.tagIds.length == filteredTags.length" multiple
                        :text="selectTagsText"
                    >
                        <TrekioSelectOption v-for="tag in filteredTags" :key="tag.id" :value="tag.id">{{ tag.localizations[0].title }}</TrekioSelectOption>
                    </TrekioSelect>
                    <div class="dayslider">
                      <label>{{ $t("filter.tripLength") }}</label>
                      <VueSlider
                          ref="slider"
                          v-model="slider.value"
                          v-bind="slider.options"
                          :tooltip="showMergeTooltip ? 'none' : 'always'"
                      >
                        <template v-slot:process="{start, end, style}">
                          <div class="vue-slider-process" :style="style">
                            <div v-if="showMergeTooltip" :class="[
                              'vue-slider-custom-merge-tooltip',
                              'vue-slider-dot-tooltip-bottom',
                              'vue-slider-dot-tooltip-inner'
                              ]">
                              {{ $tc("general.dayAmmount", slider.value[0]) }} - {{ $tc("general.dayAmmount", slider.value[1]) }}
                            </div>
                          </div>
                        </template>
                      </VueSlider>
                    </div>
                </div>
                <div class="buttons-container px-40">
                    <p role="button" class="mb-3 underline" @click="setDefaultFilter">{{ $t("public.myTravelTipsPage.clearFilter") }}</p>
                    <TrekioButton primary @click="showResult">{{ $t("buttons.show") }}</TrekioButton>
                </div>
            </div>
        </Sidebar>
    </div>
</template>

<script>
import Sidebar from './Sidebar.vue';
import sharedUtils from '../utils/sharedUtils';
import {COUNTRIES} from "@/api/graphql/query/CountryQuery";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { TAGS } from '../api/graphql/query/TagQuery';

    export default {
        components: {
            Sidebar,
            VueSlider
        },
        props: {
            filterButtonAlignment: {
                type: String,
                default: "end"
            },
            filter: {
                type: Object,
                required: true,
                default() {
                    return {
                        countries: [],
                        difficulties: [],
                        transferTypes: [],
                        themes: [],
                        tagIds: [],
                    };
                },
            },
            ignoreGlobalSliderValues: {
                type: Boolean,
                default: false
            },
            countriesOptions: null,
            difficultiesOptions: null,
            transferTypesOptions: null,
            themesOptions: null,
            tagsOptions: null,
            sliderMaxValue: null,
        },

        created() {
            if (this.$store.state.currentSliderValue[1] != null) {
                this.slider.value = [this.$store.state.currentSliderValue[0], this.$store.state.currentSliderValue[1]]
            }
        },
        

        data() {
            return {
                slider: {
                    value: [this.filter.minLengthOfTravel, this.filter.maxLengthOfTravel > this.sliderMaxValue ? this.sliderMaxValue : this.filter.maxLengthOfTravel ],
                    options: {
                        dotSize: 30,
                        width: 'auto',
                        height: 1,
                        contained: false,
                        direction: 'ltr',
                        data: null,
                        dataLabel: 'label',
                        dataValue: 'value',
                        min: 1,
                        max: this.sliderMaxValue ? this.sliderMaxValue : 10,
                        interval: 1,
                        disabled: false,
                        clickable: true,
                        duration: 0.5,
                        adsorb: false,
                        lazy: false,
                        tooltip: 'active',
                        tooltipPlacement: 'bottom',
                        tooltipFormatter: function (value) {
                            return this.$tc("general.dayAmmount", value)
                        },
                        useKeyboard: false,
                        keydownHook: null,
                        dragOnClick: false,
                        enableCross: true,
                        fixed: false,
                        minRange: void 1,
                        maxRange: void 10,
                        order: true,
                        marks: false,
                        dotOptions: void 0,
                        dotAttrs: void 0,
                        process: true,
                        dotStyle: void 0,
                        railStyle: void 0,
                        processStyle: void 0,
                        tooltipStyle: void 0,
                        stepStyle: void 0,
                        stepActiveStyle: void 0,
                        labelStyle: void 0,
                        labelActiveStyle: void 0,
                    },
                },
                isSidebarVisible: false,
                searchString: '',
                countriesSearchString: '',
                observer: null,
                isFixedFilterButtonVisible: false,
            }
        },

        mounted() {
            this.throttledHandleScroll = this.throttleAndDebounce(this.handleScroll, 100, 200);
            window.addEventListener('scroll', this.throttledHandleScroll);
            document.addEventListener("keypress", this.handleEnterPress)
        },
        
        destroyed() {
            window.removeEventListener('scroll', this.throttledHandleScroll);
            document.removeEventListener("keypress", this.handleEnterPress)
        },

        methods: {
            throttleAndDebounce(func, throttleDelay, debounceDelay) {
                let inThrottle = false;
                let timeout;

                return function (...args) {
                    const context = this;

                    if (!inThrottle) {
                    func.apply(context, args);
                    inThrottle = true;
                    setTimeout(() => {
                        inThrottle = false;
                    }, throttleDelay);
                    }

                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                    func.apply(context, args);
                    }, debounceDelay);
                };
            },

            isElementVisible(element, offset = 0) {
                const rect = element.getBoundingClientRect();
                const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
                return (
                    rect.top >= offset &&
                    rect.bottom <= viewportHeight &&
                    rect.bottom > offset
                );
            },

            handleScroll() {
                const filterIcon = this.$refs.filterIcon;
                const navbarElement = document.getElementById('nav-menu-wrapper')
                const navbarHeight = navbarElement.classList.contains('hidden') ? 0 : navbarElement?.offsetHeight;

                if (!filterIcon) return;

                this.isFixedFilterButtonVisible = !this.isElementVisible(filterIcon, navbarHeight);
            },
            handleEnterPress(e) {
                if (this.isSidebarVisible && e.key === "Enter") {
                    this.showResult()
                }
            },
            showResult() {
                this.isSidebarVisible = false
                this.$emit('showResult')
            },
            setDefaultFilter() {
                this.$emit('setDefaultFilter')
            },
            translate(val, array) {
                if (array == 'countries') {
                    if (!this.countries || this.countries.length < 1) return
                    let value = this.countries.find(value => value.code === val)[this.countryLocalization]
                    return value
                }
                let foundValue = this[array].find(value => value.value === val);
                if (foundValue) return foundValue.text;
                return null
            },
        },

        watch: {
            sliderValue(nV) {
                this.$store.state.currentSliderValue = this.slider.value;
                this.filter.minLengthOfTravel = this.slider.value[0];
                this.filter.maxLengthOfTravel = this.slider.value[1];
            },
            sliderMaxValue(nV) {
                if (nV) {
                    if (nV < this.slider.value[1]) {
                        this.slider.value = [this.slider.value[0], nV]
                    }
                    this.$nextTick(() => {
                        this.slider.options.max = nV
                    })
                }
            }
        },

        computed: {
            selectCountriesText() {
                if (this.countries && this.filter.countries?.length) {
                    return this.countries.filter(c => this.filter.countries.includes(c.code)).map(c => c[this.countryLocalization]).join(', ')
                }
                return this.$t('general.countries')
            },
            selectThemeText() {
                if (this.filter.themes?.length) {
                    return this.themes.filter(theme => this.filter.themes.includes(theme.value)).map(theme => theme.text).join(', ')
                }
                return this.$t('general.theme')
            },
            selectTransferTypeText() {
                if (this.filter.transferTypes?.length) {
                    return this.transferTypes.filter(transferType => this.filter.transferTypes.includes(transferType.value)).map(transferType => transferType.text).join(', ')
                }
                return this.$t('general.transport')
            },
            selectDifficultyText() {
                if (this.filter.difficulties?.length) {
                    return this.difficulties.filter(difficulty => this.filter.difficulties.includes(difficulty.value)).map(difficulty => difficulty.text).join(', ')
                }
                return this.$t('general.difficulty')
            },
            selectTagsText() {
                if (this.filter.tagIds?.length) {
                    return this.tags.filter(tag => this.filter.tagIds.includes(tag.id)).map(tag => tag.localizations[0].title).join(', ')
                }
                return this.$t('general.tags')
            },
            sliderValue() {
                return this.slider.value;
            },
            showMergeTooltip() {
                return this.slider.value[1] - this.slider.value[0] < 2
            },
            themes() {
                return [
                    {value: 'FOOD', text: this.$t("general.food")}, 
                    {value: 'TOURISM', text: this.$t("general.nature")}, 
                    {value: 'SPORT', text: this.$t("general.adventure")}, 
                    {value: 'CULTURE', text: this.$t("general.culture")}
                ]
            },
            difficulties() {
                return [
                    {value: 'LOW', text: this.$t("general.low")}, 
                    {value: 'MEDIUM', text: this.$t("general.medium")}, 
                    {value: 'HIGH', text: this.$t("general.high")}
                ]
            },
            transferTypes() {
                return [
                    {value: 'WALK',text: this.$t("general.walk")},
                    {value: 'PUBLIC_TRANSPORT',text: this.$t("general.publicTransport")},
                    {value: 'BUS',text: this.$t("general.bus")}, 
                    {value: 'TRAIN',text: this.$t("general.train")},
                    {value: 'BIKE',text: this.$t("general.bike")},
                    {value: 'ROLLER_SCATE',text: this.$t("general.rollerScate")},
                    {value: 'SCOOTER',text: this.$t("general.scooter")},
                    {value: 'BOAT',text: this.$t("general.boat")},
                    {value: 'CABLE_CAR',text: this.$t("general.cableCar")},
                    {value: 'CAR',text: this.$t("general.car")},
                    {value: 'FLIGHT',text: this.$t("general.flight")}
                ]
            },
            activeFiltersCount() {
                let totalCount = 0
                totalCount += this.filter.countries?.length
                totalCount += this.filter.themes?.length
                totalCount += this.filter.transferTypes?.length
                totalCount += this.filter.difficulties?.length
                totalCount += this.filter.minLengthOfTravel !== 1 ? 1 : 0
                totalCount += this.filter.maxLengthOfTravel !== this.$store.state.sliderMaxValue ? 1 : 0
                totalCount += this.filter.searchString ? 1 : 0
                totalCount += this.filter.tagIds?.length
                return totalCount
            },
            filteredCountries() {
                if(this.countries) {
                    const countries = this.countriesOptions ? this.countriesOptions : this.countries
                    const searchString = sharedUtils.removeAccents(this.countriesSearchString)
                    return countries.filter(c => {
                        if (sharedUtils.removeAccents(c[this.countryLocalization]).toUpperCase().includes(searchString.toUpperCase())) return true
                        return false
                    }).sort((a, b) => {
                        return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
                    })
                }
                return []
            },
            countryLocalization() {
                return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
            },
            filteredTags() {
                if (this.tags?.length || this.tagsOptions?.length) {
                    const tags = this.tagsOptions?.length ? this.tagsOptions : this.tags
                    return tags.filter(tag => tag.localizations[0].title != '')
                }
                return []
            },
        },

        apollo: {
            countries: {
                query: COUNTRIES
            },
            tags: {
                query: TAGS
            }
        },
    }
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.floating-button.filter-icon-container {
        background-color: $tr-white;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        z-index: 1;
        bottom: 120px;
        right: -120px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
        border: none;
        transition: right 0.25s ease-in;

        .filter-count {
            position: absolute;
            transform: translate(-50%, -50%);
            top: calc(50% + 10px);
            width: 22px;
            height: 22px;
        }

        &.show {
            right: 25px;
        }

        @media(max-width: 576px) {
            width: 70px;
            height: 70px;
            bottom: 90px;

            &.show {
                right: 10px;
            }
        }
    }

    .px-40 {
        padding-inline: 40px;
    }

    .filter-icon-container {
        cursor: pointer;
        display: flex;
        align-items: center;

        .filter-icon {
            height: 30px;
            width: 30px;
        }

        .filter-count {
            // position: absolute;
            // top: 17px;
            // left: -7px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: $tr-black;
            color: $tr-white;
        }
    }

    .filter-sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .filters-container {
            height: 100%;
            overflow-y: auto;
        }

        .buttons-container {
            padding-top: 20px;
            border-top: 1px solid $tr-light-gray;
            box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.05);
        }
    }

</style>

<style lang="scss">
@import '@/scss/variables';

    .dayslider {
        padding-bottom: 30px;
    }
    .dayslider label {
        text-align: left;
        display: block;
    }

    .vue-slider-rail {
        background-color: $tr-gray !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .vue-slider-process {
        background-color: $tr-green !important;
        height: 3px !important;
    }

    .vue-slider-dot-handle {
        background-color: $tr-black !important;
        box-shadow: none !important;
    }

    .vue-slider-dot-tooltip {
        visibility: visible !important;
    }

    .vue-slider-dot-tooltip-inner {
        border-color: transparent !important;
        background-color: transparent !important;
        color: $tr-black !important;
        font-size: 16px !important;
        font-weight: 500 !important;
    }

    .vue-slider-dot-tooltip-inner::after {
        display: none !important;
    }

    .vue-slider-custom-merge-tooltip {
        position: absolute;
        left: 50%;
        bottom: -20px;
    }

    .vue-slider {
        padding: 20px 25px !important;
    }

</style>