import gql from 'graphql-tag'

export const EQUIPMENTS = gql`
  query Equipments {
    equipments {
      id
      category {
        id
        localizations {
          language
          title
        }
      }
      localizations {
        language
        title
        productLink
      }
    }
  }
`;

export const EQUIPMENTS_ADMIN = gql`
  query EquipmentsAdmin {
    equipmentsAdmin {
      id
      originalLanguage
      category {
        id
        originalLanguage
        localizations {
          language
          title
        }
      }
      localizations {
        language
        title
        productLink
      }
    }
  }
`;

export const EQUIPMENT = gql`
  query Equipment($equipmentId: ID!) {
    equipment(equipmentId: $equipmentId) {
      id
      category {
        id
        localizations {
          language
          title
        }
        iconFileName
      }
      title
      productLink
    }
  }
`;

export const EQUIPMENT_CATEGORIES = gql`
  query EquipmentCategories {
    equipmentCategories {
      id
      localizations {
        language
        title
      }
      iconFileName
    }
  }
`;

export const EQUIPMENT_CATEGORIES_ADMIN = gql`
  query EquipmentCategoriesAdmin {
    equipmentCategoriesAdmin {
      id
      originalLanguage
      localizations {
        language
        title
        published
      }
      iconFileName
    }
  }
`;

export const EQUIPMENT_TEMPLATES = gql`
  query EquipmentTemplates {
    equipmentTemplates {
      id
      localizations {
        title
      }
      equipments {
        id
        category {
          id
          localizations {
            title
          }
          iconFileName
        }
        localizations {
          title
          productLink
        }
      }
    }
  }
`;

export const EQUIPMENT_TEMPLATES_ADMIN = gql`
  query EquipmentTemplatesAdmin {
    equipmentTemplatesAdmin {
      id
      originalLanguage
      localizations {
        language
        title
      }
      equipments {
        id
        category {
          id
          localizations {
            language
            title
          }
          iconFileName
        }
        localizations {
          language
          title
          productLink
        }
      }
    }
  }
`;

export const TRAVEL_TIP_EQUIPMENTS = gql`
  query TravelTipEquipments($travelTipId: ID!) {
    travelTipEquipments(travelTipId: $travelTipId) {
      equipment {
        id
        category {
          id
          localizations {
            language
            title
          }
          iconFileName
        }
        localizations {
          language
          title
          productLink
        }
      }
      quantity
      importance
    }
  }
`

export const PERSONAL_EQUIPMENTS = gql`
  query PersonalEquipmentsForCurrentUser($travelTipId: ID!) {
    personalEquipmentsForCurrentUser(travelTipId: $travelTipId) {
      id
      category {
        id
        iconFileName
      }
      title
      productLink
      quantity
      settled
      packed
    }
  }
`;
