export default {
    homeTitle: "Travel with us and explore the entire globe",
    homeDescription: "Where to go on a trip? Embark on an adventure in the Czech Republic and around the world. We plan original travel itineraries filled with practical information and tips just for you.",

    searchTitle: "Trips - Find a tailored trip",
    searchDescription: "Search for travel itineraries packed with practical information and explore unique places in the Czech Republic and abroad.",

    travelTipDetailTitle: "Travel Tip Details",
    travelTipDetailDescription: "Check out detailed information about this travel tip. Get inspired and plan your next unforgettable trip.",

    aboutTitle: "About Us - Explore the world with us sustainably and differently",
    aboutDescription: "Learn our story! We love discovering hidden spots and believe in sustainable travel. Our itineraries will take you where ordinary tourists don’t go.",

    workWithUsTitle: "Work with Us",
    workWithUsDescription: "Join us and help inspire the world to discover hidden gems. Together, we create a travel community full of passion and experience.",

    principlesDataProcessingTitle: "Principles of Personal Data Processing",
    principlesDataProcessingDescription: "Find out how we protect your personal data. Our policies are designed to ensure transparency and security.",

    termsOfUseTitle: "Terms of Use",
    termsOfUseDescription: "Read our terms of use and learn how our platform works. We aim to make your experience the best it can be.",

    contactTitle: "Contact - Write to Us",
    contactDescription: "Need advice or have a question? Contact us through the form or on social media. We’re here for you whether you’re planning a trip or sharing feedback.",

    howToTitle: "How It Works - Learn how to use our platform with ease",
    howToDescription: "Discover how easy it is to use our platform. From searching for trips to sharing experiences, everything you need to know in one place.",

    registerTitle: "Registration - Create an account and explore hidden places",
    registerDescription: "Become part of our community. Register and gain access to unique itineraries, planning tools, and travel opportunities.",

    authorRegistrationTitle: "Author Registration - Create an account and share your trips as an itinerary author",
    authorRegistrationDescription: "Become a trip author! Share your journeys with the community and inspire others to explore the world off the beaten path.",

    loginTitle: "Login",
    loginDescription: "Continue planning unforgettable adventures.",

    resetPasswordTitle: "Password Reset",
    resetPasswordDescription: "Forgot your password? Reset it easily and quickly using our form.",

    pinboardTitle: "Travel Companions - Find a travel buddy",
    pinboardDescription: "Find travel companions and explore the world together. Join discussions or plan your own adventure."
}
